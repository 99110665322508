<template>
    <div class="home" style="min-width: 1024px">
        <el-header>
            <div class="header_top flex ai_c">
                <img src="@/assets/img/jupiao.png" alt="" />
                <span :class="isCollapse === 'false' ? 'iconfont iconzhankai' : 'iconfont iconfold-right'" class="miniTb" @click="toggleCollapse"></span>
                <span style="color: #949494"> 所在位置：{{ $route.meta.title }} </span>
            </div>
            <div class="avatar_box flex ai_c">
                <div class="sanj flex ai_c">
                    <el-avatar :src="avatorImg" style="margin-right: 20px"></el-avatar>
                    <el-dropdown>
                        <span class="iconfont iconbelow-s sj">
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="signOut">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </span>
                    </el-dropdown>
                </div>
            </div>
        </el-header>
        <el-container>
            <el-aside class="aside" :width="isCollapse === 'false' ? '180px' : '64px'">
                <el-menu text-color="#515A6E" active-text-color="#4295FB" :default-active="$route.path" router>
                    <el-menu-item v-for="(item, index) in navList" :index="item.link" :key="index" :class="index == currentInd ? 'active' : ''" @click="clickMenu(index)">
                        <i :class="item.icon" style="margin-right: 10px; font-size: 20px"></i>
                        <span v-show="isCollapse === 'false'">{{ item.title }}</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main class="main">
                <router-view></router-view>
            </el-main>
        </el-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isCollapse: 'false',
            currentInd: sessionStorage.getItem('currentInd') || 0,
            avatorImg: require('@/assets/img/avator.png'),
            navList: [
                { icon: 'iconfont iconyemian', title: '首页总览', link: '/first' },
                {
                    icon: 'iconfont icondingdanguanli1',
                    title: '订单管理',
                    link: '/order',
                },
                {
                    icon: 'iconfont iconyonghuguanli',
                    title: '用户管理',
                    link: '/user',
                },
                {
                    icon: 'iconfont iconchangjianwenti1',
                    title: '常见问题',
                    link: '/problem',
                },
                {
                    icon: 'iconfont iconqianbao',
                    title: '零钱提现',
                    link: '/toCash',
                },
                {
                    icon: 'iconfont iconguanggaoguanli',
                    title: '广告管理',
                    link: '/advert',
                },
                {
                    icon: 'iconfont iconshezhi',
                    title: '影院规则',
                    link: '/cinema',
                },
                {
                    icon: 'iconfont iconshezhi',
                    title: '影院承包',
                    link: '/distribution',
                },
            ],
        }
    },
    methods: {
        toggleCollapse() {
            // this.isCollapse = !this.isCollapse;
            if (this.isCollapse === 'false') {
                this.isCollapse = 'true'
            } else if (this.isCollapse === 'true') {
                this.isCollapse = 'false'
            }
            sessionStorage.setItem('sideRow1', this.isCollapse)
        },
        clickMenu(ind) {
            sessionStorage.setItem('currentInd', ind)
            this.currentInd = ind
        },
        //退出登陆
        signOut() {
            localStorage.removeItem('uInfo')
            sessionStorage.removeItem('currentInd')
            this.$store.commit('setUInfo', undefined)
            this.$router.push('/login')
        },
    },
    created() {
        this.isCollapse = sessionStorage.getItem('sideRow1') || 'false'
        // console.log('created',sessionStorage.getItem('sideRow'))
    },
}
</script>

<style lang="less" scoped>
.el-header {
    padding: 0;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    padding-right: 1.25rem;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(24, 90, 233, 0.25);
}
.header_top {
    box-sizing: border-box;
    .miniTb {
        font-size: 1.375rem;
        padding-right: 1rem;
        color: #949494;
    }
    img {
        display: inline-block;
        width: 12.5rem;
        height: 3.75rem;
    }
}
.aside {
    padding-top: 2.5rem;
    height: calc(100vh - 4rem);
    /deep/ .el-menu {
        border-right: 0px;
        .el-menu-item {
            margin-bottom: 0.625rem;
            font-size: 16px;
        }
    }
}
.active {
    background-color: #f5f7f9;
    &::after {
        content: '';
        position: absolute;
        width: 0.25rem;
        height: 100%;
        right: 0;
        background-color: #4295fb;
    }
}
.main {
    // padding-top: 40px;
    margin-top: 0.1875rem;
    background-color: #f5f7f9;
}
</style>