<template>
    <div class="user">
        <div class="box">
            <div class="menu flex jc_s">
                <a href="/user" class="flex jc_s"><i class="iconfont iconmaijia_wode"></i>买家</a>
                <a href="/userBusiness" class="flex jc_s now"><i class="iconfont iconyonghu1"></i>商家</a>
                <a href="/userExtension" class="flex jc_s"><i class="iconfont icontuiguang-1"></i>推广</a>
            </div>
            <div class="p16">
                <div class="pb16 flex jc_b ai_c">
                    <div class="flex jc_s">
                        <i class="iconfont iconzongji f16 lan"></i>
                        <span class="col666 f14 ml10">共{{ total }}个用户</span>
                    </div>
                    <div class="flex jc_end f14 col333 ai_c">
                        <span class="mr20">注册日期：</span>
                        <el-date-picker
                            v-model="valDate"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            :clearable="false"
                            class="xzdate"
                            style="width: 15rem"
                        >
                        </el-date-picker>
                        <span class="mr20 ml40">类型：</span>
                        <el-select v-model="valSelect" style="width: 7.875rem" class="xzselect">
                            <el-option v-for="item in valSelectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <el-input v-model="valSerch" placeholder="请输入" style="width: 19.875rem" class="ml15 mr20 xzinput"></el-input>
                        <el-button type="primary" class="ssbtn" style="width: 5.375rem" @click="filter">搜 索</el-button>
                        <el-select v-model="valSelectZt" style="width: 7rem" class="xzselect2 ml20" @change="filter2">
                            <el-option v-for="item in valSelectOptionsZt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
                <!--menub end-->
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%" @sort-change="sortMethod">
                        <el-table-column prop="id" label="ID" width="78" fixed>
                            <template slot-scope="scope">
                                <div>{{ scope.row.id }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="headimgurl" label="头像" width="68" align="center" fixed>
                            <template slot-scope="scope">
                                <div class="tc">
                                    <img :src="scope.row.headimgurl" style="width: 38px; width: 38px; border-radius: 50%; vertical-align: top" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nickname,sell_province,sell_city" label="昵称" width="130" fixed>
                            <template slot-scope="scope">
                                <p class="col666">{{ scope.row.nickname }}</p>
                                <p class="col999">
                                    {{ scope.row.sell_province }} -
                                    {{ scope.row.sell_city }}
                                </p>
                                <p>{{scope.row.credit}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="username" label="用户名" width="100" fixed>
                            <template slot-scope="scope">
                                <div>{{ scope.row.username }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="openid" label="opendid" width="225">
                            <template slot-scope="scope">
                                <div>{{ scope.row.openid }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="70">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status == 0">启用</div>
                                <div v-else-if="scope.row.status == 1" style="color: #f74d4d">冻结</div>
                                <div v-else-if="scope.row.status == 2" style="color: #4fdc76">待审核</div>
                                <div v-else-if="scope.row.status == 3" style="color: #f8c161">未启用</div>
                                <div v-else-if="scope.row.status == 4" style="color: #acacac">审核失败</div>
                                <div v-else-if="scope.row.status == 5">未注册</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ali_account,ali_name" label="支付宝" width="150">
                            <template slot-scope="scope">
                                <p class="col666">{{ scope.row.ali_name }}</p>
                                <p class="col999">
                                    {{ scope.row.ali_account }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column prop="country,city,province" label="地区" width="120">
                            <template slot-scope="scope">
                                <div>{{ scope.row.country }}-{{ scope.row.province }}-{{ scope.row.city }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="shop_name" label="店铺名称" width="100">
                            <template slot-scope="scope">
                                <div>{{ scope.row.shop_name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tell" label="手机" width="100">
                            <template slot-scope="scope">
                                <div>{{ scope.row.tel }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="balance" label="余额" sortable="custom" width="80">
                            <template slot-scope="scope">
                                <div>{{ scope.row.balance }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="settling" label="待结算" sortable="custom" width="90">
                            <template slot-scope="scope">
                                <div>{{ scope.row.settling }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="settled" label="已结算" sortable="custom" width="90">
                            <template slot-scope="scope">
                                <div>{{ scope.row.settled }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ctotal,stotal" :formatter="fomattercp" label="出票率（成交数/总单数）" width="190">
                            <!--<template slot-scope="scope">            
							<div>93%({{scope.row.ctotal}}/{{scope.row.stotal}})</div>
						 </template> -->
                        </el-table-column>
                        <el-table-column prop="receipt" label="接单状态" width="80">
                            <template slot-scope="scope">
                                <div v-if="scope.row.receipt == 0 || scope.row.receipt == null">停止接单</div>
                                <div v-else-if="scope.row.receipt == 1">接单中</div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="cinema" label="擅长影院" width="200">
                            <template slot-scope="scope">
                                <div>{{ scope.row.cinema }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="last_logintime" label="最后登录时间" sortable width="150">
                            <template slot-scope="scope">
                                <div>{{ scope.row.last_logintime }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" label="注册时间" width="150">
                            <template slot-scope="scope">
                                <div>{{ scope.row.created_at }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" min-width="320" class="min-width" fixed="right" >
                            <template slot-scope="scope">
                                <div class="flex jc_s ai_c">
                                    <el-button class="qxbtn mr10" @click="ckbj(scope.$index, scope.row)" style="margin-right: 10px">编辑</el-button>
                                    <!--<el-button class="kqbtn mr10" @click="ptqxDialog = true">审核</el-button>-->
                                    <div v-if="scope.row.status == 0">
                                        <el-button class="djbtn" @click="djMessage(scope.$index, scope.row)">冻结</el-button>
                                    </div>
                                    <div v-else-if="scope.row.status == 1">
                                        <el-button class="djbtn" @click="jdMessage(scope.$index, scope.row)">解冻</el-button>
                                    </div>
                                    <el-button class="qxbtn mr10" style="margin-left: 10px" @click="cshMessage(scope.$index, scope.row)">初始化</el-button>
                                    <el-button style="margin-left: 10px" class="delbtn" @click="delMessage(scope.$index, scope.row)">删除</el-button>
                                    <el-button style="margin-left: 10px" class="qxbtn2 mr10" @click="Offer(scope.row,'2')" v-if="scope.row.auto_offer == 1">关自动</el-button>
                                    <el-button style="margin-left: 10px; background:#f8c161 !important" class="qxbtn2 mr10" @click="Offer(scope.row,'1')" v-else>开自动</el-button>

                                    <!-- 581336 -->
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--table end-->
                <div class="page pt16">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[2, 5, 8, 10]"
                        :page-size="pageSize"
                        layout=" prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--box end-->
        <!---->
        <el-dialog id="bianji" width="575px" :visible.sync="bjDialog">
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">编辑</span>
                    <i class="iconfont iconguanbi colfff f18" @click="bjDialog = false"></i>
                </div>
            </div>
            <div class="qxmid">
                <el-form :model="bjForm" ref="bjForm">
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt"><b>*</b>用户名</span>
                        <el-form-item prop="username">
                            <el-input placeholder="" v-model="bjForm.username" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt"><b>*</b>手机号</span>
                        <el-form-item prop="tel">
                            <el-input placeholder="" v-model="bjForm.tel" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt"><b>*</b>店铺名</span>
                        <el-form-item prop="shop_name">
                            <el-input placeholder="" v-model="bjForm.shop_name" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt">支付宝姓名</span>
                        <el-form-item prop="ali_name">
                            <el-input placeholder="请输入支付宝姓名" v-model="bjForm.ali_name" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt">支付宝账号</span>
                        <el-form-item prop="ali_account">
                            <el-input placeholder="请输入支付宝账号" v-model="bjForm.ali_account" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt"><b>*</b>余额</span>
                        <el-form-item prop="balance,stotal,ctotal">
                            <div class="flex jc_b ai_c">
                                <el-input v-model="bjForm.balance" style="width: 78px" :disabled="true"></el-input>
                                <div class="flex jc_s ai_c">
                                    <span class="f14 col666 mr10">总单数</span>
                                    <el-input-number v-model="bjForm.stotal" controls-position="right" class="xznum" style="width: 78px !important" :disabled="true"></el-input-number>
                                </div>
                                <!-- <div class="flex jc_s ai_c">
                                    <span class="f14 col666 mr10">成交数</span>
                                    <el-input v-model="bjForm.ctotal" style="width: 78px" :disabled="true"></el-input>
                                </div> -->
                                 <div class="flex jc_s ai_c ml20">
                                    <span class="f14 col666 mr10">积分</span>
                                    <el-input v-model="bjForm.credit" style="width: 78px" ></el-input>
                                </div>
                            </div>
                        </el-form-item>
                    </div>

                    <div class="qxmidm mb10">
                        <span class="qxmidmtxt">是否接单</span>
                        <el-form-item prop="receipt">
                            <el-radio-group v-model="bjForm.receipt" class="xzradio2">
                                <el-radio :label="0">不接单</el-radio>
                                <el-radio :label="1">接单中</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="qxmidm mb20">
                        <span class="qxmidmtxt">状态</span>
                        <el-form-item prop="status">
                            <el-radio-group v-model="bjForm.status" class="xzradio2 xzradio2b flex jc_b ai_c" @change="ckly">
                                <el-radio :label="0">启用</el-radio>
                                <el-radio :label="1">冻结</el-radio>
                                <el-radio :label="2">待审核</el-radio>
                                <el-radio :label="3">未启用</el-radio>
                                <el-radio :label="4">审核失败</el-radio>
                                <!--<el-radio :label="5">未注册</el-radio>-->
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb18" v-if="liyou == true">
                        <span class="qxmidmtxt">理由</span>
                        <el-form-item prop="reason">
                            <el-input placeholder="请输入审核不通过理由" v-model="bjForm.reason" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb26">
                        <span class="qxmidmtxt">影院</span>
                        <el-form-item prop="cinema">
                            <el-input type="textarea" v-model="bjForm.cinema"></el-input>
                        </el-form-item>
                    </div>

                    <div class="qxmidm pb40">
                        <el-button type="primary" class="ssbtn ml26" style="width: 5.375rem; margin-right: 5rem" @click="submitForm('bjForm')">确认</el-button>
                        <el-button class="cancelbtn" style="width: 5.375rem" @click="resetForm('bjForm')">取消</el-button>
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    data() {
        return {
            valDate: '',
            valSelect: '',
            valSelectOptions: [
                {
                    value: 1,
                    label: '邀请人ID',
                },
                {
                    value: 2,
                    label: 'ID',
                },
                {
                    value: 3,
                    label: '昵称',
                },
                {
                    value: 4,
                    label: '手机号',
                },
                {
                    value: 5,
                    label: 'OPPENID',
                },
            ],
            valSerch: '',
            valSelectZt: '',
            valSelectOptionsZt: [
                {
                    value: '0',
                    label: '启用',
                },
                {
                    value: '1',
                    label: '禁用',
                },
                {
                    value: '2',
                    label: '待审核',
                },
                {
                    value: '3',
                    label: '未启用',
                },
                {
                    value: '4',
                    label: '审核失败',
                },
                {
                    value: '5',
                    label: '未注册',
                },
            ],
            tableData: [],
            pageSize: 20,
            currentPage: 1,
            total: 0,
            bjDialog: false,
            bjForm: {
                username: '',
                tel: '',
                shop_name: '',
                balance: '',
                ctotal: '',
                stotal: '',
                status: '',
                id: '',
                receipt: '',
                status: '',
                cinema: '',
                reason: '',
                ali_account: '',
                ali_name: '',
                credit:''
            },
            liyou: false,
            ali_account0: '',
            ali_name0: '',
            sort: {
                sortMode: '',
            },
            condition: {
                search_type: '',
                start_at: '',
                end_at: '',
                s: '',
                status: '',
            },
        }
    }, //data
    mounted() {
        this.getData()
    }, //
    methods: {
        Offer(item,val) {
            this.$confirm('确定操作该用户的自动报价吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        id: item.id,
                        auto_offer: val,
                    }
                    this.$api
                        .setSeller(data)
                        .then((res) => {
                            if (res.data.code == 0) {
                                this.$message.success(res.data.msg)
                                this.getData()
                                // console.log('this.$api.setSeller',res.data.data)
                            }
                        })
                        .catch((err) => {
                            console.log('err', err)
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消重置',
                    })
                })
        },
        sortMethod(e) {
            if (e.order) {
                switch (e.prop) {
                    case 'settling':
                        this.sort.sortMode = e.order == 'ascending' ? 'settlingasc' : 'settlingdesc'
                        break
                    case 'settled':
                        this.sort.sortMode = e.order == 'ascending' ? 'settledasc' : 'settleddesc'
                        break
                    case 'balance':
                        this.sort.sortMode = e.order == 'ascending' ? 'balanceasc' : 'balancedesc'
                        break
                }
            } else {
                this.sort.sortMode = ''
            }
            // ordercountasc:我的订单数   pushcountasc:推广人数    pointsasc:积分   balancedesc:余额
            console.log(this.sort)
            console.log('sortMethod:', e)
            this.getData()
        },
        cshMessage(index, row) {
            const that = this
            this.$confirm('确定将该用户的密码重置为初始密码吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = { uid: row.id }
                    this.$api
                        .initPass(data)
                        .then((res) => {
                            if (res.data.code == 0) {
                                that.$message.success(res.data.msg)
                            }
                            location.reload()
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消重置',
                    })
                })
        }, //cshMessage

        getData(order_by = 'id') {
            const that = this
            //获取商家
            let data = {
                // balancedesc  降序   balanceasc 升序

                order_by: order_by,
                search_type: '',
                s: '',
                status: '',
                limit: that.pageSize,
                // offset: that.currentPage-1,
                offset: (that.currentPage - 1) * that.pageSize,

                start_at: '',
                end_at: '',
            }

            data['search_type'] = this.condition.search_type
            data['s'] = this.condition.s
            data['start_at'] = this.condition.start_at
            data['end_at'] = this.condition.end_at
            data['status'] = this.condition.status

            if (this.sort.sortMode) {
                data.order_by = this.sort.sortMode
            }
            this.$api.getSeller(data).then((res) => {
                that.total = res.data.data.count
                that.tableData = res.data.data.rows

                //console.log('getdata',res.data.data.rows)
            })
        }, //getData
        ckjd(e) {
            if (e == 0) {
                ;(this.bjForm.ali_account = ''), (this.bjForm.ali_name = '')
            } else {
                ;(this.bjForm.ali_account = this.ali_account0), (this.bjForm.ali_name = this.ali_name0)
            }
        }, //
        dateToTimestamp(dateStr) {
            if (!dateStr) {
                return ''
            }
            let newDataStr = dateStr.replace(/\.|\-/g, '/')
            let date = new Date(newDataStr)
            let timestamp = date.getTime()
            return timestamp
        },
        filter() {
            this.total = 0
            this.currentPage = 1

            const that = this
            let fsearch_type
            if (this.valSelect == 1 || this.valSelect == 2 || this.valSelect == 3 || this.valSelect == 4 || this.valSelect == 5) {
                fsearch_type = this.valSelect
            } else {
                fsearch_type = ''
            }
            var stime
            if (!this.valDate[0]) {
                stime = ''
            } else {
                stime = moment(this.valDate[0]).format('YYYY-MM-DD')
            }
            var etime
            if (!this.valDate[1]) {
                etime = ''
            } else {
                etime = moment(this.valDate[1]).format('YYYY-MM-DD')
            }
            this.condition = {
                search_type: fsearch_type,
                start_at: stime,
                end_at: etime,
                s: this.valSerch,
            }
            this.getData()
        }, //
        filter2() {
            this.total = 0
            this.currentPage = 1

            this.condition.status = this.valSelectZt
            this.getData()
        }, //

        fomattercp(row, column, cellValue, index) {
            let num = row.ctotal
            let total = row.stotal
            num = parseFloat(num)
            total = parseFloat(total)
            if (isNaN(num) || isNaN(total)) {
                return '-'
            }
            return total <= 0 ? '0%' : Math.round((num / total) * 10000) / 100.0 + '%(' + row.ctotal + '/' + row.stotal + ')'
        }, //

        handleSizeChange(val) {
            this.pageSize = val
            //console.log(`每页 ${val} 条`);
            this.getData()
            // this.filter();
            // this.filter2();
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getData()
            // this.filter();
            // this.filter2();
        },
        djMessage(index, row) {
            const that = this
            this.$confirm('冻结用户将禁止该用户一切操作行为...', '确定冻结吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })

                .then(() => {
                    let data = { id: row.id }

                    this.$api.frozenBuyer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                        }
                        location.reload()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消冻结',
                    })
                })
        }, //djMessage
        jdMessage(index, row) {
            const that = this
            this.$confirm('确定解冻吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })

                .then(() => {
                    let data = { id: row.id }

                    this.$api.frozenBuyer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                        }
                        location.reload()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消解冻',
                    })
                })
        }, //jdMessage
        delMessage(index, row) {
            const that = this
            this.$confirm('确定删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = { id: row.id }

                    this.$api.delUser(data).then((res) => {
                        console.log('del', res)
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                        }
                        location.reload()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        }, //delMessage
        ckly(e) {
            if (e == 4) {
                this.liyou = true
            } else {
                this.liyou = false
            }
        }, //ckleix
        ckbj(index, row) {
            this.bjForm.username = row.username
            this.bjForm.tel = row.tel
            this.bjForm.shop_name = row.shop_name
            this.bjForm.balance = row.balance
            this.bjForm.stotal = row.stotal
            this.bjForm.ctotal = row.ctotal
            this.bjForm.id = row.id
            this.bjForm.cinema = row.cinema
            this.bjForm.credit = row.credit

            if (row.receipt == null) {
                this.bjForm.receipt = 0
            } else {
                this.bjForm.receipt = row.receipt
            }
            this.bjForm.ali_account = row.ali_account
            this.bjForm.ali_name = row.ali_name
            this.ali_account0 = row.ali_account
            this.ali_name0 = row.ali_name

            this.bjForm.status = row.status

            this.bjDialog = true
        }, //ckbj
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const that = this
                    let data = {
                        username: that.bjForm.username,
                        tel: that.bjForm.tel,
                        shop_name: that.bjForm.shop_name,
                        balance: that.bjForm.balance,
                        ctotal: that.bjForm.ctotal,
                        stotal: that.bjForm.stotal,
                        status: that.bjForm.status,
                        id: that.bjForm.id,
                        reason: that.bjForm.reason,
                        receipt: that.bjForm.receipt,
                        cinema: that.bjForm.cinema,
                        ali_name: that.bjForm.ali_name,
                        ali_account: that.bjForm.ali_account,
                        credit:that.bjForm.credit
                    }
                    this.$api.setSeller(data).then((res) => {
                        console.log('res', res)
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                            setTimeout(function () {
                                that.bjDialog = false
                                that.tableData = []
                                that.getData()
                            }, 100)
                        }
                    })
                }
            })
        }, //submitForm
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.bjDialog = false
        },
    }, //methods
}
</script>

<style lang="less" scoped>
</style>