<template>
    <div class="first" >
        <div class="oneRow">
            <el-row :gutter="20">
                <el-col :span="6" >
                    <div class="block radious flex ai_c jc_e">
                        <div
                            class="left flex jc_c"
                            style="background-color: #ffe2e2"
                        >
                            <i
                                class="icon iconfont icondingdanguanli1"
                                style="color: #ff9999"
                            ></i>
                        </div>
                        <div class="right">
                            <h3>{{result.allOrder}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>订单总数</p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="block radious flex ai_c jc_e">
                        <div
                            class="left flex jc_c"
                            style="background-color: #e2fffb"
                        >
                            <i
                                class="icon iconfont iconp-dingdanzonge"
                                style="color: #89f2e4; font-size: 30px"
                            ></i>
                        </div>
                        <div class="right">
                            <h3>{{result.allOrderMoney}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>订单总额</p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="block radious flex ai_c jc_e">
                        <div
                            class="left flex jc_c"
                            style="background-color: #fff7e2"
                        >
                            <i
                                class="icon iconfont iconchupiaoTicketissue"
                                style="color: #ffdd87; font-size: 35px"
                            ></i>
                        </div>
                        <div class="right">
                            <h3>{{result.sysAllOrder}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>总系统出票订单</p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="block radious flex ai_c jc_e">
                        <div
                            class="left flex jc_c"
                            style="background-color: #e2eeff"
                        >
                            <i
                                class="icon iconfont iconkuisun"
                                style="color: #74a7ef; font-size: 40px"
                            ></i>
                        </div>
                        <div class="right">
                            <h3>{{result.allLossProfit}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>总系统出票亏损金额</p>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- style="" -->
        <div class="twoRow">
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="dd radious">
                        <img class="bgc" src="../assets/img/circle.png" alt="">
                        <div class="block radious flex ai_c jc_e">
                            <div class="left">
                                <h3>{{result.orderSettled}}</h3>
                                <!-- <h3>123</h3> -->
                                <p>已结算</p>
                            </div>
                            <div class="right">
                                <h3>{{result.orderSettling}}</h3>
                                <!-- <h3>123</h3> -->
                                <p>待结算</p>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="block radious">
                        <div class="top flex jc_end">
                            <div
                                class="first"
                                :class="[activeBtn == true ? 'act' : '']"
                                @click="btn"
                                style="min-width: 46px"
                            >
                                商家
                            </div>
                            <div
                                class="two"
                                :class="[activeBtn == false ? 'act' : '']"
                                @click="btn"
                                style="min-width: 46px"
                            >
                                合伙人
                            </div>
                        </div>
                        <div class="bottom flex jc_e">
                            <div class="">
                                <div v-if="activeBtn">
                                    <h3>{{result.shopBalance}}</h3>
                                    <!-- <h3>123</h3> -->
                                    <p class="" >
                                        <i class="iconfont icondaitixian"></i>
                                        商家待提现
                                    </p>
                                </div>
                                <div v-else>
                                    <h3>{{result.shareBalance}}</h3>
                                    <!-- <h3>123</h3> -->
                                    <p class="" >
                                        <i class="iconfont icondaitixian"></i>
                                        合伙人待提现
                                    </p>
                                </div>
                            </div>
                            <el-divider direction="vertical"></el-divider>
                            <div class="">
                                <div v-if="activeBtn">
                                    <h3>{{result.sellOrderSettled}}</h3>
                                    <!-- <h3>123</h3> -->
                                    <p >
                                        <i class="iconfont iconyijiesuan"></i>
                                        商家已结算
                                    </p>
                                </div>
                                <div v-else>
                                    <h3>{{result.pusherOrderSettled}}</h3>
                                    <!-- <h3>123</h3> -->
                                    <p >
                                        <i class="iconfont iconyijiesuan"></i>
                                        合伙人已结算
                                    </p>
                                </div>
                            </div>
                            <el-divider direction="vertical"></el-divider>
                            <div class="">
                                <div v-if="activeBtn">
                                    <h3>{{result.sellOrderSettling}}</h3>
                                    <!-- <h3>123</h3> -->
                                    <p >
                                        <i class="iconfont icondaijiesuan"></i>
                                        商家待结算
                                    </p>                                   
                                </div>
                                <div v-else>
                                    <h3>{{result.pusherOrderSettling}}</h3>
                                    <!-- <h3>123</h3> -->
                                    <p>
                                        <i class="iconfont icondaijiesuan"></i>
                                        合伙人待结算
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="threeRow">
            <el-row :gutter="20">
                <el-col :span="16">
                    <div class="block radious flex ai_c jc_e">
                        <div class="">
                            <h3>{{result.todayOrder}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>今日订单数</p>
                        </div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="">
                            <h3>{{result.todayOrderMoney}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>今日总额</p>
                        </div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="">
                            <h3>{{result.orderSysProfit}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>今日收益</p>
                        </div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="">
                            <h3>{{result.sysTodayOrder}}</h3>
                            <!-- <h3>123</h3> -->
                            <p>今日系统出票订单</p>
                        </div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="">
                            <h3>{{result.todayLossProfit}}</h3>
                            <!-- <h3>123</h3> -->

                            <p>今日系统出票亏损金额</p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="block radious">
                        <div
                            id="myChart1"
                            style="width: 100%; height: 100%"
                        ></div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="fourRow">
            <el-row :gutter="20">
                <el-col :span="16">
                    <div class="block radious">
                        <div
                            id="myChart2"
                            style="width: 100%; height: 100%"
                        ></div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="block radious">
                        <div class="chartTop flex jc_e ai_e">
                            <div class="left">
                                <i class="iconfont iconmaijia_wode icon"></i>
                                总用户量:
                                <span class="icon">{{result.allUser}}</span>
                                <!-- <span class="icon">123</span> -->
                            </div>
                            <div class="right">
                                <i
                                    class="iconfont iconshangsheng icon"
                                    style="color: #f4736d; font-size: 1.75rem"
                                ></i>
                                今日新增用户:
                                <span class="icon">{{result.todayUser}}</span>
                                <!-- <span class="icon">123</span> -->
                            </div>
                        </div>
                        <div id="myChart3"></div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import moment from 'moment'

export default {
    data() {
        return {
            activeBtn: true,
            result:'',
            // 饼图
            myChart1: "",
            // 折现图
            myChart2: "",
            // 柱状图
            myChart3: "",
            chartoption: {
                color: ["#59E8D5", "#4295FB", "#F4736D"],
                // tooltip: {
                //     show: false,
                //     trigger: "item",
                //     position:'right'
                // },
                legend: {
                    icon: "circle",
                    top: "7%",
                    left: "5%",
                    orient: "vertical",
                    itemWidth: 6,
                    itemHeight: 6,
                    selectedMode: false,
                },
                series: [
                    {
                        name: "访问来源",
                        type: "pie",
                        radius: ["55%", "70%"],
                        center: ["60%", "50%"], //第一个百分比是左右移动，50%居中，越小越靠左，反之亦然；第二个百分比是上下移动，50%居中，越小越靠上反之亦然。
                        avoidLabelOverlap: true,
                        label: {
                            show: false,
                            // position: "center",
                            normal: {
                                show: false,
                                position: "outer", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）
                                // \n\n可让文字居于牵引线上方，很关键
                                // {b}  代表显示的内容标题
                                // {c}代表数据
                                formatter: " {b}：{c}   \n\n {d}%",
                                borderWidth: 20,
                                borderRadius: 4,
                                padding: [0, -30],
                                rich: {
                                    a: {
                                        color: "#CCCCCC",
                                        fontSize: 12,
                                        lineHeight: 20,
                                    },
                                    b: {
                                        fontSize: 12,
                                        lineHeight: 20,
                                        color: "#CCCCCC",
                                    },
                                },
                            },
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: "15",
                                fontColor: "#616478",
                                fontWeight: "bold",
                            },
                            labelLine: {
                                show: true,
                            },
                        },
                        labelLine: {
                            normal: {
                                length: 5,
                                length2: 50,
                                lineStyle: {
                                    color: "#CCCCCC",
                                },
                            },
                        },
                        data: [
                            // 0 公众号  1 小程序  3 网页
                            { value: 0, name: "公众号访问",key:0 },
                            { value: 0, name: "小程序访问",key:3 },
                            { value: 0, name: "网页(H5)访问",key:1 },
                        ],
                    },
                ],
            },
            chartoption2: {
                tooltip: {
                    trigger: "axis",
                    // 自定义鼠标移入的提示信息
                    // formatter: function (params) {
                    //     console.log(params); //打印params
                    //     return params;
                    // },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    // bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    // type: "category",
                    type: "category",

                    data: [
                        "周一",
                        "周二",
                        "周三",
                        "周四",
                        "周五",
                        "周六",
                        "周日",
                    ],
                    axisTick: {
                        show: true,
                        
                    },
                    axisPointer: {
                        type: "shadow",
                    },
                },
                yAxis: {
                    type: "value",
                    axisTick: {
                        show: true,
                    },
                    axisLine:{
                        show:true,
                    },
                },
                series: [
                    {
                        name: "订单总额",
                        type: "line",
                        stack: "总量",
                        data: [0, 0, 0, 0, 0, 0, 0],
                        color: "#F4736D",
                    },
                    {
                        name: "今日收益",
                        type: "line",
                        stack: "总量",
                        data: [0, 0, 0, 0, 0, 0, 0],
                        color: "#4295FB",
                    },
                    {
                        name: "有效订单",
                        type: "line",
                        stack: "总量",
                        data: [0, 0, 0, 0, 0, 0, 0],
                        color: "#59E8D5",
                    },
                ],
            },
            chartoption3: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'line'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                xAxis: {
                    type: "category",
                    data: [
                        "04月12日",
                        "04月13日",
                        "04月14日",
                        "04月15日",
                        "04月16日",
                        "04月17日",
                        "04月18日",
                    ],
                    axisPointer: {
                        type: "shadow",
                    },
                    axisLabel:{
                        interval:0,
                    },

                },
                yAxis: {
                    type: "value",
                    axisLine:{
                        show:true,
                    },
                    axisTick:{
                        show:true
                    }
                },
                series: [
                    {
                        data: [0, 0, 0, 0, 0, 0, 0],
                        type: "bar",
                    },
                ],
            },
        };
    },
    mounted() {
        this.setChart(this.myChart1, "myChart1", this.chartoption);
        this.setChart(this.myChart2, "myChart2", this.chartoption2);
        this.setChart(this.myChart3, "myChart3", this.chartoption3);
        this.getDate()
    },
    methods: {
        btn() {
            this.activeBtn = !this.activeBtn;
        },
        setChart(chart, dom, option) {
            //设置echarts
            if (chart != null && chart != "" && chart != undefined) {
                chart.dispose();
            }
            let chartDom = document.getElementById(dom);
            // console.log(chartDom)
            chart = echarts.init(chartDom);
            option && chart.setOption(option);
            // 屏幕宽度改变后，重新绘制图标
            window.addEventListener("resize", function () {
                chart.resize();
            });
        },
        getDate(){
            // 首页数据
            this.$api.getOrderSurvey().then(res => {
                this.result = res.data.data
                // console.log(this.result)
            })
            //获取用户数统计图
            this.$api.getUserCount().then(res => {
                let data = res.data.data
                if(data.length > 0){
                    data.map(item => {
                        for (const key in item) {
                            if(item[key] == null){
                                item[key] = 0
                            }
                        }
                    })
                }
                let config = ['userCount']
                
                let dealData = this.$common.completion(data,config)
                
                let userArr = []
                let dateArr = []

                dealData.forEach(element => {
                    dateArr.push(element.days)
                    userArr.push(element.userCount)
                });
                //  console.log('dateArr',dateArr)
                this.chartoption3.xAxis.data = dateArr
                this.chartoption3.series[0].data = userArr
                this.setChart(this.myChart3, "myChart3", this.chartoption3);
            })
            //获取折线统计图
            this.$api.getStatistics().then(res => {
                let data = res.data.data
                if(data.length > 0){
                    data.map(item => {
                        for (const key in item) {
                            if(item[key] == null){
                                item[key] = 0
                            }
                        }
                    })
                }
                let config = ['orderCount','price','profit']
                let dealData = this.$common.completion(data,config)
                let profitArr = []
                let priceArr = []
                let orderCountArr = []
                let dateArr = []
                dealData.forEach(element => {
                    dateArr.push(element.days)
                    profitArr.push(element.profit)
                    priceArr.push(element.price)
                    orderCountArr.push(element.orderCount)
                });
                this.chartoption2.xAxis.data = dateArr
                this.chartoption2.series[0].data = priceArr
                this.chartoption2.series[1].data = profitArr
                this.chartoption2.series[2].data = orderCountArr
                this.setChart(this.myChart2, "myChart2", this.chartoption2);
                // console.log(profitArr)
                // console.log(priceArr)
                // console.log(orderCountArr)
            })
            //获取访客比例统计
            this.$api.getVisitor().then(res => {
                // console.log(res.data.data)
                // 0 公众号  1 小程序  3 网页(非静默)
                this.chartoption.series[0].data[0].value = res.data.data[''] ? res.data.data[''] : 0
                this.chartoption.series[0].data[1].value = res.data.data[3] ? res.data.data[3] : 0
                this.chartoption.series[0].data[2].value = res.data.data[1] ? res.data.data[1] : 0
                // console.log("this.chartoption.series[0].data:",this.chartoption.series[0].data)
                // console.log(this.chartoption.series[0].data)
                this.setChart(this.myChart1, "myChart1", this.chartoption);
            })
        },
    },
};
</script>

<style lang="less" scoped>
.first {
    .oneRow {
        .block {
            height: 9.75rem;
            background-color: #ffffff;
            .left {
                width: 5.875rem;
                height: 5.875rem;
                line-height: 5.875rem;
                border-radius: 50%;
                .icon {
                    font-size: 1.5625rem;
                }
            }
            .right {
                h3 {
                    color: #4295fb;
                    text-align: center;
                    font-size: 2.125rem;
                }
                p {
                    font-size: 0.875rem;
                }
            }
        }
    }
    .twoRow {
        .dd{
            position: relative;
            background-color: #6CA9F4;
            h3{
                color: white !important;
            }
            p {
                color: white !important;

            }
            .bgc{
                z-index: 77;
                position: absolute;
            }
        }
        
        .el-divider--vertical {
            height: 4.9375rem;
        }
        margin-top: 1rem;
        .block {
            position: relative;
            z-index: 88;
            height: 11.8125rem;
            h3 {
                color: #4295fb;
                font-size: 2.125rem;
                margin-bottom: 0.9375rem;
            }
            p {
                font-size: 0.875rem;
                color: black;
            }
            .top {
                padding-top: 1.125rem;
                padding-right: 1.25rem;
                > div {
                    width: 4.5625rem;
                    height: 1.5rem;
                    line-height: 1.5rem;
                    text-align: center;
                    font-size: 0.8125rem;
                    background: #f8f8f8;
                    border: 0.0625rem solid #eaeaea;
                    box-shadow: 0px 0px 1.125rem 0px rgba(0, 0, 0, 0.09);
                    border-radius: 0.25rem 0px 0px 0.25rem;
                    color: #999999;
                }
                .act {
                    background: linear-gradient(0deg, #2d8cf0 0%, #62abf9 100%);
                    color: white;
                }
            }
            .bottom {
                margin-top: 1.25rem;
                >div{
                    &:nth-child(odd){
                        min-width: 12.5rem;
                    }
                }
                p {
                    text-align: left;
                    > i {
                        color: #4295fb;
                        font-size: 1.25rem;
                        margin-right: 0.5rem;
                        // vertical-align: middle;
                    }
                }
            }
        }
    }
    .threeRow {
        .el-divider--vertical {
            height: 4.9375rem;
        }
        margin-top: 1rem;
        .block {
            height: 11.8125rem;
            h3 {
                color: #4295fb;
                text-align: center;
                font-size: 2.125rem;
                margin-bottom: 0.9375rem;
            }
            p {
                font-size: 0.875rem;
                color: black;
            }
        }
    }
    .fourRow {
        margin-top: 1rem;
        .block {
            height: 24.125rem;
            .chartTop {
                height: 20%;
                // background-color: gray;
                .icon {
                    color: #64a4fc;
                    font-size: 1.5625rem;
                    font-weight: 600;
                }
            }
            #myChart3 {
                height: calc(100% - 20%);
            }
        }
    }
}
.radious {
    box-shadow: 0px 0px 0.375rem 0px rgba(24, 90, 233, 0.25);
    border-radius: 0.5rem;
}
</style>