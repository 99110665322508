<template>
    <div class="user">
        <div class="box">
            <div class="menu flex jc_s">
                <a href="/user" class="flex jc_s now"><i class="iconfont iconmaijia_wode"></i>买家</a>
                <a href="/userBusiness" class="flex jc_s"><i class="iconfont iconyonghu1"></i>商家</a>
                <a href="/userExtension" class="flex jc_s"><i class="iconfont icontuiguang-1"></i>推广</a>
            </div>
            <div class="p16">
                <div class="pb16 flex jc_b ai_c">
                    <div class="flex jc_s">
                        <i class="iconfont iconzongji f16 lan"></i>
                        <span class="col666 f14 ml10">共{{ total }}个用户</span>
                    </div>
                    <div class="flex jc_end f14 col333 ai_c">
                        <span class="mr20">日期：</span>
                        <el-date-picker
                            v-model="valDate"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            :clearable="false"
                            class="xzdate"
                            style="width: 15rem"
                        >
                        </el-date-picker>
                        <span class="mr20 ml40">类型：</span>
                        <el-select v-model="valSelect" style="width: 7.875rem" class="xzselect">
                            <el-option v-for="item in valSelectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <el-input v-model="valSerch" placeholder="请输入" style="width: 19.875rem" class="ml15 mr20 xzinput"></el-input>
                        <el-button type="primary" @click="filter" class="ssbtn" style="width: 5.375rem">搜 索</el-button>
                    </div>
                </div>
                <!--menub end-->
                <div class="table">
                    <el-table :data="tableData" @sort-change="sortMethod" border style="width: 100%">
                        <el-table-column prop="id" label="ID" width="78" fixed>
                            <template slot-scope="scope">
                                <div>{{ scope.row.id }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="headimgurl" label="头像" width="68" align="center" fixed>
                            <template slot-scope="scope">
                                <div class="tc">
                                    <img :src="scope.row.headimgurl" style="width: 38px; width: 38px; border-radius: 50%; vertical-align: top" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nickname" label="昵称" width="200" fixed>
                            <template slot-scope="scope">
                                <div>{{ scope.row.nickname }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="openid" label="opendid" width="300">
                            <template slot-scope="scope">
                                <div>{{ scope.row.openid }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sex" label="性别" width="60">
                            <template slot-scope="scope">
                                <div>{{ scope.row.valtable5 }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="country,province,city" label="地区" width="250">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.country }} - {{ scope.row.province }} -
                                    {{ scope.row.city }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tel" label="手机" width="120">
                            <template slot-scope="scope">
                                <div>{{ scope.row.tel }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ordercount" label="订单数" sortable="custom" width="100">
                            <template slot-scope="scope">
                                <div>{{ scope.row.ordercount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="pid,puser" label="邀请人" width="180">
                            <template slot-scope="scope">
                                <div>{{ scope.row.pid }} | {{ scope.row.puser }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="follow" label="关注公众号" width="120">
                            <template slot-scope="scope">
                                <div v-if="scope.row.follow == 0">未关注</div>
                                <div v-else-if="scope.row.follow == 1">已关注</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sub" label="关注渠道" width="100">
                            <template slot-scope="scope">
                                <div v-if="scope.row.openid_channel == 0">公众号</div>
                                <div v-else-if="scope.row.openid_channel == 1">网页</div>
                                <!-- <div v-if="scope.row.openid_channel==2">公众号搜索</div> -->
                                <div v-else-if="scope.row.openid_channel == 3">小程序</div>
                                <!-- <div v-if="scope.row.openid_channel==4">抖音</div> -->
                                <!-- <div v-if="scope.row.sub=='ADD_SCENE_SEARCH'">公众号搜索</div>
							<div v-else-if="scope.row.sub=='ADD_SCENE_ACCOUNT_MIGRATION'">公众号迁移</div>
							<div v-else-if="scope.row.sub=='ADD_SCENE_PROFILE_CARD'">名片分享</div>
							<div v-else-if="scope.row.sub=='ADD_SCENE_QR_CODE'">扫描二维码</div>
							<div v-else-if="scope.row.sub=='ADD_SCENE_PROFILE_LINK'">图文页内名称点击</div>
							<div v-else-if="scope.row.sub=='ADD_SCENE_PROFILE_ITEM'">图文页右上角菜单</div>
							<div v-else-if="scope.row.sub=='ADD_SCENE_PAID'">支付后关注</div>
							<div v-else="scope.row.sub=='ADD_SCENE_OTHERS'">其他</div> -->
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="65">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status == 0">启用</div>
                                <div v-else-if="scope.row.status == 1" style="color: #f74d4d">冻结</div>
                                <div v-else-if="scope.row.status == 2" style="color: #4fdc76">待审核</div>
                                <div v-else-if="scope.row.status == 3" style="color: #f8c161">未启用</div>
                                <div v-else-if="scope.row.status == 4" style="color: #acacac">审核失败</div>
                                <div v-else="scope.row.status == 5">未注册</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="points" label="积分" width="80">
                            <template slot-scope="scope">
                                <div>{{ scope.row.points }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="last_logintime" label="最后登录时间" width="175">
                            <template slot-scope="scope">
                                <div>{{ scope.row.last_logintime }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" label="注册时间" width="200">
                            <template slot-scope="scope">
                                <div>{{ scope.row.created_at }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="wool" label="羊毛党" width="75" align="center">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.wool" :active-value="1" :inactive-value="0" active-color="#4295FB" inactive-color="#ececec" @change="ckwool(scope.$index, scope.row)">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="320" fixed="right">
                            <template slot-scope="scope">
                                <div class="flex jc_s ai_c">
                                    <el-button class="qxbtn" style="margin-right: 10px" @click="ckqx(scope.$index, scope.row)">权限</el-button>
                                    <div v-if="scope.row.status == 0">
                                        <el-button class="djbtn" @click="djMessage(scope.$index, scope.row)">冻结</el-button>
                                    </div>
                                    <div v-else="scope.row.status == 1">
                                        <el-button class="djbtn" @click="jdMessage(scope.$index, scope.row)">解冻</el-button>
                                    </div>

                                    <!--<el-button class="kqbtn mr10" @click="jfDialog = true">扣除积分</el-button>-->
                                    <el-button class="delbtn" style="margin-left: 10px" @click="delMessage(scope.$index, scope.row)">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--table end-->
                <div class="page pt16">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[2, 5, 8, 10]"
                        :page-size="pageSize"
                        layout=" prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--box end-->
        <!---->
        <el-dialog id="ptquanxian" width="575px" :visible.sync="ptqxDialog">
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">配置推广权限</span>
                    <i class="iconfont iconguanbi colfff f18" @click="ptqxDialog = false"></i>
                </div>
            </div>
            <div class="qxmid">
                <el-form :model="qxForm" :rules="qxrules" ref="qxForm">
                    <div class="qxmidm pb26">
                        <span class="qxmidmtxt"><b>*</b>用户名</span>
                        <el-form-item prop="username">
                            <el-input v-model="qxForm.username" placeholder="输入用户名" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb26">
                        <span class="qxmidmtxt">角色</span>
                        <el-form-item prop="levels">
                            <el-select v-model="qxForm.role_label" class="xzselect" style="width: 100%" @change="changeRole">
                                <el-option v-for="item in roleOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb26">
                        <span class="qxmidmtxt">提成（0~1）</span>
                        <el-form-item prop="percent">
                            <!--<el-select v-model="qxForm.percent" class="xzselect" style="width: 100%;" @change="changePercent">
						<el-option v-for="item in percentOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>-->
                            <el-input v-model="qxForm.percent" placeholder="0" @input="onInputPercent" auto-complete="off" :disabled="true"></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb26">
                        <span class="qxmidmtxt">等级</span>
                        <el-form-item prop="levels">
                            <el-input v-model="qxForm.levels" placeholder="0" @input="onInputPercent" auto-complete="off" :disabled="true"></el-input>
                            <!--<el-select v-model="qxForm.levels" class="xzselect" style="width: 100%;">
						<el-option v-for="item in levelsOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>-->
                        </el-form-item>
                    </div>
                    <div class="qxmidm mb26 mt10">
                        <span class="qxmidmtxt">合伙人类型</span>
                        <el-form-item prop="pusher_class">
                            <el-radio-group v-model="qxForm.pusher_class" class="xzradio" @change="ckleix">
                                <el-radio :label="0">普通合伙人</el-radio>
                                <el-radio :label="1">企业合伙人</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div v-if="qyform == true">
                        <div class="qxmidm pb26">
                            <span class="qxmidmtxt">银行</span>
                            <el-form-item prop="bank_class">
                                <el-select v-model="qxForm.bank_class" class="xzselect" style="width: 100%" placeholder="请选择">
                                    <el-option v-for="item in bankOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="qxmidm pb26">
                            <span class="qxmidmtxt"><b>*</b>收款人</span>
                            <el-form-item prop="bank_user">
                                <el-input v-model="qxForm.bank_user" placeholder="请输入收款人姓名" auto-complete="off"></el-input>
                            </el-form-item>
                        </div>
                        <div class="qxmidm pb26">
                            <span class="qxmidmtxt"><b>*</b>开户行</span>
                            <el-form-item prop="bank_name">
                                <el-input v-model="qxForm.bank_name" placeholder="请输入开户行" auto-complete="off"></el-input>
                            </el-form-item>
                        </div>
                        <div class="qxmidm pb40">
                            <span class="qxmidmtxt"><b>*</b>银行卡</span>
                            <el-form-item prop="bank_no">
                                <el-input v-model="qxForm.bank_no" placeholder="请输入银行卡号" auto-complete="off"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--qyform-->
                    <div class="qxmidm pb45">
                        <el-button type="primary" class="ssbtn" style="width: 5.375rem; margin-right: 5rem" @click="submitForm('qxForm')">确认</el-button>
                        <el-button class="cancelbtn" style="width: 5.375rem" @click="resetForm('qxForm')">取消</el-button>
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>

        <el-dialog id="jifen" width="575px" :visible.sync="jfDialog">
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">扣除积分</span>
                    <i class="iconfont iconguanbi colfff f18" @click="jfDialog = false"></i>
                </div>
            </div>
            <div class="qxmid">
                <el-form>
                    <div class="qxmidm pb40">
                        <span class="qxmidmtxt"><b>*</b>扣除积分数</span>
                        <el-form-item>
                            <el-input-number v-model="num" placeholder="输入扣除积分数目" controls-position="right" :min="0" :max="10" class="xznum"></el-input-number>
                        </el-form-item>
                    </div>

                    <div class="qxmidm pb32">
                        <el-button type="primary" class="ssbtn" style="width: 5.375rem; margin-right: 5rem">确认</el-button>
                        <el-button class="cancelbtn" style="width: 5.375rem" @click="jfDialog = false">取消</el-button>
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    inject: ['reload'],
    data() {
        return {
            valDate: '',
            valSelect: '',
            valSelectOptions: [
                {
                    value: 1,
                    label: '邀请人ID',
                },
                {
                    value: 2,
                    label: 'ID',
                },
                {
                    value: 3,
                    label: '昵称',
                },
                {
                    value: 4,
                    label: '手机号',
                },
                {
                    value: 5,
                    label: 'OPPENID',
                },
            ],
            valSerch: '',
            tableData: [],

            ok: true,
            no: false,
            pageSize: 20,
            currentPage: 1,
            total: 0,
            ptqxDialog: false,
            qxForm: {
                username: '',
                pusher_class: 0,
                percent: '',
                levels: '',
                role: '',
                role_label: '',
                id: '',
                bank_user: '',
                bank_name: '',
                bank_no: '',
                bank_class: '',
            },
            qxrules: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                ],
                bank_user: [
                    {
                        required: true,
                        message: '请输入收款人姓名',
                        trigger: 'blur',
                    },
                ],
                bank_name: [
                    {
                        required: true,
                        message: '请输入开户行',
                        trigger: 'blur',
                    },
                ],
                bank_no: [
                    {
                        required: true,
                        message: '请输入银行卡号',
                        trigger: 'blur',
                    },
                ],
            },

            levelsOptions: [
                {
                    value: 0,
                    label: '一级',
                },
                {
                    value: 1,
                    label: '二级',
                },
            ],
            roleOptions: [
                {
                    value: '',
                    label: '',
                },
            ],
            percentOptions: [],
            qyform: false,

            bankOptions: [
                {
                    value: '1',
                    label: '招商银行',
                },
                {
                    value: '2',
                    label: '中国工商银行',
                },
                {
                    value: '3',
                    label: '中国农业银行',
                },
                {
                    value: '4',
                    label: '中国银行',
                },
                {
                    value: '5',
                    label: '中国建设银行',
                },
                {
                    value: '6',
                    label: '平安银行',
                },
                {
                    value: '7',
                    label: '交通银行',
                },
                {
                    value: '8',
                    label: '中信银行',
                },
                {
                    value: '9',
                    label: '兴业银行',
                },
                {
                    value: '10',
                    label: '光大银行',
                },
                {
                    value: '11',
                    label: '民生银行',
                },
                {
                    value: '12',
                    label: '中国邮政储蓄银行',
                },
                {
                    value: '13',
                    label: '上海浦东发展银行',
                },
                {
                    value: '14',
                    label: '广发银行',
                },
                {
                    value: '15',
                    label: '华夏银行',
                },
            ],
            jfDialog: false,
            num: '',
            sort: {
                sortMode: '',
            },
            condition: {
                search_type: '',
                start_at: '',
                end_at: '',
                s: '',
            },
        }
    }, //data
    mounted() {
        this.getData()

        //this.filter()
    }, //mounted
    methods: {
        sortMethod(e) {
            if (e.order) {
                switch (e.prop) {
                    case 'ordercount':
                        this.sort.sortMode = e.order == 'ascending' ? 'ordercountasc' : 'ordercountdesc'
                        break
                }
            } else {
                this.sort.sortMode = ''
            }
            // ordercountasc:我的订单数   pushcountasc:推广人数    pointsasc:积分   balancedesc:余额
            console.log(this.sort)
            console.log('sortMethod:', e)
            this.getData()
        },
        getData() {
            const that = this
            //获取买家
            //let now = moment().format('YYYY-MM-DD');

            let data = {
                order_by: '',
                search_type: '',
                s: '',
                limit: that.pageSize,
                // offset: that.currentPage-1,
                offset: (that.currentPage - 1) * that.pageSize,
                start_at: '',
                end_at: '',
            }
            data['search_type'] = this.condition.search_type
            data['s'] = this.condition.s
            data['start_at'] = this.condition.start_at
            data['end_at'] = this.condition.end_at

            if (this.sort.sortMode) {
                data.order_by = this.sort.sortMode
            }
            this.$api.getBuyer(data).then((res) => {
                that.total = res.data.data.count
                that.tableData = res.data.data.rows

                //console.log('getdata')
            })

            let data2 = {
                toekn: localStorage.getItem('userInfo'),
            }
            this.$api.getSysShare(data2).then((res) => {
                let datarole = res.data.data.role
                let arrrole = []
                for (let i in datarole) {
                    arrrole.push({
                        value: i,
                        label: datarole[i],
                    })
                }
                that.roleOptions = arrrole
                let datapercent = res.data.data.percent
                let arrpercent = []
                for (let i in datapercent) {
                    arrpercent.push({
                        value: i,
                        label: datapercent[i],
                    })
                }
                that.percentOptions = arrpercent

                //that.roleOptions=roleArr
                //that.percentOptions=res.data.data.percent
                //console.log('arr2',arrrole)
                //console.log('percentOptions',that.percentOptions)
            })
        }, //getData
        formatDate(date) {
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            let d = date.getDate()
            d = d < 10 ? '0' + d : d
            return y + '-' + m + '-' + d
        },
        dateToTimestamp(dateStr) {
            if (!dateStr) {
                return ''
            }
            let newDataStr = dateStr.replace(/\.|\-/g, '/')
            let date = new Date(newDataStr)
            let timestamp = date.getTime()
            return timestamp
        },
        filter() {
            this.total = 0
            this.currentPage = 1

            const that = this
            let fsearch_type
            if (this.valSelect == 1 || this.valSelect == 2 || this.valSelect == 3 || this.valSelect == 4 || this.valSelect == 5) {
                fsearch_type = this.valSelect
            } else {
                fsearch_type = ''
            }
            //console.log('fcash_type',this.valSelect,fsearch_type)
            var stime
            if (!this.valDate[0]) {
                stime = ''
            } else {
                stime = moment(this.valDate[0]).format('YYYY-MM-DD')
            }
            var etime
            if (!this.valDate[1]) {
                etime = ''
            } else {
                etime = moment(this.valDate[1]).format('YYYY-MM-DD')
            }
            this.condition = {
                search_type: fsearch_type,
                start_at: stime,
                end_at: etime,
                s: this.valSerch,
            }
            this.getData()

            // let data = {
            //     order_by: "",
            //     search_type: fsearch_type,
            //     s: that.valSerch,
            //     limit: that.pageSize,
            //     // offset: that.currentPage-1,
            //     offset: (that.currentPage - 1) * that.pageSize,
            //     start_at: stime,
            //     end_at: etime,
            // };
            // if (this.sort.sortMode) {
            //     data.order_by = this.sort.sortMode
            // }
            // this.$api.getBuyer(data).then((res) => {
            //     that.total = res.data.data.count;
            //     that.tableData = res.data.data.rows;
            // });
        }, //filter

        handleSizeChange(val) {
            this.pageSize = val
            //console.log(`每页 ${val} 条`);
            this.getData()
            // this.filter();
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getData()
            // this.filter();
        },
        djMessage(index, row) {
            const that = this
            this.$confirm('冻结用户将禁止该用户一切操作行为...', '确定冻结吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })

                .then(() => {
                    let data = { id: row.id }

                    this.$api.frozenBuyer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                        }
                        location.reload()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消冻结',
                    })
                })
        }, //djMessage
        jdMessage(index, row) {
            const that = this
            this.$confirm('确定解冻吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })

                .then(() => {
                    let data = { id: row.id }

                    this.$api.frozenBuyer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                        }
                        location.reload()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消解冻',
                    })
                })
        }, //jdMessage
        delMessage(index, row) {
            const that = this
            this.$confirm('确定删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = { id: row.id }

                    this.$api.delUser(data).then((res) => {
                        console.log('del', res)
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                        }
                        location.reload()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        }, //delMessage
        ckwool(index, row) {
            const that = this
            // console.log('wool',row.wool)
            let data = { id: row.id }

            this.$api.setWool(data).then((res) => {
                console.log('wool', res)
                if (res.data.code == 0) {
                    that.$message.success(res.data.msg)
                    setTimeout(function () {
                        that.getData()
                    }, 100)
                }
                //location.reload()
            })
        }, //ckwool
        ckleix(e) {
            if (e == 0) {
                this.qyform = false
            } else {
                this.qyform = true
            }
        }, //ckleix
        ckqx(index, row) {
            this.ptqxDialog = true
            this.qxForm.id = row.id
            this.qxForm.levels = row.levels
            this.qxForm.username = row.username
            this.qxForm.role = row.role_level
            if (row.role_level == 1) {
                this.qxForm.role_label = this.roleOptions[0].label
            }
            if (row.role_level == 2) {
                this.qxForm.role_label = this.roleOptions[1].label
            }
            if (row.role_level == 3) {
                this.qxForm.role_label = this.roleOptions[2].label
            }
            if (row.role_level == 4) {
                this.qxForm.role_label = this.roleOptions[3].label
            }

            this.qxForm.percent = row.percent
            //console.log('role_leval',)
        }, //ckqx
        changeRole(e) {
            if (e == 1) {
                this.qxForm.percent = this.percentOptions[0].label
            }
            if (e == 2) {
                this.qxForm.percent = this.percentOptions[1].label
            }
            if (e == 3) {
                this.qxForm.percent = this.percentOptions[2].label
            }
            if (e == 4) {
                this.qxForm.percent = this.percentOptions[3].label
            }
            this.qxForm.role = e
        },
        // changePercent(e){
        // 	if(e==1){
        // 				this.qxForm.role='普通'
        // 			}
        // 			if(e==2){
        // 				this.qxForm.role='青铜'
        // 			}
        // 			if(e==3){
        // 				this.qxForm.role='黄金'
        // 			}
        // 			if(e==4){
        // 				this.qxForm.role='白银'
        // 			}
        // },
        onInputPercent() {
            let rebate = this.qxForm.percent
            let newRebate = rebate.replace(/[^\d.]/g, '')
            newRebate = newRebate.substring(0, 4)
            let splitRebate = newRebate.split('')
            if (splitRebate[0] && splitRebate[0] != '0') {
                splitRebate[0] = ''
            }
            if (splitRebate[1] && splitRebate[1] != '.') {
                splitRebate = ['0']
            }
            this.qxForm.percent = splitRebate.join('')
        }, //onInputPercent
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const that = this
                    let data = {
                        username: that.qxForm.username,
                        pusher_class: that.qxForm.pusher_class,
                        percent: that.qxForm.percent,
                        levels: that.qxForm.levels,
                        role_level: that.qxForm.role,
                        id: that.qxForm.id,
                        bank_user: that.qxForm.bank_user,
                        bank_name: that.qxForm.bank_name,
                        bank_no: that.qxForm.bank_no,
                        bank_class: that.qxForm.bank_class,
                    }
                    // console.log(data)
                    // return false
                    this.$api.setBuyer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                            setTimeout(function () {
                                that.ptqxDialog = false
                                that.getData()
                            }, 100)
                        }
                    })
                }
            })
        }, //submitForm
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.ptqxDialog = false
        },
    }, //methods
}
</script>

<style lang="less" scoped>
</style>