
import moment from 'moment'

let interval = null

function down(time){
    var day = 0
    var hour = '00'
    var min = '00'
    var second = '00'
    let data1 = moment().format('x') //当前时间
    let data2 = moment(time).format('x') * 1 + 3 * 60 * 1000 // 创建时间 + 3 分钟

    // 时间差
    // let leftTime =Math.abs(end - now)
    let leftTime = data2 - data1

    // 定义变量 d,h,m,s保存倒计时的时间
    // console.log(leftTime)
    if (leftTime >= 0) {
      // 天
      day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
      // 时
      let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
      hour = h < 10 ? '0' + h : h
      // 分
      let m = Math.floor(leftTime / 1000 / 60 % 60)
      min = m < 10 ? '0' + m : m
      // 秒
      let s = Math.floor(leftTime / 1000 % 60)
      second = s < 10 ? '0' + s : s
    } else {
      day = 0
      hour = '00'
      min = '00'
      second = '00'
    };
    // 等于0的时候不调用
    // console.log(Number(min) === 0 )
    if (Number(hour) === 0 && Number(day) === 0 && Number(min) === 0 && Number(second) === 0) {
      if(interval){
        clearInterval(interval)
      }
      return {
        day,
        hour,
        min,
        second
      }
    } else {
    // 递归每秒调用countTime方法，显示动态时间效果,
      // setTimeout(down, 1000)
    }
    return {  
      day,
      hour,
      min,
      second
    }
}


export default function countDown(callback,t){
    callback(down(t))
    interval = setInterval(()=>{
       let res = down(t)
       callback(res)
    },1000)
}