import request from './request'

const api = {
  login: (data = {}) => {
    return request({
      url: '/sys/login',
      method: 'POST',
      data
    })
  },
  // 获取首页数据
  getOrderSurvey: (data = {}) => {
    return request({
      url: '/sys/getOrderSurvey',
      method: 'POST',
      data
    })
  },
  //获取用户数统计图
  getUserCount: (data = {}) => {
    return request({
      url: '/sys/getUserCount',
      method: 'POST',
      data
    })
  },
  //获取折线统计图
  getStatistics: (data = {}) => {
    return request({
      url: '/sys/getStatistics',
      method: 'POST',
      data
    })
  },
  //获取访客比例统计
  getVisitor: (data = {}) => {
    return request({
      url: '/sys/getVisitor',
      method: 'POST',
      data
    })
  },
  // 订单管理-竞价列表
  getOffer: (data = {}) => {
    return request({
      url: '/sys/getOffer',
      method: 'POST',
      data
    })
  },
  // 订单管理-撤销报价
  cancelOffer: (data = {}) => {
    return request({
      url: '/sys/cancelOffer',
      method: 'POST',
      data
    })
  },
  // 订单管理-退回订单
  setOrder: (data = {}) => {
    return request({
      url: '/sys/setOrder',
      method: 'POST',
      data
    })
  },
  // 订单管理-获取座位表
  getSeatInfo: (data = {}) => {
    return request({
      url: '/sys/getSeatInfo',
      method: 'POST',
      data
    })
  },
  // 订单管理-获取场次
  getShows: (data = {}) => {
    return request({
      url: '/sys/getShows',
      method: 'POST',
      data
    })
  },
  // 订单管理-出票详情（列表点击）
  getTicketDetailByNo: (data = {}) => {
    return request({
      url: '/sys/getTicketDetailByNo',
      method: 'POST',
      data
    })
  },

  // 订单管理-获取票据详情
  getTicketImg: (data = {}) => {
    return request({
      url: '/sys/getTicketImg',
      method: 'POST',
      data
    })
  },

  // 订单管理-手动写入取票码
  setTicketCode: (data = {}) => {
    return request({
      url: '/sys/setTicketCode',
      method: 'POST',
      data
    })
  },

  // 订单管理-点击出票
  subTicket: (data = {}) => {
    return request({
      url: '/sys/subTicket',
      method: 'POST',
      data
    })
  },

  // 订单管理-删除取票截图
  delTicketImg: (data = {}) => {
    return request({
      url: '/sys/delTicketImg',
      method: 'POST',
      data
    })
  },

  // 订单管理-更新座位表
  updateSeatInfo: (data = {}) => {
    return request({
      url: '/sys/updateSeatInfo',
      method: 'POST',
      data
    })
  },

  // 订单管理-上传图片
  sysUploadImg: (data = {}) => {
    return request({
      url: '/sys/sysUploadImg',
      method: 'POST',
      data
    })
  },

  // 订单管理-释放座位
  unLock: (data = {}) => {
    return request({
      url: '/sys/unLock',
      method: 'POST',
      data
    })
  },

  // 订单管理-下载订单excel
  toFinanceExcel: (data = {}) => {
    return request({
      url: '/sys/toFinanceExcel',
      method: 'POST',
      data
    })
  },
  // 订单管理-转单接口
  transferOrder: (data = {}) => {
    return request({
      url: '/sys/transferOrder',
      method: 'POST',
      data
    })
  },

  //用户管理-冻结
  frozenBuyer: (data = {}) => {
    return request({
      url: '/sys/frozenBuyer',
      method: 'POST',
      data
    })
  },
  //用户管理-删除
  delUser: (data = {}) => {
    return request({
      url: '/sys/delUser',
      method: 'POST',
      data
    })
  },
  //用户管理-买家-获取买家
  getBuyer: (data = {}) => {
    return request({
      url: '/sys/getBuyer',
      method: 'POST',
      data
    })
  },
  //用户管理-买家-编辑权限
  setBuyer: (data = {}) => {
    return request({
      url: '/sys/setBuyer',
      method: 'POST',
      data
    })
  },
  //用户管理-买家-设置羊毛党
  setWool: (data = {}) => {
    return request({
      url: '/sys/setWool',
      method: 'POST',
      data
    })
  },
  //用户管理-商家-获取商家
  getSeller: (data = {}) => {
    return request({
      url: '/sys/getSeller',
      method: 'POST',
      data
    })
  },
  //用户管理-商家-编辑商家
  setSeller: (data = {}) => {
    return request({
      url: '/sys/setSeller',
      method: 'POST',
      data
    })
  },
  //用户管理-推广-获取推广
  getPusher: (data = {}) => {
    return request({
      url: '/sys/getPusher',
      method: 'POST',
      data
    })
  },
  //用户管理-推广-降权
  downAuth: (data = {}) => {
    return request({
      url: '/sys/downAuth',
      method: 'POST',
      data
    })
  },
  //用户管理-推广-初始化密码
  initPass: (data = {}) => {
    return request({
      url: '/sys/initPass',
      method: 'POST',
      data
    })
  },
  //用户管理-推广-编辑
  setPusher: (data = {}) => {
    return request({
      url: '/sys/setPusher',
      method: 'POST',
      data
    })
  },
  //用户管理-推广-分享
  getShareUrl: (data = {}) => {
    return request({
      url: '/sys/getShareUrl',
      method: 'POST',
      data
    })
  },
  //用户管理-客户-获取客户
  getCustomer: (data = {}) => {
    return request({
      url: '/sys/getCustomer',
      method: 'POST',
      data
    })
  },
  //用户管理-客户-搜索客户
  getSearchData: (data = {}) => {
    return request({
      url: '/sys/getSearchData',
      method: 'POST',
      data
    })
  },
  //用户管理-客户-添加新秘钥
  setSecret: (data = {}) => {
    return request({
      url: '/sys/setSecret',
      method: 'POST',
      data
    })
  },
  //用户管理-客户-删除客户
  deleteCustomer: (data = {}) => {
    return request({
      url: '/sys/deleteCustomer',
      method: 'POST',
      data
    })
  },
  //用户管理-客户-更新密钥
  updateCustomer: (data = {}) => {
    return request({
      url: '/sys/updateCustomer',
      method: 'POST',
      data
    })
  },
  //用户管理-获取系统分销
  getSysShare: (data = {}) => {
    return request({
      url: '/sys/getSysShare',
      method: 'POST',
      data
    })
  },
  //常见问题-列表
  getQuestion: (data = {}) => {
    return request({
      url: '/sys/getQuestion',
      method: 'POST',
      data
    })
  },
  //常见问题-发布,修改
  setQuestion: (data = {}) => {
    return request({
      url: '/sys/setQuestion',
      method: 'POST',
      data
    })
  },
  //常见问题-删除
  deleteQuestion: (data = {}) => {
    return request({
      url: '/sys/deleteQuestion',
      method: 'POST',
      data
    })
  },
  //广告管理-获取广告列表
  getAd: (data = {}) => {
    return request({
      url: '/sys/getAd',
      method: 'POST',
      data
    })
  },
  //广告管理-获取电影列表
  getMovie: (data = {}) => {
    return request({
      url: '/sys/getData',
      method: 'POST',
      data
    })
  },
  //广告管理-添加，编辑、删除、下架
  setAd: (data = {}) => {
    return request({
      url: '/sys/setAd',
      method: 'POST',
      data
    })
  },
  uploadAd: (data = {}) => {
    return request({
      url: '/upload/UploadAd',
      method: 'POST',
      data
    })
  },
  //零钱提现-获取提现列表
  getCash: (data = {}) => {
    return request({
      url: '/sys/getCash',
      method: 'POST',
      data
    })
  },
  //零钱提现-提现
  toCash: (data = {}) => {
    return request({
      url: '/sys/toCash',
      method: 'POST',
      data
    })
  },

  // 获取订单列表
  getOrder: (data = {}) => {
    return request({
      url: '/sys/getOrder',
      method: 'POST',
      data
    })
  },
  // 影院规则-影院列表
  getCinemaList: (data = {}) => {
    return request({
      url: '/sys/getCinemaList',
      method: 'POST',
      data
    })
  },
  // 影院规则-设置影院列表
  setCinemaList: (data = {}) => {
    return request({
      url: '/sys/setCinemaList',
      method: 'POST',
      data
    })
  },
  // 影院规则- 批量操作影院列表
  setSomeCinema: (data = {}) => {
    return request({
      url: '/sys/setSomeCinema',
      method: 'POST',
      data
    })
  },
  // 退回订单
  backOrder(data = {}) {
    return request({
      url: '/sys/backOrder',
      method: 'POST',
      data
    })
  },
  // 分配影院
  distributionCinema(data = {}) {
    return request({
      url: '/sys/distributionCinema',
      method: 'POST',
      data
    })
  },
  // 获取省份
  getProvince(data = {}) {
    return request({
      url: '/sys/getProvince',
      method: 'POST',
      data
    })
  },
  // 获取城市
  getCity(data = {}) {
    return request({
      url: '/sys/getCity',
      method: 'POST',
      data
    })
  },
  // 获取影院
  getCinema(data = {}) {
    return request({
      url: '/sys/getCinema',
      method: 'POST',
      data
    })
  },
  // 获取用户
  getSellerList(data = {}) {
    return request({
      url: '/sys/getSellerList',
      method: 'POST',
      data
    })
  },
  // 添加规则
  distributionCinema(data = {}) {
    return request({
      url: '/sys/distributionCinema',
      method: 'POST',
      data
    })
  },
  // 获取规则
  getDistributionCinema(data = {}) {
    return request({
      url: '/sys/getDistributionCinema',
      method: 'POST',
      data
    })
  },
  // 设置规则开关
  setDistributionCinema(data = {}) {
    return request({
      url: '/sys/setDistributionCinema',
      method: 'POST',
      data
    })
  },
}

export default api
