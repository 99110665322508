<template>
    <div class="user">
        <div class="box">
            <div class="menu flex jc_s">
                <a href="/user" class="flex jc_s"
                    ><i class="iconfont iconmaijia_wode"></i>买家</a
                >
                <a href="/userBusiness" class="flex jc_s"
                    ><i class="iconfont iconyonghu1"></i>商家</a
                >
                <a href="/userExtension" class="flex jc_s now"
                    ><i class="iconfont icontuiguang-1"></i>推广</a
                >
               
            </div>
            <div class="p16">
                <div class="pb16 flex jc_b ai_c">
                    <div class="flex jc_s">
                        <i class="iconfont iconzongji f16 lan"></i>
                        <span class="col666 f14 ml10">共{{ total }}个用户</span>
                    </div>
                    <div class="flex jc_end f14 col333 ai_c">
                        <span class="mr20">注册日期：</span>
                        <el-date-picker
                            v-model="valDate"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            :clearable="false"
                            class="xzdate"
                            style="width: 15rem"
                        >
                        </el-date-picker>
                        <span class="mr15 ml26">合伙人类型：</span>
                        <el-select
                            v-model="valSelect"
                            style="width: 7.875rem"
                            class="xzselect"
                        >
                            <el-option
                                v-for="item in valSelectOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        <span class="mr15 ml26">搜索类型：</span>
                        <el-select
                            v-model="valSelectlx"
                            style="width: 7.875rem"
                            class="xzselect"
                        >
                            <el-option
                                v-for="item in valSelectOptionslx"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>

                        <el-input
                            v-model="valSerch"
                            placeholder="请输入"
                            style="width: 19.875rem"
                            class="ml15 mr20 xzinput"
                        ></el-input>
                        <el-button
                            type="primary"
                            class="ssbtn"
                            style="width: 5.375rem"
                            @click="filter"
                            >搜 索</el-button
                        >
                        <el-select
                            v-model="valSelecttg"
                            style="width: 7rem"
                            class="xzselect2 ml20"
                            @change="filter2"
                        >
                            <el-option
                                v-for="item in valSelectOptionstg"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
                <!--menub end-->
                <div class="table">
                    <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                        @sort-change="sortMethod"
                    >
                        <el-table-column prop="id" label="ID" width="60" fixed>
                            <template slot-scope="scope">
                                <div>{{ scope.row.id }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="headimgurl"
                            label="头像"
                            width="68"
                            align="center"
                            fixed
                        >
                            <template slot-scope="scope">
                                <div class="tc">
                                    <img
                                        :src="scope.row.headimgurl"
                                        style="
                                            width: 38px;
                                            width: 38px;
                                            border-radius: 50%;
                                            vertical-align: top;
                                        "
                                    />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="nickname"
                            label="昵称"
                            width="100"
                            fixed
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.nickname }}</div>
                            </template>
                            <!--<template>            
							<p class="col666"><span class="lan">微信：</span>英剧迷</p>
							<p class="col999">公众号：影青庸</p>
						 </template>-->
                        </el-table-column>
                        <el-table-column
                            prop="username,levels"
                            label="用户名"
                            width="110"
                            fixed
                        >
                            <template slot-scope="scope">
                                <p class="col666">{{ scope.row.username }}</p>
                                <div v-if="scope.row.levels == 0">
                                    <p class="hong">一级推广</p>
                                </div>
                                <div v-else="scope.row.levels == 1">
                                    <p class="lan">二级推广</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="openid"
                            label="opendid"
                            width="230"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.openid }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sex" label="性别" width="60">
                            <template slot-scope="scope">
                                <div v-if="scope.row.sex == 0">保密</div>
                                <div v-else-if="scope.row.sex == 1">男</div>
                                <div v-else="scope.row.sex == 2">女</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="country,province,city"
                            label="地区"
                            width="165"
                        >
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.country }}-{{
                                        scope.row.province
                                    }}-{{ scope.row.city }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tel" label="手机" width="120">
                            <template slot-scope="scope">
                                <div>{{ scope.row.tel }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="ordercount"
                            label="我的订单数"
                            sortable="custom"
                            width="120"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.myordercount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="pid,puser"
                            label="邀请人"
                            width="140"
                        >
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.pid }} | {{ scope.row.puser }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="sordercount"
                            label="推广订单数"
                            sortable="custom"
                            width="120"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.ordercount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="pushcount"
                            label="推广人数"
                            sortable="custom"
                            width="110"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.pushcount }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="levels,settling,subsettling"
                            label="待结算"
                            width="70"
                        >
                            <template slot-scope="scope">
                                <div >
                                    {{ scope.row.settling ? scope.row.settling : 0 }}
                                </div>
                                <div >
                                    {{ scope.row.subsettling ? scope.row.subsettling : 0 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="levels,settled,subsettled"
                            label="已结算"
                            width="70"
                        >
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.settled ? scope.row.settled : 0 }}
                                </div>
                                <div>
                                    {{ scope.row.subsettled ? scope.row.subsettled : 0 }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="balance"
                            label="余额"
                            width="90"
                            sortable="custom"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.balance }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="points"
                            label="积分"
                            sortable="custom"
                            width="80"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.points }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="percent"
                            label="提成比例"
                            width="80"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.percent }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="ali_account"
                            label="支付宝账户"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.ali_name }}</div>
                                <div>{{ scope.row.ali_account }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="share_url"
                            label="分享地址"
                            width="180"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.share_url }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="follow"
                            label="关注公众号"
                            width="100"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.follow == 0">未关注</div>
                                <div v-else="scope.row.follow == 1">已关注</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="openid_channel"
                            label="关注渠道"
                            width="85"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.openid_channel == 0">
                                    关注公众号
                                </div>
                                <div v-else-if="scope.row.openid_channel == 1">
                                    非静默
                                </div>
                                <div v-else-if="scope.row.openid_channel == 2">
                                    扫码登录
                                </div>
                                <div v-else-if="scope.row.openid_channel == 3">
                                    小程序
                                </div>
                                <div v-else="scope.row.openid_channel == 4">
                                    抖音
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="status" label="状态" width="65">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status == 0">启用</div>
                                <div
                                    v-else-if="scope.row.status == 1"
                                    style="color: #f74d4d"
                                >
                                    冻结
                                </div>
                                <div
                                    v-else-if="scope.row.status == 2"
                                    style="color: #4fdc76"
                                >
                                    待审核
                                </div>
                                <div
                                    v-else-if="scope.row.status == 3"
                                    style="color: #f8c161"
                                >
                                    未启用
                                </div>
                                <div
                                    v-else-if="scope.row.status == 4"
                                    style="color: #acacac"
                                >
                                    审核失败
                                </div>
                                <div v-else="scope.row.status == 5">未注册</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="last_logintime"
                            label="最后登录时间"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.last_logintime }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="created_at"
                            label="注册时间"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <div>{{ scope.row.created_at }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="权限设置"
                            width="160"
                            fixed="right"
                        >
                            <template slot-scope="scope">
                                <div class="flex jc_s ai_c">
                                    <div v-if="scope.row.status == 0">
                                        <el-button
                                            class="djbtn"
                                            @click="
                                                djMessage(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                            >冻结</el-button
                                        >
                                    </div>
                                    <div v-else="scope.row.status == 1">
                                        <el-button
                                            class="djbtn"
                                            @click="
                                                jdMessage(
                                                    scope.$index,
                                                    scope.row
                                                )
                                            "
                                            >解冻</el-button
                                        >
                                    </div>
                                    <el-button
                                        class="qxbtn"
                                        style="margin-left: 10px"
                                        @click="
                                            jquanMessage(
                                                scope.$index,
                                                scope.row
                                            )
                                        "
                                        >降权</el-button
                                    >
                                    <!--<el-button class="kqbtn mr10" @click="jfDialog = true">扣除积分</el-button>-->
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="260" fixed="right">
                            <template slot-scope="scope">
                                <div class="flex jc_s ai_c">
                                    <el-button
                                        class="djbtn mr10"
                                        style="background: #2db7f5 !important"
                                        @click="ckbj(scope.$index, scope.row)"
                                        >编辑</el-button
                                    >
                                    <el-button
                                        class="kqbtn mr10"
                                        @click="cksc(scope.$index, scope.row)"
                                        >生成</el-button
                                    >
                                    <el-button
                                        class="qxbtn mr10"
                                        @click="
                                            cshMessage(scope.$index, scope.row)
                                        "
                                        >初始化</el-button
                                    >
                                    <el-button
                                        style="margin-left: 10px"
                                        class="delbtn"
                                        @click="
                                            delMessage(scope.$index, scope.row)
                                        "
                                        >删除</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--table end-->
                <div class="page pt16">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[2, 5, 8, 10]"
                        :page-size="pageSize"
                        layout=" prev, pager, next"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--box end-->
        <!---->
        <el-dialog id="jifen" width="575px" :visible.sync="jfDialog">
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">扣除积分</span>
                    <i
                        class="iconfont iconguanbi colfff f18"
                        @click="jfDialog = false"
                    ></i>
                </div>
            </div>
            <div class="qxmid">
                <el-form>
                    <div class="qxmidm pb40">
                        <span class="qxmidmtxt"><b>*</b>扣除积分数</span>
                        <el-form-item>
                            <el-input-number
                                v-model="num"
                                placeholder="输入扣除积分数目"
                                controls-position="right"
                                :min="0"
                                :max="10"
                                class="xznum"
                            ></el-input-number>
                        </el-form-item>
                    </div>

                    <div class="qxmidm pb32">
                        <el-button
                            type="primary"
                            class="ssbtn"
                            style="width: 5.375rem; margin-right: 5rem"
                            >确认</el-button
                        >
                        <el-button
                            class="cancelbtn"
                            style="width: 5.375rem"
                            @click="jfDialog = false"
                            >取消</el-button
                        >
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>
        <el-dialog id="bianji" width="575px" :visible.sync="bjDialog">
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">编辑推广信息</span>
                    <i
                        class="iconfont iconguanbi colfff f18"
                        @click="bjDialog = false"
                    ></i>
                </div>
            </div>
            <div class="qxmid">
                <el-form :model="bjForm" ref="bjForm">
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt"><b>*</b>用户名</span>
                        <el-form-item prop="username">
                            <el-input
                                placeholder=""
                                v-model="bjForm.username"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb20">
                        <span class="qxmidmtxt">角色</span>
                        <el-form-item prop="levels">
                            <el-select
                                v-model="bjForm.role_label"
                                class="xzselect"
                                style="width: 100%"
                                @change="changeRole"
                            >
                                <el-option
                                    v-for="item in roleOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb30">
                        <span class="qxmidmtxt">提成（0~1）</span>
                        <el-form-item prop="percent">
                            <el-input
                                v-model="bjForm.percent"
                                placeholder="0"
                                @input="onInputPercent"
                                auto-complete="off"
                                :disabled="true"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm pb20">
                        <span class="qxmidmtxt">等级</span>
                        <el-form-item prop="levels">
                            <el-input
                                v-model="bjForm.levels"
                                placeholder="0"
                                @input="onInputPercent"
                                auto-complete="off"
                                :disabled="true"
                            ></el-input>
                            <!--<el-select v-model="bjForm.levels" class="xzselect" style="width: 100%;">
						<el-option v-for="item in levelsOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>-->
                        </el-form-item>
                    </div>
                    <div class="qxmidm mb20">
                        <span class="qxmidmtxt">合伙人类型</span>
                        <el-form-item prop="pusher_class">
                            <el-radio-group
                                v-model="bjForm.pusher_class"
                                class="xzradio"
                                @change="ckleix"
                            >
                                <el-radio :label="0">普通合伙人</el-radio>
                                <el-radio :label="1">企业合伙人</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="qxmidm mb20">
                        <span class="qxmidmtxt">支付宝姓名</span>
                        <el-form-item prop="ali_aname">
                            <el-input
                                placeholder=""
                                v-model="bjForm.ali_name"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm mb20">
                        <span class="qxmidmtxt">支付宝账户</span>
                        <el-form-item prop="ali_account">
                            <el-input
                                placeholder=""
                                v-model="bjForm.ali_account"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="qxmidm mb20">
                        <span class="qxmidmtxt">推广渠道</span>
                        <el-form-item prop="push_channel">
                            <el-radio-group
                                v-model="bjForm.push_channel"
                                class="xzradio2 xzradio2b flex jc_b ai_c"
                            >
                                <el-radio :label="0">微信</el-radio>
                                <el-radio :label="1">APP</el-radio>
                                <el-radio :label="2">抖音</el-radio>
                                <el-radio :label="3">百度</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="qxmidm mb10">
                        <span class="qxmidmtxt">渠道备注</span>
                        <el-form-item prop="push_channel_des">
                            <el-input
                                placeholder="输入渠道备注"
                                v-model="bjForm.push_channel_des"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <div
                            class="qxmidm flex-column jc_c mb10"
                            style="height: 32px"
                        >
                            <span class="qxmidmtxt">开启专属</span>
                            <el-form-item prop="exclusive">
                                <el-switch
                                    v-model="bjForm.exclusive"
                                    active-color="#4295FB"
                                    inactive-color="#ececec"
                                    @change="ckzs"
                                >
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div
                            class="qxmidm flex-column jc_c mb10"
                            style="height: 32px"
                        >
                            <span class="qxmidmtxt">是否全部展示模块</span>
                            <el-form-item prop="isAllShow">
                                <el-switch
                                    v-model="bjForm.isAllShow"
                                    active-color="#4295FB"
                                    inactive-color="#ececec"
                                >
                                </el-switch>
                            </el-form-item>
                        </div>
                    </div>

                    <div v-if="zsform == true">
                        <div class="qxmidm mb10">
                            <span class="qxmidmtxt">专属信息</span>
                            <el-form-item prop="exclusive_info">
                                <el-input
                                    placeholder="输入专属信息"
                                    v-model="bjForm.exclusive_info"
                                    auto-complete="off"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div v-if="qyform == true">
                        <div class="qxmidm pb30 mt20">
                            <span class="qxmidmtxt">银行</span>
                            <el-form-item prop="bank_class">
                                <el-select
                                    v-model="bjForm.bank_class"
                                    class="xzselect"
                                    style="width: 100%"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in bankOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="qxmidm pb30">
                            <span class="qxmidmtxt"><b>*</b>收款人</span>
                            <el-form-item prop="bank_user">
                                <el-input
                                    placeholder="请输入收款人姓名"
                                    v-model="bjForm.bank_user"
                                    auto-complete="off"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="qxmidm pb30">
                            <span class="qxmidmtxt"><b>*</b>开户行</span>
                            <el-form-item prop="bank_name">
                                <el-input
                                    placeholder="请输入开户行"
                                    v-model="bjForm.bank_name"
                                    auto-complete="off"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="qxmidm">
                            <span class="qxmidmtxt"><b>*</b>银行卡</span>
                            <el-form-item prop="bank_no">
                                <el-input
                                    placeholder="请输入银行卡号"
                                    v-model="bjForm.bank_no"
                                    auto-complete="off"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--qyform-->

                    <div class="qxmidm pv40">
                        <el-button
                            type="primary"
                            class="ssbtn"
                            style="width: 5.375rem; margin-right: 5rem"
                            @click="submitForm('bjForm')"
                            >确认</el-button
                        >
                        <el-button
                            class="cancelbtn"
                            style="width: 5.375rem"
                            @click="resetForm('bjForm')"
                            >取消</el-button
                        >
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>

        <el-dialog id="shengcheng" width="480px" :visible.sync="scDialog">
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <div class="scmenu">
                        <span :class="{ now: scmeun == 1 }" @click="scnow(1)"
                            >海报</span
                        >
                        <span :class="{ now: scmeun == 2 }" @click="scnow(2)"
                            >推广</span
                        >
                    </div>
                    <i
                        class="iconfont iconguanbi colfff f18"
                        @click="scDialog = false"
                    ></i>
                </div>
            </div>
            <div ref="qrCodeDiv" id="scewm" style="display: none"></div>
            <div class="haib" v-if="scmeun == 1">
                <canvas id="canvasImg"></canvas>
                <!--<img :src="scmovieimgItem" width="386" style="vertical-align: top;" alt="">
			<div class="flex jc_b ai_c pb32">
				<span><img src="../assets/img/hbimg2.png" width="264" alt=""></span>
				<span class="pr20"></span>
			</div>-->

                <div class="flex jc_c ai_c">
                    <el-button
                        type="primary"
                        class="ssbtn"
                        style="width: 86px; margin-right: 80px"
                        @click="scchange"
                        >换一张</el-button
                    >
                    <el-button
                        class="cancelbtn"
                        style="width: 86px"
                        @click="scDialog = false"
                        >取消</el-button
                    >
                </div>
            </div>
            <!--haib end-->
            <div class="tuig" v-if="scmeun == 2">
                <h2 class="mb30">专属链接</h2>
                <p class="col666 f14 pv5">微信H5推广地址：{{ scurl }}</p>
                <p class="col666 f14 pv5">小程序推广地址：{{ wechatUrl }}</p>
                <h2 class="mb10 mt40">二维码</h2>
                <div class="flex jc_c pr15">
                    <span> <canvas id="canvasImg2"></canvas></span>
                </div>
                <h2 class="mb10 mt40">小程序码</h2>
                <div class="flex jc_c pr15">
                    <span><img :src="scmini_qrcode" width="183" alt="" /></span>
                </div>
                <div style="padding: 38px 12px 0 0" class="flex jc_end">
                    <el-button
                        class="cancelbtn"
                        style="width: 86px"
                        @click="scDialog = false"
                        >取消</el-button
                    >
                </div>
            </div>
            <!--tuig end-->
        </el-dialog>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import $ from "jquery";
import store from "../store/index.js";
import moment from "moment";
export default {
    data() {
        return {
            canvas: null,
            context: null,
            valDate: "",
            valSelect: "",
            valSelectOptions: [
                {
                    value: "0",
                    label: "全部合伙人",
                },
                {
                    value: "1",
                    label: "普通合伙人",
                },
                {
                    value: "2",
                    label: "企业合伙人",
                },
            ],
            valSelectlx: "",
            valSelectOptionslx: [
                {
                    value: "1",
                    label: "邀请人ID",
                },
                {
                    value: "2",
                    label: "ID",
                },
                {
                    value: "3",
                    label: "昵称",
                },
                {
                    value: "4",
                    label: "手机号",
                },
                {
                    value: "5",
                    label: "OPPENID",
                },
            ],
            valSerch: "",
            valSelecttg: "",
            valSelectOptionstg: [
                {
                    value: "1",
                    label: "一级推广",
                },
                {
                    value: "2",
                    label: "二级推广",
                },
            ],
            tableData: [],
            pageSize: 20,
            currentPage: 1,
            total: 0,
            jfDialog: false,
            num: "",
            bjDialog: false,
            bjForm: {
                uid: "",
                username: "",
                percent: "",
                pusher_class: 0,
                bank_user: "",
                bank_name: "",
                bank_no: "",
                bank_class: "",
                exclusive_info: "",
                exclusive: 0,
                push_channel: 0,
                push_channel_des: "",
                levels: "",
                role: "",
                role_label: "",
                isAllShow: false,
                ali_name:'',
                ali_account:'',
            },
            // bjrules: {
            // 	 username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
            // 	 bank_user: [{required: true, message: '请输入收款人姓名', trigger: 'blur'}],
            // 	bank_name: [{required: true, message: '请输入开户行', trigger: 'blur'}],
            // 	bank_no: [{required: true, message: '请输入银行卡号', trigger: 'blur'}],

            //  },
            levelsOptions: [
                {
                    value: "0",
                    label: "一级",
                },
                {
                    value: "1",
                    label: "二级",
                },
            ],
            roleOptions: [
                {
                    value: "",
                    label: "",
                },
            ],
            percentOptions: [],
            bankOptions: [
                {
                    value: "1",
                    label: "招商银行",
                },
                {
                    value: "2",
                    label: "中国工商银行",
                },
                {
                    value: "3",
                    label: "中国农业银行",
                },
                {
                    value: "4",
                    label: "中国银行",
                },
                {
                    value: "5",
                    label: "中国建设银行",
                },
                {
                    value: "6",
                    label: "平安银行",
                },
                {
                    value: "7",
                    label: "交通银行",
                },
                {
                    value: "8",
                    label: "中信银行",
                },
                {
                    value: "9",
                    label: "兴业银行",
                },
                {
                    value: "10",
                    label: "光大银行",
                },
                {
                    value: "11",
                    label: "民生银行",
                },
                {
                    value: "12",
                    label: "中国邮政储蓄银行",
                },
                {
                    value: "13",
                    label: "上海浦东发展银行",
                },
                {
                    value: "14",
                    label: "广发银行",
                },
                {
                    value: "15",
                    label: "华夏银行",
                },
            ],

            qyform: false,
            zsform: false,
            scDialog: false,
            scmeun: 1,
            scurl: "",
            scqrurl: "",
            scmini_qrcode: "",
            scmovieimg: [],
            scmovieimgItem: "",
            hbimg: "",
            hbewm: "",
            hbicon: store.state.baseUrl + "/img/bl.png",
            wechatUrl: "",
            tabId: "",

            sort: {
                sortMode: "",
            },
            condition:{
                search_type:'',
                start_at:'',
                end_at:'',
                s:'',
                levels:'',
            }

        };
    }, //data
    mounted() {
        this.getData();
        // console.log(this.hbicon);
    }, //
    methods: {
        sortMethod(e) {
            // sordercount
            if(e.order){
                switch (e.prop) {
                    case "ordercount":
                        this.sort.sortMode = e.order == 'ascending' ? 'myordercountasc' : 'myordercountdesc'
                        break;
                    case "sordercount":
                        this.sort.sortMode = e.order == 'ascending' ? 'ordercountasc' : 'ordercountdesc'
                        break;
                    case "pushcount":
                        this.sort.sortMode = e.order == 'ascending' ? 'pushcountasc' : 'pushcountdesc'
                        break;
                    case "balance":
                        this.sort.sortMode = e.order == 'ascending' ? 'balanceasc' : 'balancedesc'
                        break;
                    case "points":
                        this.sort.sortMode = e.order == 'ascending' ? 'pointsasc' : 'pointsdesc'
                        break;
                }
            }else{
                this.sort.sortMode = ''
            }
            // ordercountasc:我的订单数   pushcountasc:推广人数    pointsasc:积分   balancedesc:余额
            console.log(this.sort);
            console.log("sortMethod:", e);
            this.getData();
        },
        getData() {
            const that = this;
            let data = {
                // balancedesc  降序   balanceasc 升序
                order_by: "",
                search_type: "",
                s: "",
                levels: "",
                limit: that.pageSize,
                // offset: that.currentPage-1,
                offset: (that.currentPage - 1) * that.pageSize,

                pusher_class: "",
                start_at: "",
                end_at: "",
            };
            
            data['search_type'] = this.condition.search_type
            data['s'] = this.condition.s
            data['start_at'] = this.condition.start_at
            data['end_at'] = this.condition.end_at
            data['levels'] = this.condition.levels

            if (this.sort.sortMode) {
                data.order_by = this.sort.sortMode
            }
            this.$api.getPusher(data).then((res) => {
                that.total = res.data.data.count;
                that.tableData = res.data.data.rows;
                //console.log('getdata',res.data.data.rows)
            });
            let data2 = {
                toekn: localStorage.getItem("userInfo"),
            };
            this.$api.getSysShare(data2).then((res) => {
                let datarole = res.data.data.role;
                let arrrole = [];
                for (let i in datarole) {
                    arrrole.push({
                        value: i,
                        label: datarole[i],
                    });
                }
                that.roleOptions = arrrole;
                let datapercent = res.data.data.percent;
                let arrpercent = [];
                for (let i in datapercent) {
                    arrpercent.push({
                        value: i,
                        label: datapercent[i],
                    });
                }
                that.percentOptions = arrpercent;
                // console.log('arr',that.roleOptions)
            });
        }, //getData
        dateToTimestamp(dateStr) {
            if (!dateStr) {
                return "";
            }
            let newDataStr = dateStr.replace(/\.|\-/g, "/");
            let date = new Date(newDataStr);
            let timestamp = date.getTime();
            return timestamp;
        },
        filter() {
            this.total = 0
            this.currentPage = 1

            const that = this;
            let fsearch_type;
            if (
                this.valSelectlx == 1 ||
                this.valSelectlx == 2 ||
                this.valSelectlx == 3 ||
                this.valSelectlx == 4 ||
                this.valSelectlx == 5
            ) {
                fsearch_type = this.valSelectlx;
            } else {
                fsearch_type = "";
            }
            let fpusher_class;
            if (this.valSelect == 1 || this.valSelect == 2) {
                fpusher_class = this.valSelect;
            } else {
                fpusher_class = "";
            }
            var stime;
            if (!this.valDate[0]) {
                stime = "";
            } else {
                stime = moment(this.valDate[0]).format("YYYY-MM-DD");
            }
            var etime;
            if (!this.valDate[1]) {
                etime = "";
            } else {
                etime = moment(this.valDate[1]).format("YYYY-MM-DD");
            }

            this.condition = {
                search_type:fsearch_type,
                start_at:stime,
                end_at:etime,
                s:this.valSerch
            }
            this.getData()
            // let data = {
            //     order_by: "",
            //     search_type: fsearch_type,
            //     s: that.valSerch,
            //     levels: "",
            //     limit: that.pageSize,
            //     offset: (that.currentPage - 1) * that.pageSize,
            //     pusher_class: fpusher_class,
            //     start_at: stime,
            //     end_at: etime,
            // };
            // if (this.sort.sortMode) {
            //     data.order_by = this.sort.sortMode
            // }
            // this.$api.getPusher(data).then((res) => {
            //     that.total = res.data.data.count;
            //     that.tableData = res.data.data.rows;
            // });
        },
        filter2() {
            this.total = 0
            this.currentPage = 1

            let flevels;
            if (this.valSelecttg == 1 || this.valSelecttg == 2) {
                flevels = this.valSelecttg;
            } else {
                flevels = "";
            }
            
            this.condition.levels = flevels
            this.getData()
            // const that = this;
            // let flevels;
            // if (this.valSelecttg == 1 || this.valSelecttg == 2) {
            //     flevels = this.valSelecttg;
            // } else {
            //     flevels = "";
            // }
            // let data = {
            //     order_by: "",
            //     search_type: "",
            //     s: "",
            //     levels: flevels,
            //     limit: that.pageSize,
            //     // offset: that.currentPage-1,
            //     offset: (that.currentPage - 1) * that.pageSize,

            //     pusher_class: "",
            //     start_at: "",
            //     end_at: "",
            // };
            // if (this.sort.sortMode) {
            //     data.order_by = this.sort.sortMode
            // }
            // this.$api.getPusher(data).then((res) => {
            //     that.total = res.data.data.count;
            //     that.tableData = res.data.data.rows;
            //     //console.log('getdata',res.data.data.rows)
            // });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
            // this.filter();
            // this.filter2();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
            // this.filter();
            // this.filter2();
        },
        djMessage(index, row) {
            const that = this;
            this.$confirm(
                "冻结用户将禁止该用户一切操作行为...",
                "确定冻结吗？",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )

                .then(() => {
                    let data = { id: row.id };

                    this.$api.frozenBuyer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                        }
                        location.reload();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消冻结",
                    });
                });
        }, //djMessage
        jdMessage(index, row) {
            const that = this;
            this.$confirm("确定解冻吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })

                .then(() => {
                    let data = { id: row.id };

                    this.$api.frozenBuyer(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                        }
                        location.reload();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消解冻",
                    });
                });
        }, //jdMessage
        jquanMessage(index, row) {
            const that = this;
            this.$confirm("确定撤销该用户的推广权限吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })

                .then(() => {
                    let data = { uid: row.id };
                    // console.log('uid',row.id)

                    this.$api.downAuth(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                        }
                        location.reload();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消撤销",
                    });
                });
        }, //jquanMessage
        delMessage(index, row) {
            const that = this;
            this.$confirm("确定删除吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let data = { id: row.id };

                    this.$api.delUser(data).then((res) => {
                        console.log("del", res);
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                        }
                        location.reload();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        }, //delMessage
        cshMessage(index, row) {
            const that = this;
            this.$confirm("确定将该用户的密码重置为初始密码吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })

                .then(() => {
                    let data = { uid: row.id };
                    this.$api.initPass(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                        }
                        location.reload();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消重置",
                    });
                });
        }, //cshMessage
        ckleix(e) {
            if (e == 0) {
                this.qyform = false;
            } else {
                this.qyform = true;
            }
        }, //ckleix
        ckzs(e) {
            if (e) {
                this.zsform = true;
            } else {
                this.zsform = false;
            }
            console.log("e", e);
        }, //
        ckbj(index, row) {
            // console.log("rowrowrow:", row);
            this.bjDialog = true;
            this.bjForm.uid = row.id;
            this.bjForm.username = row.username;
            this.bjForm.percent = row.percent;
            this.bjForm.levels = row.levels;
            this.bjForm.role = row.role_level;
            this.bjForm.ali_account = row.ali_account;
            this.bjForm.ali_name = row.ali_name;
            // is_show: 1 全部展示  2 不展示
            if (row.is_show == 1) {
                this.bjForm.isAllShow = true;
            }
            if (row.is_show == 2) {
                this.bjForm.isAllShow = false;
            }
            if (row.role_level == 1) {
                this.bjForm.role_label = this.roleOptions[0].label;
            }
            if (row.role_level == 2) {
                this.bjForm.role_label = this.roleOptions[1].label;
            }
            if (row.role_level == 3) {
                this.bjForm.role_label = this.roleOptions[2].label;
            }
            if (row.role_level == 4) {
                this.bjForm.role_label = this.roleOptions[3].label;
            }
            // console.log(this.roleOptions);
            if (row.role_level == 1) {
                this.bjForm.role = this.roleOptions[0].value;
            }
            if (row.role_level == 2) {
                this.bjForm.role = this.roleOptions[1].value;
            }
            if (row.role_level == 3) {
                this.bjForm.role = this.roleOptions[2].value;
            }
            if (row.role_level == 4) {
                this.bjForm.role = this.roleOptions[3].value;
            }
            // console.log(this.bjForm.role);
            //console.log('from', row.id)
        }, //ckbj
        changeRole(e) {
            if (e == 1) {
                this.bjForm.percent = this.percentOptions[0].label;
            }
            if (e == 2) {
                this.bjForm.percent = this.percentOptions[1].label;
            }
            if (e == 3) {
                this.bjForm.percent = this.percentOptions[2].label;
            }
            if (e == 4) {
                this.bjForm.percent = this.percentOptions[3].label;
            }
            this.bjForm.role = e;
        },
        // changePercent(e){
        // 	if(e==1){
        // 				this.bjForm.role='普通'
        // 			}
        // 			if(e==2){
        // 				this.bjForm.role='青铜'
        // 			}
        // 			if(e==3){
        // 				this.bjForm.role='黄金'
        // 			}
        // 			if(e==4){
        // 				this.bjForm.role='白银'
        // 			}
        // },

        cksc(index, row) {
            this.scDialog = true;
            this.scmeun = 1;
            const that = this;
            that.tabId = row.id;
            let data = {
                id: row.id,
            };
            this.$api.getShareUrl(data).then((res) => {
                console.log(res.data.data);
                that.scurl = res.data.data.url; //分享url
                that.scurlqr = res.data.data.qr; //二维码公众号海报url
                that.scmini_qrcode = res.data.data.mini_qrcode;
                that.movieimg = res.data.data.movieImg;
                that.scmovieimgItem = res.data.data.movieImg[0].img; //电影图片
                that.hbimg = res.data.data.movieImg[0].img;
                this.$nextTick(function () {
                    setTimeout(() => {
                        var qrcode = new QRCode(this.$refs.qrCodeDiv, {
                            text: res.data.data.qr,
                            width: 65,
                            height: 65,
                            colorDark: "#000000",
                            colorLight: "#ffffff",
                            correctLevel: QRCode.CorrectLevel.H,
                        }); //qrcode
                    }, 100);

                    that.drawImg();
                });

                setTimeout(function () {
                    that.hbewm = $("#scewm img").attr("src");
                    // $('#tgqr').attr('src',that.hbewm)
                }, 200);
                console.log("hbewm", that.hbewm);

                console.log("qr", res.data.data.qr);
            });
        }, //cksc
        drawImg2() {
            const that = this;
            var canvasImg = document.getElementById("canvasImg2");
            var a = setInterval(() => {
                canvasImg = document.getElementById("canvasImg2");
                if (!canvasImg) {
                    return false;
                } else {
                    clearInterval(a);
                    var ctx = canvasImg.getContext("2d");
                    var imgewm = new Image();
                    imgewm.src = $("#scewm img").attr("src");

                    imgewm.onload = function () {
                        if (imgewm.complete) {
                            canvasImg.setAttribute("width", 170);
                            canvasImg.setAttribute("height", 170);
                            ctx.drawImage(imgewm, 0, 0, 170, 170);
                        }
                    }; //imgewm
                }
            }, 300); //a
        }, //drawimg2
        drawImg() {
            const that = this;
            var canvasImg = document.getElementById("canvasImg");
            var a = setInterval(() => {
                canvasImg = document.getElementById("canvasImg");
                if (!canvasImg) {
                    return false;
                } else {
                    clearInterval(a);
                    var ctx = canvasImg.getContext("2d");
                    var img = new Image();
                    img.src = that.hbimg;

                    img.onload = function () {
                        if (img.complete) {
                            canvasImg.setAttribute("width", 386);
                            canvasImg.setAttribute("height", 624);
                            ctx.fillStyle = "#fff";
                            ctx.fillRect(0, 0, 386, 624);
                            ctx.drawImage(img, 0, 0, 386, 527);
                            let imgicon = new Image();
                            imgicon.src = that.hbicon;
                            imgicon.onload = function () {
                                if (imgicon.complete) {
                                    ctx.drawImage(imgicon, 0, 527, 264, 97);
                                }
                                let imgewm = new Image();
                                imgewm.src = $("#scewm img").attr("src");

                                imgewm.onload = function () {
                                    if (imgewm.complete) {
                                        ctx.drawImage(imgewm, 308, 543, 65, 65);
                                    }
                                }; //imgewm
                            }; //imgicon
                        }
                    }; //img
                }
            }, 300); //a
        }, //draw
        onInputPercent() {
            let rebate = this.qxForm.percent;
            let newRebate = rebate.replace(/[^\d.]/g, "");
            newRebate = newRebate.substring(0, 4);
            let splitRebate = newRebate.split("");
            if (splitRebate[0] && splitRebate[0] != "0") {
                splitRebate[0] = "";
            }
            if (splitRebate[1] && splitRebate[1] != ".") {
                splitRebate = ["0"];
            }
            this.qxForm.percent = splitRebate.join("");
        }, //onInputPercent
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const that = this;
                    let data = {
                        uid: that.bjForm.uid,
                        username: that.bjForm.username,
                        percent: that.bjForm.percent,
                        pusher_class: that.bjForm.pusher_class,
                        bank_user: that.bjForm.bank_user,
                        bank_name: that.bjForm.bank_name,
                        bank_no: that.bjForm.bank_no,
                        bank_class: that.bjForm.bank_class,
                        exclusive_info: that.bjForm.exclusive_info,
                        push_channel: that.bjForm.push_channel,
                        push_channel_des: that.bjForm.push_channel_des,
                        levels: that.bjForm.levels,
                        role_level: that.bjForm.role,
                        ali_name: that.bjForm.ali_name,
                        ali_account: that.bjForm.ali_account,
                    };
                    // is_show
                    if (this.bjForm.isAllShow) {
                        data["is_show"] = 1;
                    } else {
                        data["is_show"] = 2;
                    }
                    // console.log('data:',data)
                    this.$api.setPusher(data).then((res) => {
                        console.log("res", res);
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg);
                            setTimeout(function () {
                                that.bjDialog = false;
                                that.getData();
                            }, 100);
                        }
                    });
                }
            });
        }, //submitForm
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.bjDialog = false;
        }, //
        scnow(e) {
            this.scmeun = e;
            const that = this;
            if (e == 2) {
                that.drawImg2();
                that.wechatUrl = "pages/index/index?scene=" + that.tabId;
            } else {
                that.hbicon = store.state.baseUrl + "/img/bl.png";
                that.hbewm = $("#scewm img").attr("src");
                that.drawImg();
            }
        }, //scnow
        scchange() {
            const that = this;
            var items = that.movieimg;
            that.scmovieimgItem =
                items[Math.floor(Math.random() * items.length)].img;
            that.hbimg = items[Math.floor(Math.random() * items.length)].img;
            that.hbicon = store.state.baseUrl + "/img/bl.png";
            that.hbewm = $("#scewm img").attr("src");
            that.drawImg();
            console.log("that.hbicon", that.hbicon);
        }, //
    }, //methods
};
</script>

<style lang="less" scoped>
</style>