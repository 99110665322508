<template>
    <div class="order">
        <!-- style="min-width: 1024px" style="min-width:1164px"  -->
        <div class="top flex ai_c jc_b">
            <div class="left" style="min-width: 110px">
                <i class="iconfont iconzongji" style="color: #4295fb; font-size: 20px"></i>
                <span style="color: #666666; margin-left: 10px">共{{ count }}条订单</span>
            </div>
            <!-- @change='changeDate' -->
            <div class="right flex jc_end ai_c" style="min-width: 1061px">
                <div class="dateSele">
                    <div class="block">
                        <span class="demonstration">日期：</span>
                        <el-date-picker
                            size="small"
                            v-model="dateSele.value"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <!-- @change='changeType' -->
                <div class="typeSele">
                    类型：
                    <el-select size="small" v-model="typeSele.value" placeholder="请选择" clearable>
                        <el-option v-for="item in typeSele.options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </div>
                <div class="search">
                    <el-input size="small" placeholder="请输入" prefix-icon="el-icon-search" v-model="search.value" clearable @keyup.enter.native="searchBtn"> </el-input>
                </div>
                <div class="btn flex jc_b">
                    <el-button type="primary" size="small" @click="searchBtn">搜索</el-button>
                </div>
                <div>
                    <el-select class="statusSele" size="small" @change="searchBtn" v-model="statusSele.value" placeholder="全部状态">
                        <el-option v-for="item in statusSele.options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="content" v-loading="loading">
            <!-- v-show="countLoading" -->
            <!-- v-delay  -->
            <el-table
                :data="table.tableData"
                style="width: 100%"
                border
                stripe
                :header-cell-style="{
                    background: 'linear-gradient(0deg, #EDEDED 0%, #F7F7F7 51%, #EDEDED 100%)',
                }"
            >
                <el-table-column fixed label="订单" width="230">
                    <template slot-scope="scope">
                        <div class="content_order">
                            <!-- v-if="scope.row.is_express == 1" -->
                            <h4 v-if="scope.row.status < 4 && scope.row.status != 0" class="lanse" style="display: inline-block; line-height: 17px" @click="edit('order', scope.$index, scope.row)">
                                <span  v-if="scope.row.is_express == 1" class="hongse">极</span>
                                {{ scope.row.orderno }}
                                <i class="iconfont iconbianji-01 huise iconLeft"></i>
                            </h4>
                            <h4 v-else class="huise" style="display: inline-block; line-height: 17px">
                                <span  v-if="scope.row.is_express == 1" class="hongse">极</span>
                                {{ scope.row.orderno }}
                            </h4>

                            <br />
                            <div @click="edit('edit', scope.$index, scope.row)">
                                <span :class="[scope.row.offercount == 0 || scope.row.offercount == null ? 'hongse' : 'huise']" style="line-height: 17px"
                                    >{{ scope.row.platform | h5OrMini }}
                                    {{ scope.row.offercount | jingjiaCount }}
                                    <!-- <count
                                        v-if="jingjia(scope.row.created_at)"
                                        :time="scope.row.created_at"
                                    ></count> -->

                                    <span style="color: black" v-if="scope.row.outTime != '已结束'">
                                        {{ scope.row.outTime }}
                                    </span>
                                    <span v-else>竞价结束</span>
                                    <i class="iconfont iconbianji-01 huise iconLeft"></i>
                                </span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="电影" width="160">
                    <template slot-scope="scope">
                        <div>
                            <!-- 状态是：已出票，已关闭，已完成才能显示 出票详情对话框 -->
                            <span class="lanse" v-if="scope.row.status != 0" @click="edit('ticket', scope.$index, scope.row)">{{ scope.row.mn }} </span>
                            <span class="huise" v-else>{{ scope.row.mn }} </span>
                            <span>{{ scope.row.tp }} </span>
                            <span class="hongse">X{{ scope.row.count }}</span>
                            <br />
                            <div class="flex jc_s">
                                <div><span class="seat putong" v-if="scope.row.seat_class == 0">普通座</span><span class="seat qinglv" v-else>情侣座</span></div>
                                <div><span class="seat ketiao" v-if="scope.row.ischange == 1">可调座</span><span class="seat buketiao" v-else>不可调</span></div>
                                <div v-if="scope.row.is_back == 1">
                                    <el-popover placement="right-start" trigger="click" :content="`${scope.row.back_suid} | ${scope.row.back_sname} | ${scope.row.back_reason}`" class="pop">
                                        <span slot="reference" class="seat hongse" style="border: 1px solid #f74d4d">退</span>
                                    </el-popover>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column class="cinema" label="影院" width="220">
                    <template slot-scope="scope">
                        <div>
                            <!-- <span>{{scope.row.cinema.area}}|{{scope.row.cinema.addreass}}</span> -->
                            <el-popover placement="right-start" trigger="click" :content="scope.row.addr" class="pop">
                                <span slot="reference" class="cinameStyle">{{ scope.row.cname }} | {{ scope.row.cn }}</span>
                            </el-popover>
                            <br />
                            <span class="lanse">{{ scope.row.hn }}</span>
                        </div>
                    </template>
                </el-table-column>
                <!-- /下单时间 -->
                <!--  <br />
                            <span>{{ scope.row.created_at }}</span> -->
                <el-table-column prop="totalPrice" label="总价" width="70">
                    <template slot-scope="scope">
                        <div>
                            <span>{{ (scope.row.price * 1000 * scope.row.count) / 1000 }}元</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="一级" width="60">
                    <template slot-scope="scope">
                        <div>
                            <div v-if="scope.row.spuid && !jingjiaOver(scope.row.created_at)">
                                <span class="huise" v-if="scope.row.spuid">{{
                                    ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.spercent).toFixed(2)
                                        ? ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.spercent).toFixed(2)
                                        : 0
                                }}</span>
                            </div>
                            <div v-else-if="scope.row.puid && !jingjiaOver(scope.row.created_at)">
                                <span class="huise" v-if="scope.row.puid">{{
                                    ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.percent).toFixed(2)
                                        ? ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.percent).toFixed(2)
                                        : 0
                                }}</span>
                            </div>
                            <div v-else>
                                <span class="huise">0</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="二级" width="60">
                    <template slot-scope="scope">
                        <div>
                            <div v-if="scope.row.spuid && !jingjiaOver(scope.row.created_at)">
                                <span class="huise" v-if="scope.row.puid">{{
                                    ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.percent).toFixed(2)
                                        ? ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.percent).toFixed(2)
                                        : 0
                                }}</span>
                            </div>
                            <span v-else class="huise"> 0 </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="价格" width="160">
                    <template slot-scope="scope">
                        <div>
                            <!-- pencent 2级   spencent 1级 -->
                            <span class="huise"
                                >市场:{{ scope.row.original_price }}
                                <span v-if="scope.row.settle_price">| 趣满:{{ scope.row.settle_price }}</span>
                            </span>
                            <br />
                            <span
                                >实付:{{ scope.row.price }} <span v-if="scope.row.sell_price"> | 报价:{{ scope.row.sell_price }}</span></span
                            >
                            <br />
                            <!-- 竞价结束展示 一级 二级 -->
                            <!-- <div v-if="scope.row.spuid && !jingjiaOver(scope.row.created_at)">
                                <span class="huise" v-if="scope.row.spuid">一级:{{ ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.spercent).toFixed(2) }}</span>
                                <span v-if="scope.row.puid">|</span>
                                <span v-if="scope.row.puid">二级:{{ ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.percent).toFixed(2) }}</span>
                            </div>
                            <div v-else-if="scope.row.puid && !jingjiaOver(scope.row.created_at)">
                                <span class="huise" v-if="scope.row.puid">一级:{{ ((scope.row.price - scope.row.sell_price) * scope.row.count * scope.row.percent).toFixed(2) }}</span>
                            </div> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="场次座位" width="180">
                    <template slot-scope="scope">
                        <div class="content_seatInfo">
                            <span>{{ scope.row.show_time }}</span>
                            <br />
                            <div class="flex ai_e" v-if="scope.row.trading_seat_string">
                                <el-popover class="custom_popover" placement="right" trigger="click" ref="popover">
                                    <div class="B_changeSeat">
                                        <el-input class="B_changeSeat_input" type="text" v-model="scope.row.copySeat" @keyup.enter.native="B_setSeat(scope.row, true)" />
                                    </div>
                                    <div slot="reference" class="flex fw" style="width: 92%">
                                        <span class="huise" style="margin-right: 5px" v-for="(item, index) in scope.row.trading_seat_string" :key="index">
                                            {{ item }}
                                        </span>
                                    </div>
                                </el-popover>

                                <i v-if="seat(scope.row.show_time)" @click="edit('seat', scope.$index, scope.row)" class="seatIcon iconfont iconbianji-01 huise iconLeft"></i>
                            </div>
                            <div class="flex ai_e" v-else>
                                <el-popover class="custom_popover" placement="right" trigger="click" ref="popover">
                                    <div class="B_changeSeat">
                                        <el-input class="B_changeSeat_input" type="text" v-model="scope.row.copySeat" @keyup.enter.native="B_setSeat(scope.row, true)" />
                                    </div>
                                    <div slot="reference" class="flex fw" style="width: 92%">
                                        <span class="huise" style="margin-right: 5px" v-for="(item, index) in scope.row.seat" :key="index">
                                            {{ item }}
                                        </span>
                                    </div>
                                </el-popover>
                                <i v-if="seat(scope.row.show_time)" @click="edit('seat', scope.$index, scope.row)" class="seatIcon iconfont iconbianji-01 huise iconLeft"></i>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <!-- stauts: 0 已报价  2待出票 4已出票 5已关闭-->
                <el-table-column label="状态" width="120">
                    <template slot-scope="scope">
                        <div>
                            <span v-if="scope.row.status == 0" class="hongse">新订单</span>
                            <div v-else-if="scope.row.status == 2 || scope.row.status == 1" class="hongse">
                                <span>待出票</span>
                                <br />
                                <span class="huise">{{ scope.row.ticket_start | waitTicket }}</span>
                            </div>
                            <span v-else-if="scope.row.status == 3" class="lanse">已出票</span>
                            <span v-else-if="scope.row.status == 4" class="lanse">已完成</span>
                            <span v-else-if="scope.row.status == 5" class="huise">已关闭</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="合伙人" width="150">
                    <template slot-scope="scope">
                        <div v-if="scope.row.puid">
                            <span>{{ scope.row.puid }} | {{ scope.row.pname }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="商家" width="140">
                    <template slot-scope="scope">
                        <div v-if="scope.row.suid">
                            <span>{{ scope.row.suid }} | {{ scope.row.sname }}</span>
                            <br />
                            <!-- <span v-if="scope.row.suid == 5490" class="hongse">商家亏损：{{ ((scope.row.settle_price - scope.row.sell_price) * scope.row.count).toFixed(2) }}</span> -->
                            <span  class="huise">{{ scope.row.stel }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="买家" width="140">
                    <template slot-scope="scope">
                        <div>
                            <span>{{ scope.row.buid }} | {{ scope.row.bname }}</span>
                            <br />
                            <span class="huise">{{ scope.row.btel }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="距离放映" width="120">
                    <template slot-scope="scope">
                        <div>
                            <span>{{ scope.row.show_time | distanceShow }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="下单时间" prop="created_at" width="140"> </el-table-column>
                <el-table-column label="操作" width="120" fixed="right">
                    <template slot-scope="scope">
                        <div>
                            <el-button v-if="scope.row.status != 1 && scope.row.status != 2 && scope.row.status != 3" disabled type="primary" size="mini" @click="showDrawer(scope.$index, scope.row)"
                                >编辑</el-button
                            >
                            <el-button v-else type="primary" size="mini" @click="showDrawer(scope.$index, scope.row)">编辑</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="page_bottom pt16">
            <!-- 分页菜单 -->
            <div class="page">
                <el-pagination background @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-count="pages.totalPage"> </el-pagination>
            </div>
            <div class="expointList">
                <div class="export" @click="exportList('/sys/toOrderExcel','订单列表.xlsx')">导出订单</div>
                <div class="export" @click="exportList('/sys/toFinanceExcel','订单(流水)列表.xlsx')">导出列表(流水)</div>
            </div>
        </div>

        <!-- <el-table id="exportTable" :data="multdata" style="width: 100%; display: none" border>
            <el-table-column label="订单号" width="240" prop="orderno"> </el-table-column>
            <el-table-column label="买家实付" width="240">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.price * scope.row.count).toFixed(2) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="商家盈利" width="240">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.sell_price * scope.row.count).toFixed(2) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="合伙人盈利" width="240">
                <template slot-scope="scope">
                    <div>
                        {{ Number((scope.row.price - scope.row.sell_price) * ((scope.row.percent || 0) + (scope.row.spercent || 0))).toFixed(2) || '0.00' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="平台盈利" width="240">
                <template slot-scope="scope">
                    <div>
                        {{ Number((scope.row.price - scope.row.sell_price) * (1 - (scope.row.percent || 0) - (scope.row.spercent || 0))).toFixed(2) || '0.00' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="400" prop="created_at"> </el-table-column>
            <el-table-column label="流水" width="240">
                <template slot-scope="scope">
                    <div>
                        <div v-if="scope.row.water_type == 0">支付</div>
                        <div v-if="scope.row.water_type == 1">退款</div>
                    </div>
                </template>
            </el-table-column>
        </el-table> -->

        <!-- 对话框 -->
        <el-dialog :visible.sync="dialog.dialogVisible" :width="dialog.dialogWidth" v-if="dialog.dialogVisible">
            <!-- 新订单，待出票，已出票 start -->
            <div class="orderOne" v-if="dialog.isShow == 0">
                <div>
                    <div class="diaTitle flex ai_c">{{ item.orderno }} | {{ item.mn }} | {{ item.cn }}</div>
                </div>
                <div class="diaContent">
                    <div class="top">
                        <div>
                            <span>扣除商家出票率</span>
                            <el-switch v-model="dialog.orderOne.value1" active-color="#4295FB" inactive-color="#ECECEC"> </el-switch>
                        </div>
                        <div>
                            <span>扣除积分</span>
                            <el-switch v-model="dialog.orderOne.value2" active-color="#4295FB" inactive-color="#ECECEC"> </el-switch>
                        </div>
                        <div>
                            <span>关闭订单</span>
                            <el-switch v-model="dialog.orderOne.value3" active-color="#4295FB" inactive-color="#ECECEC"> </el-switch>
                        </div>
                        <div>
                            <span>退款</span>
                            <el-switch v-model="dialog.orderOne.value4" active-color="#4295FB" inactive-color="#ECECEC"> </el-switch>
                        </div>
                    </div>
                    <div class="middle">
                        <span>手续费</span>
                        <el-radio-group v-model="dialog.orderOne.radio1">
                            <el-radio :label="0">无手续费</el-radio>
                            <el-radio :label="5">5元/张</el-radio>
                            <el-radio :label="10">10元/张</el-radio>
                            <el-radio :label="15">15元/张</el-radio>
                            <el-radio :label="20">20元/张</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="bottom flex ai_s">
                        <span>关闭原因</span>
                        <div>
                            <el-radio-group class="radio_g" v-model="dialog.orderOne.radio2">
                                <el-radio :label="1">其他</el-radio>
                                <el-radio :label="2">买家取消订单</el-radio>
                                <el-radio :label="3">无人报价</el-radio>
                                <el-radio :label="4">影院取消场次</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
                <span class="footer flex jc_e">
                    <el-button size="mini" type="primary" @click="editOrder">确 定</el-button>
                    <el-button size="mini" @click="dialog.dialogVisible = false">取 消</el-button>
                </span>
            </div>
            <!-- 新订单，待出票，已出票 end -->

            <!-- 编辑报价  start-->
            <div class="orderOne" v-if="dialog.isShow == 1">
                <div>
                    <div class="diaTitle flex ai_c">编辑报价</div>
                </div>
                <div class="diaContent_two">
                    <el-table
                        :data="dialog.tableData"
                        max-height="400px"
                        border
                        style="width: 100%"
                        :header-cell-style="{
                            background: '#F0EDF1',
                            border: 'none',
                            'font-size': '0.875rem',
                        }"
                    >
                        <el-table-column label="头像" width="70">
                            <template slot-scope="scope">
                                <div>
                                    <el-avatar :src="scope.row.headimgurl"></el-avatar>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="昵称" width="170">
                            <template slot-scope="scope">
                                <div>
                                    <span>{{ scope.row.nickname }} | {{ scope.row.uid }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="报价" width="60">
                            <template slot-scope="scope">
                                <div>
                                    <span>{{ scope.row.price }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="结果" width="80">
                            <template slot-scope="scope">
                                <div>
                                    <span class="hongse" v-if="scope.row.status == 0">竞价中</span>
                                    <span v-if="scope.row.status == 1">撤回</span>
                                    <span v-if="scope.row.status == 2">竞价失败</span>
                                    <span class="lanse" v-if="scope.row.status == 3">竞价成功</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="170" label="时间">
                            <template slot-scope="scope">
                                <div>
                                    <span>{{ scope.row.created_at }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="80">
                            <template slot-scope="scope">
                                <div>
                                    <!-- <el-button size="mini" type="primary"  @click="recall(scope.row)">撤回</el-button> -->
                                    <el-button size="mini" type="primary" v-if="scope.row.status == 0" @click="recall(scope.row)">撤回</el-button>
                                    <!-- 竞价结束后显示禁止点击的撤回 -->
                                    <!-- <el-button
                                        size="mini"
                                        type="primary"
                                        v-else
                                        disabled
                                        >撤回</el-button
                                    > -->
                                    <!-- 转单功能 -->
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        v-if="scope.row.status != 0"
                                        :disabled="scope.row.status == 3 || item.status != 2 ? true : false"
                                        @click="transferOrder(scope.row)"
                                        >转单</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span class="footer flex jc_e">
                    <el-button size="mini" type="primary" @click="dialog.dialogVisible = false">确 定</el-button>
                    <el-button size="mini" @click="dialog.dialogVisible = false">取 消</el-button>
                </span>
            </div>
            <!-- 编辑报价  end-->

            <!-- 出票详情 start -->
            <div class="orderOne" v-if="dialog.isShow == 2">
                <div>
                    <div class="diaTitle flex ai_c">出票详情 ({{ item.length }}张取票码)</div>
                    <div class="diaContent_three">
                        <el-carousel>
                            <el-carousel-item v-for="(item, index) in item" :key="index">
                                <div class="swiper_item">
                                    <img :src="item.src" alt="" />
                                    <div class="text">
                                        <div v-for="(item, index) in item.result" :key="index">
                                            <span>{{ item.text }}: </span>
                                            {{ item.value }}
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
            <!-- 出票详情 end -->

            <!-- 图片详情 start -->
            <div class="orderOne" v-if="dialog.isShow == 3">
                <div>
                    <div class="diaTitle flex ai_c">图片详情</div>
                    <div class="imgContent">
                        <div class="top">
                            <img :src="dialog.imgPre.url" alt="" :style="styleObject" />
                        </div>
                        <div class="bot" v-if="dialog.isBtn">
                            <el-input size="small" v-model="dialog.imgPre.tackTicket" placeholder="取票码" style="margin-bottom: 0.625rem"></el-input>
                            <el-input size="small" v-model="dialog.imgPre.vertiTicket" placeholder="验证码"></el-input>
                            <div class="btn flex" style="justify-content: space-between">
                                <div class="left">
                                    <el-button type="success" size="small" @click.stop.native="split(0)">拆分</el-button>
                                    <el-button type="success" size="small" @click.stop.native="split(1)">以 | 拆分</el-button>
                                </div>
                                <div class="flex jc_c">
                                    <el-button size="small" @click="imgPreCancel">取消</el-button>
                                    <el-button size="small" type="primary" @click="imgPreConfirm">确认</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 图片详情 end -->

            <!-- 座位信息 start  -->
            <div class="orderOne" v-if="dialog.isShow == 4">
                <div>
                    <div class="diaTitle flex ai_c">场次座位信息</div>
                    <div class="seatInfo flex">
                        <!-- 修改座位 -->
                        <div class="left flex-column jc_c ai_c" v-if="seatInfo.isShow" v-loading="seatLoading">
                            <div class="top flex-column jc_c ai_c">
                                <img src="../assets/img/pingmu.png" alt="" />
                                <span class="text">{{ item.hn }}</span>
                            </div>
                            <div class="seatBox flex jc_s">
                                <div class="left_line">
                                    <div class="contain" :style="{ top: -seatInfo.top + 'px' }">
                                        <div v-for="(item, index) in seatInfo.seatList" :key="index">
                                            <div style="height: 34px; line-height: 34px; width: 1.3125rem; text-align: center">
                                                {{ index + 1 }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 非分区 -->
                                <!--  -->
                                <div class="right_box" @scroll="scroll" v-if="seatInfo.isArea == false">
                                    <div class="seatLine" v-for="(item, row) in seatInfo.seatList" :key="row">
                                        <div class="line" style="display: inline-block" v-for="(seat, col) in item" :key="col">
                                            <!-- <img  style="width:30px;height:30px" v-if="seat && seat.state != -1" @click="selectSeat(seat)" :src="seat.seatType | setSeatImg(seat.state)" alt=""> -->
                                            <div style="width: 30px; height: 30px">
                                                <!-- 不分区 -->
                                                <!-- <img  style="width:30px;height:30px" v-if="seat && seat.state != -1 " @click="selectSeat(seat,row,col)" :src="seat.seatType | setSeatImg(seat.state)" alt="">  -->
                                                <img
                                                    style="width: 30px; height: 30px"
                                                    v-if="seat && seat.state != -1"
                                                    @click="selectSeat(seat, row, col)"
                                                    :src="seat.seatType | setSeatImg(seat.state)"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 分区定价 -->
                                <div class="right_box" @scroll="scroll" v-else>
                                    <div class="seatLine" v-for="(item, row) in seatInfo.seatList" :key="row">
                                        <div class="line" style="display: inline-block" v-for="(seat, col) in item" :key="col">
                                            <div style="width: 30px; height: 30px">
                                                <img
                                                    style="width: 30px; height: 30px"
                                                    v-if="seat && seat.state != -1 && seat.state != 0"
                                                    @click="selectSeat(seat, row, col)"
                                                    :src="seat.seatType | setSeatImg(seat.state)"
                                                    alt=""
                                                />
                                                <img
                                                    style="width: 30px; height: 30px"
                                                    v-else-if="seat.state == 0 && seat.area_price != ''"
                                                    @click="selectSeat(seat, row, col)"
                                                    :src="areaPrice(findColor(seat.area_id))"
                                                    alt=""
                                                />
                                                <img
                                                    style="width: 30px; height: 30px"
                                                    v-if="seat.state == 0 && seat.area_price == ''"
                                                    @click="selectSeat(seat, row, col)"
                                                    :src="seat.seatType | setSeatImg(seat.state)"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 更换场次 -->
                        <div class="changeTime" v-else>
                            <div class="haveValue" v-if="seatInfo.tArr.length > 0">
                                <div class="head flex jc_s">
                                    <div class="le_arr arr" @click="changeTime('arr', -1)">
                                        <i class="el-icon-arrow-left"></i>
                                    </div>
                                    <div class="con">
                                        <div
                                            class="tabbar"
                                            :class="[seatInfo.tArr.length <= 5 ? 'flex jc_e' : '']"
                                            :style="[
                                                {
                                                    position: seatInfo.tArr.length <= 5 ? 'none' : 'absolute',
                                                    left: seatInfo.left + 'rem',
                                                },
                                            ]"
                                        >
                                            <div
                                                class="day"
                                                :class="[seatInfo.currentInd == index ? 'index' : '', '']"
                                                :style="{
                                                    width: seatInfo.tArr.length <= 5 ? 'none' : seatInfo.width + 'rem',
                                                }"
                                                v-for="(item, index) in seatInfo.tArr"
                                                :key="index"
                                                ref="day"
                                            >
                                                <div class="flex-column" @click="changeTime('con', index)">
                                                    <span>{{ item | getWeekByDay }}</span>
                                                    <span>{{ item | formatTime1 }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div @click="changeTime('arr', 1)" class="ri_arr arr">
                                        <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                                <div class="change_content">
                                    <div class="movie_city flex jc_e ai_c" v-for="(item, index) in seatInfo.conArr" :key="index" @click="changeSeat(item)">
                                        <div class="">
                                            <div class="">
                                                {{ item.show_time | formateDate('HH:mm') }}
                                            </div>
                                            <div class="huise">{{ item.show_time | getExtendTime(item.duration, 'm', 'HH:mm') }}散场</div>
                                        </div>
                                        <div class="">
                                            <div class="">
                                                {{ item.plan_type }}
                                                {{ item.language }}
                                            </div>
                                            <div class="huise">
                                                {{ item.hall_name }}
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="hongse">￥{{ item.wp_price }}</div>
                                            <div class="huise">
                                                <s> 市场价{{ item.wp_original_price }}</s>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="noValue flex jc_c ai_c" v-else>暂时没有其他场次</div>
                        </div>

                        <div class="right flex-column jc_a">
                            <span>电影：{{ item.mn }}</span>
                            <span>影院：{{ item.cn }}</span>
                            <div class="round flex">
                                <div style="padding-top: 5px">场次时间：</div>
                                <div class="time" style="margin-right: 8px" v-if="seatInfo.sessionTime">
                                    {{ seatInfo.sessionTime | formateDate('YYYY-MM-DD HH:mm:ss') }}
                                </div>
                                <div class="time" style="margin-right: 8px" v-else>
                                    {{ item.show_time }}
                                </div>
                                <div class="hongse" style="padding-top: 5px" @click="changeSession">更换场次</div>
                            </div>
                            <div class="seatI flex ai_c">
                                <div style="padding-top: 5px; min-width: 70px">座位信息：</div>
                                <div class="flex flex_wrap" v-if="seatInfo.optArr.length > 0">
                                    <div class="time" style="margin-top: 0.5rem" v-for="(sInfo, index) in seatInfo.optArr" :key="index">
                                        {{ `${sInfo.row}排${sInfo.col}座` }}
                                    </div>
                                </div>
                                <div class="flex flex_wrap" v-else>
                                    <template v-if="item.trading_seat_string">
                                        <div class="time" style="margin-top: 0.5rem" v-for="(seat, index) in item.trading_seat_string" :key="index">
                                            {{ seat }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="time" style="margin-top: 0.5rem" v-for="(seat, index) in item.seat" :key="index">
                                            {{ seat }}
                                        </div>
                                    </template>
                                </div>
                                <div class="lanse" style="padding-top: 5px; min-width: 70px" @click="changeSeat">修改座位</div>
                            </div>
                            <div class="btn flex">
                                <!-- buySeatDss -->
                                <el-button size="small" type="primary" @click="buySeatDss">确认</el-button>
                                <el-button size="small" @click="buySeatCancel">取消</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 座位信息 end  -->
        </el-dialog>

        <!-- 右侧抽屉 -->
        <el-drawer :size="drawer.size" :visible.sync="drawer.isShow" :with-header="false">
            <div class="drawer">
                <div class="drawer_top">订单详情</div>
                <div class="drawer_content">
                    <div class="order_info">
                        <div>
                            订单号: <span>{{ item.orderno }}</span>
                        </div>
                        <div></div>
                        <div>
                            电影：<span>{{ item.mn }}</span>
                        </div>
                        <div>
                            数量：<span>{{ item.count }}张</span>
                        </div>
                        <div>
                            影院：<span>{{ item.cn }}</span>
                        </div>
                        <div>
                            城市：<span>{{ item.cname }}-{{ item.rname }}</span>
                        </div>
                        <div>
                            场次：<span>{{ item.show_time }}</span>
                        </div>
                        <div>座位类型：<span class="lanse" v-if="item.seat_class == 0">普通座</span><span class="hongse" v-else>情侣座</span></div>
                        <div>
                            影厅：<span>{{ item.hn }}</span>
                        </div>
                        <div>
                            市场价：<span class="hongse">{{ item.original_price }}元</span>
                        </div>
                        <div v-if="item.trading_seat_string">
                            座位信息：<span style="margin-right: 10px" v-for="(item, index) in item.trading_seat_string" :key="index">{{ item }}</span>
                        </div>
                        <div v-else>
                            座位信息：<span style="margin-right: 10px" v-for="(item, index) in item.seat" :key="index">{{ item }}</span>
                        </div>
                        <div>调座：<span class="lvse" v-if="item.ischange == 1">可调座</span><span class="" v-else>不可调</span></div>
                        <div>
                            地址：<span>{{ item.addr }}</span>
                        </div>
                        <div>
                            中标时间：<span>{{ item.ticket_start }}</span>
                        </div>
                    </div>
                    <div class="order_btn flex jc_end">
                        <!-- <el-button size="small" type="danger" @click="open('退回系统')">退回系统</el-button>
                        <el-button size="small" type="danger"  @click="open('返回竞价')">返回竞价</el-button> -->
                    </div>
                    <el-divider></el-divider>
                    <div class="upLoad">
                        <p>上传图片</p>
                        <div class="picture flex">
                            <!-- 显示已上传的图片 -->
                            <div class="left flex" v-if="drawer.ticketImg">
                                <div v-for="(item1, index) in drawer.ticketImg" :key="index">
                                    <img v-if="item1.url" :src="item1.url" alt="" :style="item1.styObj" />
                                    <img v-else :src="item1.src" alt="" :style="item1.styObj" />
                                    <div class="cover">
                                        <div class="top" v-if="item1.result">
                                            <p v-for="(item2, index) in JSON.parse(item1.result)" :key="index">
                                                <span>{{ item2.text }}:{{ item2.value }}</span>
                                            </p>
                                        </div>
                                        <div class="top" v-else style="color: white; text-align: center">请录入取票码</div>

                                        <div class="iconList flex jc_e">
                                            <i class="iconfont iconchakan1 icon" @click="edit('img', index, false)"></i>
                                            <i class="iconfont iconbianji-01 icon" @click="edit('img', index, true)"></i>
                                            <i class="iconfont iconlajitong icon" @click="deleteTicketImg(index)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 上传图片 -->
                            <div class="righ flex" v-loading="drawer.uploagLoading" v-if="drawer.ticketImg.length <= 3">
                                <el-upload
                                    ref="upload"
                                    class="elUp"
                                    action="11"
                                    accept="image/jpeg,image/gif,image/png,image/bmp"
                                    list-type="picture-card"
                                    :http-request="SellerUploadImg"
                                    :before-upload="imgPreview"
                                >
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                    <div class="btn flex jc_end" style="margin-top: 2.8125rem">
                        <div class="f_tui" v-if="item.status == 2">
                            <div class="d_tui">
                                <el-select ref="reason" v-model="reason.seRea" placeholder="请选择" size="mini">
                                    <el-option class="se" v-for="(item, index) in reason.reasonList" :key="index" :label="item.label" :value="item.name"> </el-option>
                                </el-select>
                                <div class="bu" @click="backTicket">订单退回</div>
                            </div>
                        </div>
                        <el-button size="small" type="primary" @click="unLockSeat">释放座位</el-button>
                        <el-button size="small" type="success" @click="commitUpdata">提交更新</el-button>
                    </div>
                </div>
            </div>
        </el-drawer>

        <!-- 订单退回弹出框 -->
        <el-dialog :visible.sync="dialogTableVisible" center width="22%" custom-class="dialog">
            <div class="back">
                <div class="close">
                    <i class="iconfont iconguanbi" style="color: #bfa299; width: 60; height: 60px"></i>
                </div>
                <div class="i">
                    <i class="iconfont icontixing" style="color: #f83a68; width: 60; height: 60px"></i>
                </div>
                <div class="t">
                    <span>请确认是否退回订单？</span>
                    <span>注意：退回订单将扣除信誉值</span>
                </div>
                <div class="b">
                    <div class="le" @click="confirm">确认</div>
                    <div class="ri" @click="cancel">取消</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import count from '../components/countDown.vue'
import axios from 'axios'
import Bus from '../utils/bus'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
    data() {
        return {
            // 图片预览的width height
            styleObject: {
                width: 0,
                height: 0,
            },
            // 右侧抽屉中不能出票的原因
            reason: {
                seRea: '',
                reasonList: [{ name: '其他' }, { name: '出不了票' }, { name: '报错价格' }, { name: '市场价不准确' }],
            },
            // 导出订单的数据
            multdata: [],
            // 加载中
            loading: false,
            // 座位加载
            seatLoading: false,
            // 总订单
            count: 0,
            limit: 15,
            offset: 0,
            params: {
                tag: '', //
                search: '',
                status: '', //  -1 全部状态  0 新订单  2 待出票  3 已出票   4 已完成  5 已关闭
                start_at: '',
                end_at: '',
            },
            // startTime: new Date(),
            dateSele: {
                startTime: '开始日期',
                endTime: '结束日期',
                value: '',
            },
            typeSele: {
                options: [
                    // {
                    //     value: "",
                    //     label: "请选择",
                    // },
                    {
                        value: '1',
                        label: '订单号',
                    },
                    {
                        value: '2',
                        label: '电影',
                    },
                    {
                        value: '3',
                        label: '影院',
                    },
                    {
                        value: '4',
                        label: '城市',
                    },
                    {
                        value: '6',
                        label: '手机号',
                    },
                    {
                        value: '8',
                        label: 'ID号',
                    },
                    {
                        value: '9',
                        label: '推广人ID',
                    },
                ],
                value: '',
            },
            statusSele: {
                //  -1 全部状态  0 新订单  2 待出票  3 已出票   4 已完成  5 已关闭
                options: [
                    {
                        value: '-1',
                        label: '全部状态',
                    },
                    {
                        value: '0',
                        label: '新订单',
                    },
                    {
                        value: '2',
                        label: '待出票',
                    },
                    {
                        value: '3',
                        label: '已出票',
                    },
                    {
                        value: '4',
                        label: '已完成',
                    },
                    {
                        value: '5',
                        label: '已关闭',
                    },
                ],
                value: '',
            },
            search: {
                value: '',
            },
            table: {
                disabled: false,
                //   tableData: order,
                tableData: [],
            },
            item: '',
            // 右侧弹出框显示的图片
            imgItem: '',
            // 分页
            pages: {
                totalPage: 0,
                currentPage: 1,
            },
            // 对话框
            dialog: {
                dialogVisible: false,
                dialogWidth: '40%',
                isShow: 0, //0 订单 1表格 2出票 3图片详情 4座位信息
                isBtn: true, //判断是否显示图片详情的 底部信息
                // 图片预览
                imgPre: {
                    id: '',
                    url: '',
                    tackTicket: '',
                    vertiTicket: '',
                    index: '',
                },
                // input1:'',
                // input2:'',
                // 新订单，待出票，已出票
                orderOne: {
                    value1: false,
                    value2: false,
                    value3: false,
                    value4: false,
                    radio1: 0,
                    radio2: 1,
                },
                tableData: [],
            },
            // 右侧抽屉
            drawer: {
                size: 700,
                isShow: false,
                // 保存显示的图片
                ticketImg: [],
                // 显示加载
                uploagLoading: false,
                // 上传图片
                upLoad: {
                    dialogImageUrl: '',
                    dialogVisible: false,
                },
            },
            // 图片详情对话框
            ticketDialog: {
                dialogVisible: true,
            },
            // 座位详情，时间修改
            seatInfo: {
                seatList: [],
                top: 0,
                isShow: true,
                // 更换场次的时间
                //
                sessionTime: '',
                tArr: '',
                totalArr: '',
                conArr: '',
                left: 0,
                width: 0,
                currentInd: 0,
                showId: '',
                // 座位

                optArr: [],
                SelectNum: '',
                isArea: false,
                zoneObj: [],
                zonePrice: [],
                seatRow: 0, //影院座位行数
                seatCol: 0, //影院座位列数
                minRow: 0, //从第几行开始排座位
                minCol: 0, //从第几列开始排座位
            },

            choiceDate: '',
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    // 把选择的第一个日期赋值给一个变量。
                    this.choiceDate = minDate.getTime()
                    // 如何你选择了两个日期了，就把那个变量置空
                    if (maxDate) this.choiceDate = ''
                },
                disabledDate: (time) => {
                    // console.log("time",time)
                    // 如何选择了一个日期
                    if (this.choiceDate) {
                        // 7天的时间戳
                        const one = 31 * 24 * 3600 * 1000
                        // 当前日期 - one = 7天之前
                        const minTime = this.choiceDate - one
                        // 当前日期 + one = 7天之后
                        const maxTime = this.choiceDate + one
                        return (
                            time.getTime() < minTime ||
                            time.getTime() > maxTime ||
                            // 限制不能选择今天及以后
                            time.getTime() > Date.now()
                        )
                    } else {
                        // 如果没有选择日期，就要限制不能选择今天及以后
                        return time.getTime() > Date.now()
                    }
                },
            },

            // 计时器
            timer: '',

            //
            dialogTableVisible: false,
        }
    },
    computed: {},
    components: {
        count,
    },
    created() {
        this.dateSele.startTime = moment().format('YYYY.MM.DD')
        this.getData()
    },
    mounted() {
        Bus.$on('socketMessage', () => {
            console.log('发送订单请求')
        })
    },
    methods: {
        exportOrder(){

        },
        // 转单提示框
        transferOrder(item) {
            this.$confirm(`是否转单给"${item.nickname}"?`, '确认信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    let data = {
                        offer_id: item.id,
                    }
                    this.$api
                        .transferOrder(data)
                        .then((res) => {
                            // console.log("res",res.data)
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '转单成功',
                                    type: 'success',
                                })
                            }
                        })
                        .catch((err) => {
                            console.log('转单error', err)
                        })
                    this.dialog.dialogVisible = false
                })
                .catch((action) => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '取消转单' : '',
                    })
                })
        },

        //修改座位 B 计划
        async B_setSeat(item, type) {
            //true : 手动修改座位   false:通过修改场次修改座位
            // 数据库 Id ,数据库中的数组
            let showId, seatList
            if (type) {
                document.body.click()
                let bol = await this.getInfoSeat(item.orderno)
                if (!bol) {
                    this.$message.error('未能获取座位信息，座位修改失败')
                    return
                }
                showId = bol.show_id
                seatList = bol.seatList
            } else {
                this.dialog.dialogVisible = false
                showId = item.showId
                let list = []
                item.seatList.forEach((item) => {
                    list = [...item, ...list]
                })
                seatList = list
                item = item.currentItem
            }

            let arr = null
            let setArr = []
            let newArr = []
            if (!item.copySeat.includes(',') && !item.copySeat.includes('，')) {
                arr = [item.copySeat]
            } else {
                if (item.copySeat.includes(',')) {
                    arr = item.copySeat.split(',')
                } else {
                    arr = item.copySeat.split('，')
                }
            }
            arr = [...new Set(arr)]
            // 匹配 row col
            arr.forEach((item) => {
                let obj = {}
                let reg = /([A-Za-z\d]+)排([A-Za-z\d]+)/gim
                let res = reg.exec(item)
                obj.row = res[1]
                obj.col = res[2]
                setArr.push(obj)
            })
            // 对比取值
            setArr.forEach((itemA) => {
                seatList.forEach((itemB) => {
                    let reg = /([A-Za-z\d]+)/gim
                    let bRow = reg.exec(itemB.row)
                    let reg2 = /([A-Za-z\d]+)/gim
                    let bcol = reg2.exec(itemB.col)
                    if (itemA.row == bRow[1] && itemA.col == bcol[1]) newArr.push(itemB)
                })
            })
            let paramsObj = {
                showId: showId,
                seatList: newArr,
                no: item.orderno,
            }
            this.setSeatB(paramsObj)
        },
        // 获取座位信息
        getInfoSeat(order) {
            let that = this
            return new Promise((resolve, reject) => {
                let data = {
                    no: order,
                }
                that.$api
                    .getSeatInfo(data)
                    .then((res) => {
                        if (res.data.code === 0) {
                            resolve(res.data.data)
                        } else {
                            resolve(false)
                        }
                    })
                    .catch(() => {
                        reject(false)
                    })
            })
        },
        // 座位更新B计划
        setSeatB({ showId, seatList, no }) {
            let seatId = [],
                seat = []
            seatList.forEach((item) => {
                seat.push(item.row + '排' + item.col + '座')
                seatId.push(item.id)
            })
            let data = {
                no: no,
                seat: seat.join(','),
                seatId: seatId.join(','),
                show_id: showId,
            }
            this.$api.updateSeatInfo(data).then((res) => {
                if (res.data.code === 0) {
                    this.getData(1, this.params)
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                    })
                } else {
                    this.$message.error('修改失败')
                }
            })
        },
        // 确认订单退回
        confirm() {
            let that = this
            let data = {
                _id: 1,
                orderno: this.item.orderno,
                reason: this.reason.seRea,
            }
            // console.log('data---------',data)
            this.$api.backOrder(data).then((res) => {
                if (res.data.code == 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                    })
                    that.getData()
                }
            })
            this.dialogTableVisible = false
            this.drawer.isShow = false
        },
        // 订单退回取消
        cancel() {
            this.dialogTableVisible = false
        },
        // 未选原因订单退回
        backTicket() {
            if (!this.reason.seRea) {
                this.$notify({
                    title: '警告',
                    message: '请选择退回的原因',
                    type: 'warning',
                })
            } else {
                this.dialogTableVisible = true
            }
        },
        // 取票码拆分
        split(type) {
            // console.log(this.imgPre)
            if (this.dialog.imgPre.vertiTicket) {
                this.$message({
                    type: 'info',
                    message: '验证码有值，不需要拆分',
                })
            } else {
                if (this.dialog.imgPre.tackTicket) {
                    switch (type) {
                        case 0:
                            let index = Math.ceil(this.dialog.imgPre.tackTicket.length / 2)
                            let dd = this.dialog.imgPre.tackTicket
                            this.dialog.imgPre.tackTicket = this.dialog.imgPre.tackTicket.toString().substring(0, index)
                            this.dialog.imgPre.vertiTicket = dd.substring(index)
                            break
                        case 1:
                            if (this.dialog.imgPre.tackTicket.includes('|')) {
                                let code = this.dialog.imgPre.tackTicket.split('|')
                                this.dialog.imgPre.tackTicket = code[0]
                                this.dialog.imgPre.vertiTicket = code[1]
                            } else {
                                this.$message({
                                    type: 'info',
                                    message: '没有 | 分割',
                                })
                            }
                            break
                        default:
                            break
                    }
                }
            }
        },
        getTimestamp(date) {
            // console.log(date)
            return moment(date).utcOffset(8).valueOf()
        },
        // 倒计时处理
        NewOrderInitTime(endtime, format = 'mm-ss', outTip = '00分00秒') {
            // 倒计时初始化
            var hh = null,
                mm = null,
                ss = null
            let str = ''
            var time = parseInt(this.getTimestamp(endtime)) - new Date().getTime()
            if (time <= 0) {
                return outTip
            } else {
                hh = Math.floor(time / 1000 / 60 / 60 / 24) * 24 + Math.floor((time / 1000 / 60 / 60) % 24)
                ss = Math.floor((time / 1000) % 60)
                if (format == 'mm-ss') {
                    mm = hh * 60 + Math.floor((time / 1000 / 60) % 60)
                    str = mm + '分' + ss + '秒'
                    return str
                } else if (format == 'hh-mm') {
                    mm = Math.floor((time / 1000 / 60) % 60)
                    str = hh + '时' + mm + '分'
                }
                return str
            }
        },
        // 判断是否竞价中
        jingjia(val) {
            let data1 = moment().format('x') //当前时间
            // let data2 = this.moment(val).format('x') * 1 + 1000 * 60 * 1000 // 创建时间 + 3 分钟
            let data2 = moment(val).format('x') * 1 + 3 * 60 * 1000 // 创建时间 + 3 分钟
            if (data1 < data2) {
                return true
            } else {
                return false
            }
        },
        // 下载订单
        exportList(url,title) {
            // console.log('toFinanceExcel', this.params)
            let token = this.$store.state.userInfo.token
            let headers = {
                token: token,
            }
            let data = {}
            if (this.dateSele.value) {
                // console.log("this.dateSele.value:",this.dateSele.value)
                data['start_at'] = moment(this.dateSele.value[0]).format('x')
                data['end_at'] = moment(this.dateSele.value[1]).format('x')
            }
            let otherParams = {}
            for (const key in this.params) {
                if (this.params[key]) {
                    // console.log("params[key]",params[key])
                    otherParams[key] = this.params[key]
                } else if (this.params[key] === 0) {
                    otherParams[key] = this.params[key]
                }
            }
            Object.assign(data,otherParams)
            axios({
                method: 'post',
                data: data,
                url: url,
                responseType: 'blob',
                headers: headers,
            }).then((res) => {
                let blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel',
                })
                FileSaver.saveAs(blob, `${title}`)
            })
        },
        // 判断竞价是否结束
        jingjiaOver(val) {
            let data1 = moment().format('x') //当前时间
            // let data2 = this.moment(val).format('x') * 1 + 1000 * 60 * 1000 // 创建时间 + 3 分钟
            let data2 = moment(val).format('x') * 1 + 3 * 60 * 1000 // 创建时间 + 3 分钟
            if (data1 < data2) {
                return true
            } else {
                return false
            }
        },
        // 右侧抽屉-提交更新
        commitUpdata() {
            // console.log(this.item)
            let data = {
                no: this.item.orderno,
                id: this.item.id,
                tag: this.item.status,
            }
            this.$api
                .subTicket(data)
                .then((res) => {
                    console.log()
                    if (res.data.msg == '出票成功') {
                        this.$message({
                            type: 'success',
                            message: '出票成功',
                        })
                        this.drawer.isShow = false
                        this.getData()
                    }
                })
                .catch((err) => {
                    // this.$message.error('出票失败')
                    console.log('出票失败:', err)
                })
            // console.log(data)
        },
        // 右侧抽屉-提交更新
        unLockSeat() {
            let data = {
                id: this.item.id,
            }
            this.$api
                .unLock(data)
                .then((res) => {
                    console.log(res)
                    if (res.data.data.msg == '解锁成功') {
                        this.drawer.isShow = false
                        this.$message({
                            type: 'success',
                            message: '释放座位操作成功',
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            message: `${res.data.data.msg}`,
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 图片详情-删除
        deleteTicketImg(index) {
            this.$confirm('确定要删除该图片吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    // console.log(index)
                    // console.log(this.drawer.ticketImg[index])
                    let data = {
                        id: this.drawer.ticketImg[index].id,
                        no: this.item.orderno,
                    }
                    this.$api
                        .delTicketImg(data)
                        .then((res) => {
                            console.log()
                            if (res.data.data.msg === 'ok') {
                                let arr = this.drawer.ticketImg
                                arr.splice(index, 1)
                                this.drawer.ticketImg = arr
                                this.$message({
                                    type: 'success',
                                    message: '图片删除成功',
                                })
                            }
                        })
                        .catch((err) => {
                            this.$message.error('图片删除失败')
                            console.log(err)
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: `取消删除`,
                    })
                })
        },
        // 图片详情-确认
        imgPreConfirm() {
            let data = {
                code: this.dialog.imgPre.tackTicket,
                yzm: this.dialog.imgPre.vertiTicket,
                no: this.item.orderno,
                id: this.dialog.imgPre.id,
            }
            // console.log(this.item)
            this.$api
                .setTicketCode(data)
                .then((res) => {
                    this.drawer.ticketImg = res.data.data
                    this.$message({
                        type: 'success',
                        message: '录入成功',
                    })
                    this.dialog.dialogVisible = false
                    // console.log(this.drawer.ticketImg )
                })
                .catch((err) => {
                    this.$message.error('录入失败')
                })
        },
        // 图片详情-取消
        imgPreCancel() {
            this.dialog.dialogVisible = false
        },
        // 自定义图片上传
        SellerUploadImg(file) {
            let newFile = URL.createObjectURL(file.file)
            // console.log('SellerUploadImg:',file.file)
            let form = new FormData()
            form.append('file', file.file)
            form.append('no', this.item.orderno)

            let token = this.$store.state.userInfo.token
            let config = {
                headers: {
                    token: token,
                },
            }
            // let url = 'http://api_wapiao.test/upload/sysUploadImg' //本地
            let url = '/upload/sysUploadImg' //线上
            // let url = 'http://api.yjpiao.cn/upload/sysUploadImg' // 客户
            axios
                .post(url, form, config)
                .then((res) => {
                    // console.log(res.data.data)
                    this.drawer.uploagLoading = false
                    this.drawer.ticketImg.push(res.data.data)
                    console.log(this.drawer.ticketImg)
                })
                .catch((err) => {
                    // console.log(err)
                    this.drawer.uploagLoading = false
                    this.$message.error('图片上传失败')
                })
        },
        // 图片上传前的处理
        imgPreview(file) {
            // console.log('123123456446454')
            return new Promise((resolve, reject) => {
                this.drawer.uploagLoading = true
                let fileSize = file.size / 1024 / 1024
                if (fileSize > 1) {
                    console.log('需要压缩')
                    // ＞1M需要进行压缩
                    let image = new Image(),
                        resultBlob = ''
                    image.src = URL.createObjectURL(file)
                    image.onload = () => {
                        resultBlob = this.compressUpload(image, file)
                        resolve(resultBlob)
                    }
                } else {
                    console.log('不需要压缩不需要压缩不需要压缩')
                    resolve()
                }
            })
        },
        // 图片压缩方法 canvas 压缩
        compressUpload(image, file) {
            // console.log(image)
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')
            let initSize = image.src.length
            let { width } = image,
                { height } = image
            canvas.width = width
            canvas.height = height
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(image, 0, 0, width, height)
            // 进行最小压缩0.1
            let compressData = canvas.toDataURL(file.type || 'image/jpeg', 0.1)

            // 压缩后调用方法进行base64转Blob，方法写在下边
            let blobImg = this.dataURItoBlob(compressData)
            return blobImg
            // let data = URL.createObjectURL(blobImg)
            // console.log(blobImg.size / 1024 / 1024)
        },
        // base64 转 Blob 对象
        dataURItoBlob(data) {
            let byteString
            if (data.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(data.split(',')[1])
            } else {
                byteString = unescape(data.split(',')[1])
            }
            let mimeString = data.split(',')[0].split(':')[1].split(';')[0]
            let ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i += 1) {
                ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], { type: mimeString })
        },
        // //设置分区
        areaPrice: (i) => {
            let data = {
                0: require('../../src/assets/img/1.png'),
                1: require('../../src/assets/img/2.png'),
                2: require('../../src/assets/img/3.png'),
                3: require('../../src/assets/img/4.png'),
                4: require('../../src/assets/img/5.png'),
            }
            let img = data[i]
            return img
        },
        // 数组排序
        sortNum(a, b) {
            return b[1] - a[1]
        },
        findColor(num) {
            for (let i = 0; i < this.seatInfo.zonePrice.length; i++) {
                if (num === this.seatInfo.zonePrice[i][0]) {
                    return this.seatInfo.zonePrice[i][2]
                }
            }
        },
        // 场次座位 - 更换场次
        changeSession() {
            this.seatInfo.isShow = false
            this.seatInfo.left = 0
            this.seatInfo.currentInd = 0
            this.seatInfo.tArr = ''
            this.seatInfo.conArr = ''

            let showDateDay = []
            let showData = []
            let data = {
                no: this.item.orderno,
            }
            this.$api.getShows(data).then((res) => {
                showDateDay = res.data.data.showDateDay
                showData = res.data.data.showData
                let arr1 = []
                showDateDay.forEach((item) => {
                    if (arr1.indexOf(item) == -1) {
                        arr1.push(item)
                    }
                })
                let arr2 = new Array(arr1.length)
                for (let index = 0; index < arr2.length; index++) {
                    arr2[index] = []
                }
                showData.forEach((item) => {
                    let i = arr1.indexOf(item.showDate)
                    arr2[i].push(item)
                })
                this.seatInfo.tArr = arr1
                this.seatInfo.totalArr = arr2
                this.seatInfo.conArr = arr2[0]
            })
        },
        // 场次座位 - 更换座位
        changeSeat(item) {
            this.seatLoading = true
            this.seatInfo.isArea = false
            this.seatInfo.zoneObj = []
            this.seatInfo.zonePrice = []
            this.seatInfo.SelectNum = ''
            this.seatInfo.optArr = []
            this.seatInfo.isShow = true
            // console.log(item)
            let data = {
                no: this.item.orderno,
            }
            if (item) {
                this.seatInfo.sessionTime = item.show_time
                data.show_id = item.show_id
            }
            this.$api.getSeatInfo(data).then((res) => {
                this.seatInfo.showId = res.data.data.show_id
                this.initSeat(res.data.data)
                this.seatLoading = false
            })
        },
        //  场次座位 - 选中座位
        selectSeat(seat, row, col) {
            // -1为非座位，0为未购座位，1为已选座位(绿色),2为已购座位(红色),

            // console.log('seat',seat)
            // console.log("row,col",this.seatInfo.seatList[row][col])
            // console.log('this.selectNum',this.seatInfo.SelectNum)

            let seatValue = this.seatInfo.seatList[row][col].state
            let newArray = this.seatInfo.seatList
            //如果是已购座位，直接返回
            if (seatValue === 2 || seatValue === -1) return
            //如果是已选座位点击后变未选
            let data = newArray[row][col]
            data.seat = {
                row: row,
                col: col,
            }
            let data2 = ''
            if (data.seatType == 1 || data.seatType == 2) {
                let i = 1
                if (data.seatType == 1) i = i * 1
                else if (data.seatType == 2) i = i * -1
                data2 = newArray[row][col + i]
                data2.seat = {
                    row: row,
                    col: col + i,
                }
            }
            if (seatValue === 1) {
                data.state = 0
                if (data2) {
                    data2.state = 0
                    this.seatInfo.SelectNum -= 2
                    this.getOptArr(data2, 0)
                } else {
                    this.seatInfo.SelectNum--
                }
                this.getOptArr(data, 0)
            } else if (seatValue === 0) {
                if (Number(this.maxChoose) != 0) {
                    if (this.seatInfo.optArr.length >= Number(this.item.seat.length)) {
                        this.$message({
                            type: 'warning ',
                            message: `最多只能修改${this.item.seat.length}个座位`,
                        })
                        return
                    }
                }
                if (data2) {
                    if (this.seatInfo.optArr.length + 2 > Number(this.item.seat.length)) {
                        this.$message({
                            type: 'warning ',
                            message: `最多只能修改${this.item.seat.length}个座位`,
                        })
                        return
                    }
                }
                data.state = 1
                if (data2) {
                    data2.state = 1
                    this.seatInfo.SelectNum += 2
                    this.getOptArr(data2, 1)
                } else {
                    this.seatInfo.SelectNum++
                }
                this.getOptArr(data, 1)
            }
            //必须整体更新二维数组，Vue无法检测到数组某一项更新,必须slice复制一个数组才行
            this.seatInfo.seatList = newArray.slice()
        },
        // 处理已选座位数组
        getOptArr(item, state) {
            let optArr = this.seatInfo.optArr
            if (state === 1) {
                optArr.push(item)
            } else if (state === 0) {
                let arr = []
                optArr.forEach((v) => {
                    if (v.id !== item.id) {
                        arr.push(v)
                    }
                })
                optArr = arr
            }
            this.seatInfo.optArr = optArr.slice()
        },
        //   右侧抽屉
        showDrawer(index, row) {
            let that = this
            this.drawer.isShow = true
            this.item = row
            let data = {
                no: this.item.orderno,
            }
            this.$api
                .getTicketImg(data)
                .then((res) => {
                    this.drawer.ticketImg = res.data.data
                    this.drawer.ticketImg.forEach((item) => {
                        let obj = {
                            width: '',
                            height: '',
                        }
                        let img = new Image()
                        img.src = item.src
                        img.onload = () => {
                            let { width } = img,
                                { height } = img
                            if (width > 146) {
                                height = 146 / (width / height)
                                width = 146
                            }
                            if (height > 211) {
                                width = 211 * (width / height)
                                height = 211
                            }
                            obj.width = width + 'px' + '!important'
                            obj.height = height + 'px' + '!important'
                            that.$set(item, 'styObj', obj)
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 编辑订单
        editOrder() {
            this.$confirm('确定编辑该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    // console.log(this.item)
                    let data = {}
                    if (this.dialog.orderOne.value1) {
                        data.sellper = 1
                    }
                    if (this.dialog.orderOne.value2) {
                        data.ispoints = 1
                    }
                    if (this.dialog.orderOne.value3) {
                        data.isclose = 1
                    }
                    if (this.dialog.orderOne.value4) {
                        data.is_back = 1
                    }
                    data.id = this.item.id
                    data.fee = this.dialog.orderOne.radio1
                    data.back_reasons = this.dialog.orderOne.radio2
                    // console.log("data:",data)
                    this.$api.setOrder(data).then((res) => {
                        console.log(res.data.code)
                        // 操作成功
                        if (res.data.code == 0) {
                            this.dialog.dialogVisible = false
                            this.$message({
                                type: 'info',
                                message: '操作成功',
                            })
                            // this.getData();
                            this.getData(1, this.params)
                        } else {
                            this.$message({
                                type: 'info',
                                message: '操作失败',
                            })
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消编辑',
                    })
                })
        },
        // 竞价中 撤回
        recall(content) {
            this.$confirm('确定撤回该报价?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        no: content.orderno,
                        uid: content.uid,
                    }
                    this.$api.cancelOffer(data).then((res) => {
                        // 撤回竞价后，重新获取用户状态
                        let data = {
                            no: content.orderno,
                        }
                        this.$api.getOffer(data).then((res) => {
                            this.dialog.tableData = res.data.data
                        })
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            // console.log(content)
        },
        // 头部搜索
        searchBtn() {
            this.pages = {
                totalPage: 0,
                currentPage: 1,
            }
            this.limit = 15
            this.offset = 0
            // console.log('dfsa')
            // 获取日期
            if (this.dateSele.value) {
                this.params.start_at = moment(this.dateSele.value[0]).format('X')
                this.params.end_at = moment(this.dateSele.value[1]).format('X')
            } else {
                this.params.start_at = ''
                this.params.end_at = ''
            }
            if (this.typeSele.value) {
                this.params.tag = Number(this.typeSele.value)
            } else {
                this.params.tag = ''
            }
            if (this.search.value) {
                this.params.s = this.search.value
            } else {
                this.params.s = ''
            }
            if (this.statusSele.value) {
                this.params.st = Number(this.statusSele.value)
            } else {
                this.params.st = ''
            }
            this.getData(1, this.params)
            // console.log(data)
        },
        // 获取数据
        getData(page = 1, params = {}) {
            let offset = (page - 1) * this.limit
            this.loading = true
            let otherParams = {}
            for (const key in params) {
                if (params[key]) {
                    // console.log("params[key]",params[key])
                    otherParams[key] = params[key]
                } else if (params[key] === 0) {
                    otherParams[key] = params[key]
                }
            }
            let data = {
                limit: this.limit,
                offset: offset,
            }
            Object.assign(data, otherParams)
            // console.log(data)
            this.$api
                .getOrder(data)
                .then((res) => {
                    this.loading = false
                    this.table.tableData = res.data.data.rows
                    this.table.tableData.map((item) => {
                        // 修改
                        // if (item.trading_seats == 1) {
                        //     this.$set(item, "copySeat", item.seat);
                        //     if (item.seat.includes(",")) {
                        //         // 展示
                        //         item.seat = item.seat.split(",");
                        //     } else {
                        //         let seat = [];
                        //         seat.push(item.seat);
                        //         // 展示
                        //         item.seat = seat;
                        //     }
                        // } else {
                        //     this.$set(
                        //         item,
                        //         "copySeat",
                        //         item.trading_seat_string
                        //     );
                        //     if (item.trading_seat_string.includes(",")) {
                        //         // 展示
                        //         item.trading_seat_string =
                        //             item.trading_seat_string.split(",");
                        //     } else {
                        //         let seat = [];
                        //         seat.push(item.trading_seat_string);
                        //         // 展示
                        //         item.trading_seat_string = seat;
                        //     }
                        // }

                        if (item.trading_seat_string) {
                            this.$set(item, 'copySeat', item.trading_seat_string)
                            if (item.trading_seat_string.includes(',')) {
                                // 展示
                                item.trading_seat_string = item.trading_seat_string.split(',')
                            } else {
                                let seat = []
                                seat.push(item.trading_seat_string)
                                // 展示
                                item.trading_seat_string = seat
                            }
                        } else {
                            this.$set(item, 'copySeat', item.seat)
                            if (item.seat.includes(',')) {
                                // 展示
                                item.seat = item.seat.split(',')
                            } else {
                                let seat = []
                                seat.push(item.seat)
                                // 展示
                                item.seat = seat
                            }
                        }
                    })
                    // console.log(this.table.tableData)
                    this.table.tableData.sort((a, b) => {
                        let atime = moment(a.created_at).format('x')
                        let btime = moment(b.created_at).format('x')
                        if (parseInt(atime) > parseInt(btime)) {
                            return -1
                        }
                        if (parseInt(atime) < parseInt(btime)) {
                            return 1
                        } else if (parseInt(atime) == parseInt(btime)) {
                            return 0
                        }
                    })
                    this.count = res.data.data.count
                    this.countToPages(res.data.data.count)
                    this.countDonw()
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        // 倒计时
        countDonw() {
            this.table.tableData.map((item) => {
                let res = this.NewOrderInitTime(parseInt(this.getTimestamp(item.created_at)) + 3 * 60 * 1000, 'mm-ss', '已结束')
                this.$set(item, 'outTime', res)
            })
            if (this.timer) {
                clearInterval(this.timer)
            }
            this.timer = setInterval(() => {
                this.table.tableData.map((item) => {
                    let res = this.NewOrderInitTime(parseInt(this.getTimestamp(item.created_at)) + 3 * 60 * 1000, 'mm-ss', '已结束')
                    this.$set(item, 'outTime', res)
                })
                let bol = this.table.tableData.some((item) => {
                    return this.jingjia(item.created_at)
                })
                if (!bol) {
                    clearInterval(this.timer)
                }
            }, 1000)
        },
        // 改变场次时间的滑块
        changeTime(type, num) {
            if (type == 'arr') {
                if (num == 1) {
                    if (this.seatInfo.currentInd < this.seatInfo.tArr.length - 1) {
                        this.seatInfo.currentInd = this.seatInfo.currentInd + 1
                        if (this.seatInfo.currentInd >= 2 && this.seatInfo.currentInd < this.seatInfo.tArr.length - 2) {
                            this.seatInfo.left = this.seatInfo.width * (this.seatInfo.currentInd - 2) * -1
                        }
                    }
                } else {
                    if (this.seatInfo.currentInd > 0) {
                        this.seatInfo.currentInd = this.seatInfo.currentInd - 1
                        if (this.seatInfo.currentInd >= 2 && this.seatInfo.currentInd < this.seatInfo.tArr.length - 2) {
                            this.seatInfo.left = this.seatInfo.width * (this.seatInfo.currentInd - 2) * -1
                        }
                    }
                }
            } else {
                this.seatInfo.currentInd = num
                if (this.seatInfo.currentInd >= 2 && this.seatInfo.currentInd < this.seatInfo.tArr.length - 2) {
                    this.seatInfo.left = this.seatInfo.width * (this.seatInfo.currentInd - 2) * -1
                } else {
                    if (this.seatInfo.currentInd == 1) {
                        console.log(this.seatInfo.currentInd)
                        this.seatInfo.left = 0
                    } else if (this.seatInfo.currentInd == this.seatInfo.tArr.length - 2) {
                        console.log(this.seatInfo.currentInd)
                        this.seatInfo.left = this.seatInfo.width * (this.seatInfo.currentInd - 3) * -1
                    }
                }
            }
            this.seatInfo.conArr = this.seatInfo.totalArr[this.seatInfo.currentInd]
        },
        // 滚动事件
        scroll(e) {
            this.seatInfo.top = e.target.scrollTop
        },
        // 初始化座位
        initSeat(data) {
            if (data.area_price) {
                this.seatInfo.isArea = true
                this.seatInfo.zoneObj = JSON.parse(data.area_price) || undefined
                let priceArr = []
                let totalArr = []
                let totalArr2 = []
                for (let var1 in this.seatInfo.zoneObj) {
                    totalArr.push([var1 + ',' + this.seatInfo.zoneObj[var1]])
                    priceArr.push(this.seatInfo.zoneObj[var1])
                }
                // console.log("totalArr",totalArr)
                // console.log("totalArr2:",totalArr2)
                totalArr.forEach((item) => {
                    totalArr2.push(item[0].split(','))
                })
                totalArr2 = totalArr2.sort(this.sortNum)
                for (let i = 0; i < totalArr2.length; i++) {
                    totalArr2[i][2] = i
                }
                this.seatInfo.zonePrice = totalArr2
                // console.log("this.zonePrice",this.seatInfo.zonePrice)
            } else {
                this.seatInfo.isArea = false
            }
            // console.log(data)
            // 得到最大最小行列
            let seat = data.seatList
            let minCol = seat[0].gCol
            let maxCol = seat[0].gCol
            let minRow = seat[0].gRow
            let maxRow = seat[0].gRow
            seat.forEach((element) => {
                minCol = element.gCol < minCol ? element.gCol : minCol
                minRow = element.gRow < minRow ? element.gRow : minRow
                maxRow = element.gRow > maxRow ? element.gRow : maxRow
                maxCol = element.gCol > maxCol ? element.gCol : maxCol
            })
            maxRow = maxRow - minRow + 1
            maxCol = maxCol - minCol + 1
            this.seatInfo.seatRow = maxRow
            this.seatInfo.seatCol = maxCol
            this.seatInfo.minRow = minRow
            this.seatInfo.minCol = minCol
            // console.log("this.seatInfo.seatRow:",this.seatInfo.seatRow)
            // console.log("this.seatInfo.seatCol:",this.seatInfo.seatCol)
            // console.log("this.seatInfo.minRow:",this.seatInfo.minRow)
            // console.log("this.seatInfo.minCol:",this.seatInfo.minCol)
            let arr = new Array(maxRow).fill(9).map((item, index) => {
                return (item = new Array(maxCol).fill({
                    state: -1,
                    id: '',
                    row: '',
                    col: '',
                    type: '',
                    seatType: '',
                    price: 0,
                }))
            })
            seat.forEach((seatInfo, num) => {
                let status = 2 //-1为非座位，0为未购座位，1为已选座位(绿色),2为已购座位(红色)
                let char = seatInfo.type.split('-')
                if (!char[1]) {
                    status = 0
                } else if (seatInfo.type === '') {
                    status = -1
                }
                arr[parseInt(seatInfo.gRow) - minRow][parseInt(seatInfo.gCol) - minCol] = {
                    state: status,
                    id: seatInfo.id,
                    row: seatInfo.row,
                    col: seatInfo.col,
                    type: seatInfo.type,
                    seatType: char[0],
                    price: seatInfo.price,
                    wp_original_price: seatInfo.wp_original_price,
                    // 分区定价字段
                    area_id: seatInfo.area_id || '',
                }
            })
            this.seatInfo.seatList = arr
            // console.log(this.seatInfo.seatList)
        },
        // 座位验证
        verificationSeat(seat) {
            let result = seat.every((item) => {
                let judge = []
                let leftData = this.seatInfo.seatList[item.seat.row][item.seat.col - 1] || {
                    state: -1,
                }
                judge[0] = leftData.state == 0 ? true : false

                let leftData2 = this.seatInfo.seatList[item.seat.row][item.seat.col - 2] || {
                    state: -1,
                }
                judge[1] = leftData2.state != 0 ? true : false

                let rightData = this.seatInfo.seatList[item.seat.row][item.seat.col + 1] || {
                    state: -1,
                }
                judge[2] = rightData.state == 0 ? true : false

                let rightData2 = this.seatInfo.seatList[item.seat.row][item.seat.col + 2] || {
                    state: -1,
                }
                judge[3] = rightData2.state != 0 ? true : false

                let flag = 'normal'
                if (leftData.state == -1 || leftData.state == 2) {
                    return true
                } else if (rightData.state == -1 || rightData.state == 2) {
                    return true
                }
                if (!this.judgeSeat(judge[0], judge[1])) {
                    flag = 'err'
                }
                if (!this.judgeSeat(judge[2], judge[3])) {
                    flag = 'err'
                }
                return flag == 'normal'
            })
            if (!result) {
                return false
            }
            return true
        },
        // 判断座位类型
        judgeSeat(state1, state2) {
            if (state1) {
                if (state2) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        // 座位更新 A 计划
        buySeat() {
            let seat = [],
                seatId = [],
                seatType = []
            this.seatInfo.optArr.forEach((item) => {
                seat.push(item.row + '排' + item.col + '座')
                seatId.push(item.id)
                seatType.push(item.type)
            })
            let data = {
                no: this.item.orderno,
                seat: seat.join(','),
                seatId: seatId.join(','),
                show_id: this.seatInfo.showId,
            }
            this.$api.updateSeatInfo(data).then((res) => {
                if (res.data.data.msg == 'ok') {
                    this.dialog.dialogVisible = false
                    clearInterval(this.timer)
                    this.getData(1, this.params)
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                    })
                } else {
                    this.$message.error('修改失败')
                }
            })
        },
        // 判断座位是否可选 A 计划
        buySeatDss() {
            if (this.seatInfo.optArr.length === 0) {
                if (this.seatInfo.showId) {
                    let data = {}
                    data.seatList = this.seatInfo.seatList
                    data.no = this.item.orderno
                    data.showId = this.seatInfo.showId
                    data.currentItem = this.item
                    this.B_setSeat(data, false)
                }
                return
            } else {
                if (!this.verificationSeat(this.seatInfo.optArr)) {
                    // console.log("this.verificationSeat:", this.seatInfo.optArr);
                    this.$message({
                        type: 'warning',
                        message: '请不要留下空位',
                    })
                    return false
                } else {
                    this.buySeat()
                }
            }
        },
        // 取消更新
        buySeatCancel() {
            this.dialog.dialogVisible = false
        },
        // 打开对话框弹框
        edit(type, index, content) {
            // this.item = ''
            this.dialog.dialogWidth = '40%'
            if (type == 'order') {
                ;(this.dialog.orderOne = {
                    value1: false,
                    value2: false,
                    value3: false,
                    value4: false,
                    radio1: 0,
                    radio2: 1,
                }),
                    (this.dialog.isShow = 0)
                this.item = content

                this.dialog.dialogVisible = true
            } else if (type == 'edit') {
                this.dialog.dialogWidth = '36%'
                this.item = content
                console.log('this.item', this.item)
                this.dialog.isShow = 1
                let data = {
                    no: content.orderno,
                }
                this.$api.getOffer(data).then((res) => {
                    this.dialog.tableData = res.data.data
                })
                this.dialog.dialogVisible = true
                // console.log(data)
            } else if (type == 'ticket') {
                // getTicketImg
                let data = {
                    no: content.orderno,
                }
                this.$api.getTicketDetailByNo(data).then((res) => {
                    if (res.data.data.length == 0) {
                        this.$message({
                            type: 'warning',
                            message: '没有出票信息',
                        })
                    } else {
                        this.item = res.data.data
                        this.item.map((item) => {
                            item.result = JSON.parse(item.result)
                        })
                        console.log(this.item)
                        this.dialog.dialogVisible = true
                    }
                })
                this.dialog.dialogWidth = '27.5rem'
                this.dialog.isShow = 2
            } else if (type == 'img') {
                this.dialog.dialogWidth = '24.875rem'
                this.dialog.isShow = 3
                this.dialog.isBtn = content
                this.dialog.imgPre = {
                    id: '',
                    url: '',
                    tackTicket: '',
                    vertiTicket: '',
                    index: '',
                }
                let data = this.drawer.ticketImg[index]
                // console.log(data)
                if (data.src) {
                    this.dialog.imgPre.url = data.src
                } else if (data.url) {
                    this.dialog.imgPre.url = data.url
                }
                this.dialog.imgPre.id = data.id
                this.dialog.imgPre.index = index
                // 设置预览图片大小
                this.setPrePic(this.dialog.imgPre.url)
                if (data.result) {
                    if (JSON.parse(data.result).length == 1) {
                        this.dialog.imgPre.tackTicket = JSON.parse(data.result)[0].value
                    } else {
                        let arr = JSON.parse(data.result)
                        for (let index = 0; index < arr.length; index++) {
                            // console.log(arr[index])
                            if (arr[index].text == '验证码') {
                                this.dialog.imgPre.vertiTicket = arr[index].value
                            } else {
                                this.dialog.imgPre.tackTicket = arr[index].value
                            }
                        }
                    }
                } else if (data.auto_code) {
                    this.dialog.imgPre.tackTicket = data.auto_code
                }
                this.dialog.dialogVisible = true
            } else if (type == 'seat') {
                this.item = content
                this.seatInfo.SelectNum = content.seat.length
                // console.log(this.seatInfo.SelectNum)
                this.dialog.dialogWidth = '61.625rem'
                this.dialog.isShow = 4
                this.seatInfo.isShow = true
                this.changeSeat()
                this.seatInfo.width = 23.8125 / 5
                this.dialog.dialogVisible = true
            }
        },
        // 设置预览图片的大小
        setPrePic(url) {
            let that = this
            let img = new Image()
            img.src = url
            img.onload = () => {
                let { width } = img,
                    { height } = img
                if (width > 420) {
                    height = 420 / (width / height)
                    width = 420
                }
                if (height > 500) {
                    width = 500 * (width / height)
                    height = 500
                }
                that.styleObject.width = width + 'px' + '!important'
                that.styleObject.height = height + 'px' + '!important'
            }
        },
        // 弹框
        open(cont) {
            this.$confirm('此操作将' + cont + ',' + '是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: `${cont}成功!`,
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: `${cont}已取消`,
                    })
                })
        },
        //  计算页数
        countToPages(count) {
            let pages = (count / 15) * 1
            let result = pages.toString().indexOf('.')
            if (result != -1) {
                pages = pages.toString().split('.')[0] * 1 + 1
            }
            this.pages.totalPage = pages || 0
        },
        // 分页
        handleCurrentChange(e) {
            this.getData(e, this.params)
        },
        // 场次座位 - 是否显示修改
        seat(val) {
            // 获取当前时间戳
            let currentTime = moment().format('x')
            // show时间戳
            let showTime = moment(val).format('x')
            if (showTime < currentTime) {
                return false
            } else {
                return true
            }
        },
    },
}
</script>

<style lang="less" scoped>
.custom_popover {
    display: block;
    min-width: 120px;
}
.B_changeSeat {
    .B_changeSeat_div {
        margin-top: 5px;
        /deep/.el-button--mini {
            padding: 5px !important;
        }
    }
}

.back {
    height: 324px;
    border: 1px solid transparent;
    .close {
        margin: 20px 20px 30px 0px;
        text-align: right;
    }
    .i {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        margin-bottom: 20px;
        i {
            color: #f83a68;
            font-size: 45px;
        }
    }
    .t {
        text-align: center;
        font-size: 16px;
        margin-bottom: 42px;

        span {
            display: block;
            &:last-of-type {
                color: #f83a68;
            }
        }
    }
    .b {
        text-align: center;
        font-size: 16px;
        > div {
            display: inline-block;
        }
        .le {
            width: 180px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: #f83a68;
            border-radius: 4px;
            cursor: pointer;
            color: white;
            margin-right: 20px;
        }
        .ri {
            width: 120px;
            height: 38px;
            text-align: center;
            line-height: 38px;
            border: 1px solid #d1d1d1;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}
.bu {
    width: 100px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    color: white;
    background: #f83a68;
    border-radius: 4px;
    margin-left: 15px;
}
.f_tui {
    width: 70%;
    overflow: hidden;
    .d_tui {
        display: flex;
        justify-content: flex-start !important;
    }
}

.seatInp {
    border: none;
    outline: none;
    width: 60px;
}

/deep/ .el-table__row td {
    padding: 0 !important;
}

.order {
    padding: 1.25rem;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 0.375rem 0px rgba(24, 90, 233, 0.25);
    border-radius: 0.5rem;

    /deep/ .el-table .cell {
        line-height: 20px !important;
    }
    .top {
        margin-bottom: 1.25rem;
        font-size: 0.875rem !important;
        /deep/.right {
            > div {
                margin-right: 1.375rem;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            width: 70%;
            .el-input__inner {
                height: 30px;
            }
            .el-date-editor {
                width: 240px;
            }
            .el-input--suffix {
                width: 126px;
            }
            .el-input--prefix {
                width: 318px;
            }
            .el-button--primary {
                height: 30px;
                width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #4295fb;
                font-size: 14px;
            }
            .statusSele {
                .el-input__suffix {
                    right: 16px;
                    .el-select__caret {
                        color: white;
                    }
                }
                .el-input__inner {
                    color: white;
                    background: #4295fb !important;
                    &::placeholder {
                        color: white;
                    }
                }
            }
        }
    }
    .content {
        /deep/ .cinameStyle:hover {
            color: #606266 !important;
            background-color: #f8fafc !important;
        }
        /deep/ .cinameStyle:active {
            color: #606266 !important;
            background-color: #f8fafc !important;
        }

        .cinameStyle {
            outline: 0;
            border: none;
            padding: 0;
            font-size: 0.75rem;
        }
        .pop {
            padding: 0;
        }
        /deep/ tbody > tr {
            font-size: 0.75rem !important;
        }

        .content_seatInfo {
            > div:hover {
                .seatIcon {
                    display: block;
                }
            }
            .seatIcon {
                // display: none;
                display: block;
            }
        }

        .content_order {
            h4 {
                width: 100%;
            }
            > div {
                > span {
                    display: inline-block;
                    // min-height: 1.5625rem;
                }
            }
            > span {
                // display: inline-block;
                width: 100%;
            }
            i {
                display: none;
            }
            > div:hover,
            h4:hover {
                // cursor: pointer;
                i {
                    display: inline-block;
                }
            }
        }
    }
    // 弹出框
    /deep/ .el-dialog {
        border-bottom: none;
        border-radius: 0.5rem;
        border-bottom: transparent 0.375rem solid;

        .el-dialog__header {
            padding: 0;
            .el-dialog__close {
                color: white;
                font-size: 1.325rem;
            }
        }
        .el-dialog__body {
            padding: 0;
        }
        // 对话框
        .orderOne {
            // padding-bottom: 1.25rem;
            .diaTitle {
                font-size: 1rem !important;
                background-color: #4295fb;
                height: 3.625rem;
                padding-left: 2.0625rem;
                color: white;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }
            // 新订单，待出票，已出票 start
            .diaContent {
                font-size: 0.875rem;
                padding: 1.875rem;
                padding-bottom: 0;
                .top {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-template-rows: 1fr 1fr;
                    row-gap: 1rem;
                    column-gap: 5rem;
                    justify-items: end;
                    justify-content: start;
                    > div {
                        span {
                            margin-right: 1.25rem;
                        }
                    }
                }
                .middle {
                    margin: 2.25rem 0rem;
                    padding-left: 2.45rem;
                    > span {
                        margin-right: 1.25rem;
                    }
                    .el-radio {
                        margin-right: 1rem;
                    }
                }
                .bottom {
                    margin-bottom: 3rem;
                    padding-left: 1.5625rem;
                    > div {
                        margin-left: 1.25rem;
                        .radio_g {
                            display: grid;
                            grid-template-columns: auto auto;
                            grid-gap: 1.5rem;
                            justify-items: start;
                        }
                    }
                }
            }
            // 编辑报价
            .diaContent_two {
                font-size: 0.875rem;

                padding: 1.25rem;
                padding-bottom: 0;
                // margin-bottom: 1rem;
                .el-table--border::after {
                    width: 0rem !important;
                }
                .el-table {
                    ::-webkit-scrollbar {
                        background-color: #f0edf1;
                    }
                    ::-webkit-scrollbar-thumb {
                        background-color: #cdcbce; /*滚动条的背景颜色*/
                    }
                    border: #cccccc solid 0.0625rem;
                    .el-table__empty-text {
                        color: black;
                    }
                    .gutter {
                        background: #f0edf1;
                    }
                }
            }
            // 出票详情
            .diaContent_three {
                font-size: 0.875rem;

                height: 29.125rem;
                padding-top: 2.5rem;
                .el-carousel__indicators {
                    display: none;
                }
                .el-carousel__container {
                    height: 24.875rem;
                }
                .el-carousel__item {
                    display: flex;
                    justify-content: center;
                    .swiper_item {
                        width: 14.75rem;
                        z-index: 999;
                        > img {
                            width: 100%;
                            height: 80%;
                            margin-bottom: 1.1875rem;
                        }
                        > .text {
                            display: block;
                            color: black;
                            > div {
                                margin-bottom: 0.625rem;
                            }
                            span {
                                color: #666666;
                            }
                        }
                    }
                }
            }
            // 图片详情
            .imgContent {
                background-color: #f6f6f6;
                .top {
                    padding: 1.125rem 0.625rem;
                    margin-bottom: 0px;
                    display: flex;
                    justify-content: center;
                    img {
                        width: 20.4375rem;
                        height: 32.375rem;
                    }
                }
                .bot {
                    background-color: white;
                    padding: 0.75rem 0.875rem;
                    .btn {
                        margin-top: 0.9375rem;
                        > button {
                            width: 5.375rem;
                            height: 2rem;
                        }
                    }
                }
            }
            // 座位信息
            .seatInfo {
                height: 31rem;
                .left {
                    width: calc(61.265rem - 25rem);
                    height: 100%;
                    padding: 1.25rem 2.3125rem 3.0625rem 1.875rem;
                    .top {
                        position: relative;
                        height: 10%;
                        img {
                            width: 75%;
                            height: 100%;
                        }
                        .text {
                            position: absolute;
                            top: 1rem;
                        }
                    }
                    // 座位盒子
                    .seatBox {
                        width: 30.625rem;
                        position: relative;
                        .left_line {
                            width: 1.3125rem;
                            position: relative;
                            height: 23.25rem;
                            // height: 13.25rem;
                            background-color: #f8f8f8;
                            margin-right: 0.625rem;
                            overflow: hidden;
                            .contain {
                                width: 1.3125rem;
                                position: absolute;
                                left: 0;
                            }
                        }
                        .right_box {
                            max-width: 28.8125rem;
                            background-color: #f8f8f8;
                            overflow: auto;
                            height: 23.25rem;
                            //  height: 13.25rem;
                            .seatLine {
                                word-break: keep-all;
                                white-space: nowrap;
                            }
                        }

                        // word-break:keep-all;
                    }
                }
                .changeTime {
                    width: calc(61.265rem - 25rem);

                    .haveValue {
                        // height: 100%;
                        margin: 2.9375rem 2.375rem 2.75rem 3.6875rem;
                        border: 1px solid #cccccc;
                        .head {
                            width: 100%;
                            overflow: hidden;
                            position: relative;
                            border-bottom: 1px solid #e9e9e9;
                            .arr {
                                width: 3.1375rem;
                                height: 3.1375rem;
                                line-height: 3.1375rem;
                                text-align: center;
                                background-color: #efecef;
                                &:hover {
                                    background-color: #ccccce;
                                    border-top: 1px solid #ccccce;
                                }
                            }
                            .con {
                                width: calc(100% - 2 * 2.9375rem);
                                position: relative;
                                overflow: hidden;
                                .tabbar {
                                    // position: absolute;
                                    white-space: nowrap;
                                    .day {
                                        height: 3.1375rem;
                                        font-size: 0.75rem;
                                        text-align: center;
                                        display: inline-block;
                                        padding-top: 0.3125rem;
                                        > span {
                                            display: inline-block;
                                            &:first-child {
                                                margin-top: 0.225rem;
                                            }
                                            margin-bottom: 0.225rem;
                                        }
                                    }
                                }
                            }
                        }
                        .change_content {
                            max-height: 21.875rem;
                            overflow: auto;
                            .movie_city {
                                height: 3.525rem;
                                border-bottom: 1px solid #e9e9e9;
                                // padding-left: 1.875rem;
                                // >div{
                                //     margin-right:4rem;
                                // }
                            }
                        }
                    }
                    .noValue {
                        height: 18.75rem;
                    }
                }
                .right {
                    width: 26.25rem;
                    height: calc(100% + 0.375rem);
                    background-color: #ebf3fc;
                    padding-left: 3.875rem;
                    padding-top: 6.875rem;
                    padding-bottom: 5.5rem;
                    border-bottom: #ebf3fc 0.375rem solid;
                    border-bottom-right-radius: 0.5rem;
                    .time {
                        padding: 0.3125rem;
                        background-color: #ffffff;
                        margin-right: 0.5rem;
                    }
                    > .btn {
                        button {
                            margin-right: 2.5rem;
                            width: 5.375rem;
                        }
                    }
                }
            }
            .footer {
                margin: 1.875rem;
                button {
                    width: 80px;
                    &:nth-of-type(1) {
                        margin-right: -5.125rem;
                    }
                    &:nth-of-type(2) {
                        margin-left: -5.125rem;
                    }
                }
            }
        }
    }

    // 右侧抽屉
    .drawer {
        .drawer_top {
            height: 4.125rem;
            line-height: 4.125rem;
            color: white;
            background-color: #4295fb;
            padding-left: 1.875rem;
            font-size: 1rem;
        }
        .drawer_content {
            padding-top: 1.5rem;
            padding-left: 2.75rem;
            padding-right: 0.75rem;
            .order_info {
                display: grid;
                grid-template-columns: 60% 40%;
                grid-template-rows: repeat(7, auto);
                row-gap: 1.2875rem;
                column-gap: 0.625rem;
                > div {
                    font-size: 0.875rem;
                    &:first-of-type {
                        font-size: 1rem;
                        color: black;
                    }
                    color: #666666;
                    > span {
                        color: black;
                    }
                }
            }
            .order_btn {
                margin-top: 2.5rem;
                button {
                    width: 6.75rem;
                    margin-right: 1.25rem;
                    margin-left: 0;
                    font-size: 0.875rem;
                }
            }
            .btn {
                button {
                    width: 6.75rem;
                    margin-right: 1.25rem;
                    margin-left: 0;
                    font-size: 0.875rem;
                }
            }
        }
        .upLoad {
            .picture {
                margin-top: 1.5625rem;
                .left {
                    > div {
                        &:hover {
                            .cover {
                                display: block;
                            }
                        }
                        position: relative;
                        width: 9rem;
                        height: 13.1875rem;
                        margin-right: 0.75rem;
                        box-shadow: 0px 0px 0.25rem 0px rgba(179, 179, 179, 0.36);
                        border-radius: 0.25rem;
                        text-align: center;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 0.25rem;
                            cursor: pointer;
                        }
                        .cover {
                            > .top {
                                margin: 2.675rem 0px;
                            }
                            display: none;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: black;
                            border-radius: 0.25rem;
                            opacity: 0.7;
                            p {
                                text-align: center;
                                font-size: 0.625rem;
                                color: #ffffff;
                                margin: 0.625rem 0;
                            }
                            .iconList {
                                margin-top: 2.35rem;
                                .icon {
                                    color: #ffffff;
                                    font-size: 1.525rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .right {
                }
            }
        }
    }

    // 上传组件样式
    /deep/ .el-upload--picture-card {
        width: 9rem !important;
        height: 13.1875rem !important;
        line-height: 13.1875rem !important;
    }
    /deep/ .el-upload-list {
        display: none;
    }

    // active
    .index {
        color: #4295fb;
        position: relative;
        &::after {
            position: absolute;
            bottom: 0.625rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 2px;
            background-color: #4295fb;
            content: '';
        }
    }
    .page_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .expointList {
            display: flex;
            justify-content: start;
            /deep/.el-date-editor {
                width: 240px;
                height: 34px;
            }
            /deep/.el-input__icon {
                line-height: 26px;
            }
            /deep/.el-range-separator {
                line-height: 24px;
            }
            .export {
                cursor: pointer;
                width: 7rem;
                height: 2rem;
                line-height: 2rem;
                text-align: center;
                color: white;
                background: #4295fb;
                border-radius: 0.25rem;
                margin-left: 20px;
            }
        }
    }

    // 颜色
    .lanse {
        color: #4295fb !important;
    }
    .huise {
        color: #999999 !important;
    }
    .hongse {
        color: #f74d4d !important;
    }
    .zise {
        color: #b763e7 !important;
    }
    .lvse {
        color: #4fdc76 !important;
    }
    .seat {
        // padding: 0.1rem;
        font-size: 0.4rem;
        border-radius: 2px;
        margin-right: 0.625rem;
    }
    .putong {
        color: #4295fb;
        border: 1px solid #4295fb;
    }
    .qinglv {
        color: #b763e7;
        border: 1px solid #b763e7;
    }
    .ketiao {
        color: #4fdc76;
        border: 1px solid #4fdc76;
    }
    .buketiao {
        color: #f68b44;
        border: 1px solid #f68b44;
    }
    .iconLeft {
        cursor: pointer;
        margin-left: 10px;
    }
}
</style>
