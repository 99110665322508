import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/api'
import common from './utils/common/common'


import 'default-passive-events'


import VueResource from 'vue-resource'
Vue.use(VueResource)

Vue.config.productionTip = false

import '../src/assets/css/global.css'
import '../src/assets/icon/icon.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import filters from './utils/filter/filters'


Vue.use(ElementUI);

Vue.prototype.$api = api
Vue.prototype.$common = common

Object.keys(filters).forEach(key => Vue.filter(key,filters[key]))

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
