import qs from "qs";
import axios from "axios"
import { Message } from 'element-ui';
import store from "../store/index.js";


// console.log(store.state)

const serve = axios.create({
    timeout:500000,
    // baseURL:store.state.baseUrl
	
})

serve.interceptors.request.use(
    (config)=>{
        if(store.state.userInfo){
            config.headers.token = store.state.userInfo.token
        }
        // console.log(config)
        // config.headers['Content-Type'] = 'application/json;utf-8'
        config.data = JSON.stringify(config.data)    
        return config
    },
    (error) =>{
        console.log(error)
        Promise.reject(error)
    }
)

serve.interceptors.response.use(
    (res) => {
        if(res.data.code == 0){
            return res;
        }
        if(res.data.code == -1){
            Message.error(res.data.msg);
        }
    },
    (error) => {
        console.log("err" + error);
        Message.error("连接超时(后台不能连接，请联系系统管理员)")
        return Promise.reject(error);
    }
)

export default serve