<template>
    <div class="cash">
        <div class="box">
            <div class="p16">
                <div class="pb16 flex jc_b ai_c">
                    <div class="flex jc_s ai_c">
                        <i class="iconfont iconyue f16 lan"></i>
                        <span class="col666 f14 ml10 mr30">金额：￥{{ orderSettled }}元</span>
                        <el-button type="primary" class="ssbtn" style="width: 86px" @click="txDialog = true">提现</el-button>
                        <span class="col666 f14 ml10">共{{ total }}条</span>
                    </div>
                    <div class="flex jc_end f14 col333 ai_c">
                        <el-button type="primary" class="ssbtn" style="width: 5.375rem; margin: 0 15px 0 0 !important" @click="exportOrder">导 出</el-button>
                        <span class="mr20">日期：</span>
                        <el-date-picker
                            v-model="valDate"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']"
                            :clearable="false"
                            class="xzdate"
                            style="width: 15rem"
                        >
                        </el-date-picker>
                        <span class="mr10 ml10">类型：</span>
                        <el-select v-model="valSelect" style="width: 7.875rem" class="xzselect">
                            <el-option v-for="item in valSelectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <span class="mr10 ml10">状态：</span>
                        <el-select v-model="statusSelect" style="width: 7.875rem" class="xzselect">
                            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <el-input v-model="valSerch" placeholder="请输入用户的ID" style="width: 10.875rem" class="ml15 xzinput"></el-input>
                        <el-button type="primary" class="ssbtn" style="width: 5.375rem; margin: 0 0 0 15px !important" @click="searchBtn">搜 索</el-button>
                    </div>
                </div>
                <!---->
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column prop="serialno" label="流水号" width="220" fixed>
                            <template slot-scope="scope">
                                <div>{{ scope.row.serialno }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderno" label="订单号" width="200" fixed>
                            <template slot-scope="scope">
                                <div>{{ scope.row.orderno }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="金额" width="90">
                            <template slot-scope="scope">
                                <div v-if="scope.row.cash_type == 0">- {{ scope.row.price }}</div>
                                <div v-else-if="scope.row.cash_type == 1">+ {{ scope.row.price }}</div>
                                <div v-else-if="scope.row.cash_type == 2">- {{ scope.row.price }}</div>
                                <div v-else-if="scope.row.cash_type == 3">+ {{ scope.row.price }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="balance" label="提现后余额" width="100">
                            <template slot-scope="scope">
                                <div>{{ scope.row.balance }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cash_type" label="收支类型" width="100">
                            <template slot-scope="scope">
                                <div v-if="scope.row.cash_type == 0" style="color: #4fdc76">提现</div>
                                <div v-else-if="scope.row.cash_type == 1" style="color: #f74d4d">已到账</div>
                                <div v-else-if="scope.row.cash_type == 2" style="color: #4fdc76">系统扣除</div>
                                <div v-else-if="scope.row.cash_type == 3" style="color: #4fdc76">在路上</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="80">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status == 0">成功</div>
                                <div v-else="scope.row.status == 1">失败</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" label="操作时间" width="180">
                            <template slot-scope="scope">
                                <div>{{ scope.row.created_at }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" label="到账时间" width="180">
                            <template slot-scope="scope">
                                <div>{{ scope.row.finish_time }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nickname" label="提现人" width="160">
                            <template slot-scope="scope">
                                <div>{{ scope.row.uid }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ scope.row.nickname }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="reason" label="备注" width="350">
                            <template slot-scope="scope">
                                <div>{{ scope.row.reason }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!--table end-->
                <div class="page pt16">
                    <el-pagination v-if="pageIsShow" background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="total"> </el-pagination>
                </div>
            </div>
        </div>
        <!--box end-->
        <el-dialog id="tixian" class="dialogHeaderno" width="575px" :visible.sync="txDialog">
            <div class="qxDialogHeader flex-column jc_c">
                <div class="flex jc_b ai_c">
                    <span class="f16 colfff">提现</span>
                    <i class="iconfont iconguanbi colfff f18" @click="txDialog = false"></i>
                </div>
            </div>
            <div class="txmid">
                <el-form :model="txForm" :rules="txRules" ref="txForm">
                    <div class="qxmidm">
                        <span class="qxmidmtxt">可提现金额：</span>
                        <span style="line-height: 32px">{{ orderSettled }}元</span>
                    </div>
                    <div class="qxmidm" style="margin: 5px 0">
                        <span class="qxmidmtxt">待结算：</span>
                        <span style="line-height: 32px">{{ orderSettling }}元</span>
                    </div>
                    <div class="qxmidm">
                        <span class="qxmidmtxt">收款方式：</span>
                        <span style="line-height: 32px">支付宝</span>
                    </div>
                    <div class="qxmidm mt10 mb26">
                        <span class="qxmidmtxt">提现金额：</span>
                        <el-form-item prop="price">
                            <el-input placeholder="输入金额" v-model="txForm.price" auto-complete="off"></el-input>
                        </el-form-item>
                    </div>
                    <!--<div class="qxmidm mb10">
				<span class="qxmidmtxt">备注：</span>
				<el-form-item>
					<el-input type="textarea"></el-input>
				</el-form-item>
			</div>-->
                    <div class="qxmidm pv40">
                        <el-button type="primary" class="ssbtn" style="width: 5.375rem; margin-right: 5rem" @click="submitTxForm('txForm')">确认</el-button>
                        <el-button class="cancelbtn" style="width: 5.375rem" @click="resetTxForm('txForm')">取消</el-button>
                    </div>
                </el-form>
            </div>
            <!--qxmid end-->
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import FileSaver from 'file-saver'


export default {
    data() {
        return {
            pageIsShow: true,
            valDate: '',
            valSelect: '',
            valSelectOptions: [
                {
                    value: 0,
                    label: '提现',
                },
                {
                    value: 1,
                    label: '收入',
                },
                {
                    value: 2,
                    label: '扣除',
                },
            ],
            statusSelect: '3',
            statusOptions: [
                {
                    value: '3',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '成功',
                },
                {
                    value: '1',
                    label: '失败',
                },
            ],
            tableData: [],
            valSerch: '',
            pageSize: 20,
            currentPage: 1,
            total: 0,
            orderSettled: '',
            orderSettling: '',
            txDialog: false,
            txForm: {
                price: '',
            },
            txRules: {
                price: [{ required: true, message: '输入金额', trigger: 'blur' }],
            },
            condition: {
                start: '',
                end: '',
                cash_type: '',
                uid: '',
                status:''
            },
        }
    }, //data
    mounted() {
        this.getData()
    }, //
    methods: {
        // 下载订单
        exportOrder() {
            // console.log('toFinanceExcel', this.params)
            let token = this.$store.state.userInfo.token
            let headers = {
                token: token,
            }
            this.write()
            let data = this.filter()
            delete data['limit']
            delete data['offset']
            // console.log(data)
            axios({
                method: 'post',
                data: data,
                url: '/sys/toCashExcel',
                responseType: 'blob',
                headers: headers,
            }).then((res) => {
                let blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel',
                })
                FileSaver.saveAs(blob, '提现列表.xlsx')
            })
        },
        getData(val = 1) {
            const that = this
            let data = this.filter(val)
            this.$api.getCash(data).then((res) => {
                that.total = res.data.data.list.count
                that.orderSettled = res.data.data.orderSettled
                that.orderSettling = res.data.data.orderSettling
                that.tableData = res.data.data.list.rows
            })
        }, //getData
        filter(val) {
            let offset = (val - 1) * this.pageSize
            let uid = this.condition.uid ? Number(this.condition.uid) : ''
            let data = {
                limit: this.pageSize,
                offset: offset,
                end: this.condition.end,
                start: this.condition.start,
                cash_type: this.condition.cash_type,
                uid: uid,
                status: Number(this.statusSelect),
            }
            return data
        },
        // 搜索
        searchBtn() {
            this.write()
            const that = this

            this.pageIsShow = false
            that.currentPage = 1
            this.$nextTick(() => {
                this.pageIsShow = true
            })
            this.getData()
            // console.log(this.currentPage)
        },
        write() {
            const that = this
            var stime
            if (!this.valDate[0]) {
                stime = ''
            } else {
                stime = moment(this.valDate[0]).format('YYYY-MM-DD')
            }
            var etime
            if (!this.valDate[1]) {
                etime = ''
            } else {
                etime = moment(this.valDate[1]).format('YYYY-MM-DD')
            }
            let fcash_type
            if (this.valSelect == 2 || this.valSelect == 1 || this.valSelect == 0 || this.valSelect == null) {
                fcash_type = this.valSelect
            } else {
                fcash_type = ''
            }
            this.condition.end = etime
            this.condition.start = stime
            this.condition.cash_type = fcash_type
            this.condition.uid = this.valSerch
            this.condition.status = this.statusSelect
            
        },
        handleCurrentChange(val) {
            this.getData(val)
        },
        submitTxForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const that = this
                    let data = {
                        _id: JSON.parse(localStorage.getItem('userInfo'))._id,
                        price: that.txForm.price,
                    }
                    this.$api.toCash(data).then((res) => {
                        if (res.data.code == 0) {
                            that.$message.success(res.data.msg)
                            setTimeout(function () {
                                that.txDialog = false
                                that.getData()
                            }, 10)
                            location.reload()
                        }
                    })
                }
            })
        },
        resetTxForm(formName) {
            this.$refs[formName].resetFields()
            this.txDialog = false
        }, //resetScmyForm
    }, //methods
}
</script>

<style lang="less" scoped>

</style>