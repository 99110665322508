<template>
    <div class="distribution">
        <template>
            <div class="title">
                <div class="flex jc_s flex_wrap" style="flex: 1">
                    <el-input v-model="search" placeholder="搜索影院" style="width: 12%"></el-input>
                    <el-input v-model="search2" placeholder="搜索用户名" style="width: 12%;margin-left:30px"></el-input>

                    <div class="active" @click="searchBtn">查询</div>
                </div>
                <div class="title_right">
                    <div>
                        <span class="mr10 f16" style="color: #666">承包</span>
                        <el-switch v-model="distriStatus" @change="changeStatus" :active-value="1" :inactive-value="2" active-color="#185ae9"></el-switch>
                    </div>
                    <div class="autoRule active" @click="dialogVisible = true">新增分配规则</div>
                </div>
            </div>
            <div class="content">
                <template>
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column label="序号" width="60">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.$index + 1 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rule_name" label="规则名" width="140"></el-table-column>
                        <el-table-column label="城市" min-width="50">
                            <template slot-scope="scope">
                                <div v-if="scope.row.city">
                                    {{ scope.row.city | dealData('nm') }}
                                </div>
                                <div v-else>不限</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="影院" min-width="100">
                            <template slot-scope="scope">
                                <div v-if="scope.row.cinema_list">{{ scope.row.cinema_list | dealData('nm', 38) }}</div>
                                <div v-else>不限</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="影院数量" width="80">
                            <template slot-scope="scope">
                                <div v-if="scope.row.cinema_list">{{ scope.row.cinema_list.length }}</div>
                                <div v-else>0</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="报价模式" width="120">
                            <template slot-scope="scope">
                                <div v-if="scope.row.offer_type == 4">固定金额报价</div>
                                <div v-else-if="scope.row.offer_type == 1">比例报价</div>
                                <div v-else-if="scope.row.offer_type == 2">最高价减法报价</div>
                                <div v-else-if="scope.row.offer_type == 3">市场价减法报价</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="比例" width="160">
                            <template slot-scope="scope">
                                <div v-if="scope.row.offer_type === 1">
                                    <span v-for="(item, index) in dealObj(scope.row.offer_price)" class="mr10"> {{ `${item.contain}厅-${item.price * 100}%` }}</span>
                                </div>
                                <div v-else>
                                    <span v-for="(item, index) in dealObj(scope.row.offer_price)" class="mr10"> {{ `${item.contain}厅-${item.price}元` }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="70">
                            <template slot-scope="scope">
                                <el-switch @change="statusSwitch($event, scope.row)" v-model="scope.row.status" :active-value="1" active-color="#185ae9"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="180">
                            <template slot-scope="scope">
                                <el-button class="caozuo" type="primary" @click="editRule(scope.row)">编辑</el-button>
                                <el-button class="caozuo" style="color: red" type="primary" @click="deleteRule(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
            <div class="page pt16">
                <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="total"> </el-pagination>
            </div>

            <el-dialog :visible.sync="dialogVisible" width="38%" class="dialog" :close-on-click-modal="false" :show-close="false" @closed="closeDialog">
                <div class="dialog_title">
                    <span>编辑</span>
                    <span class="iconfont iconguanbi1" style="font-size: 30px" @click="dialogVisible = false"></span>
                </div>
                <div class="dialog_body">
                    <el-form ref="form" :model="form2" label-width="100px">
                        <el-form-item label="标题:" prop="title">
                            <el-input v-model="form2.title" placeholder="请输入标题"></el-input>
                        </el-form-item>
                        <div class="flex jc_b ai_c">
                            <el-form-item label="适用省份" style="width: 50%">
                                <el-select class="selectInput" v-model="form2.province" multiple filterable placeholder="请输入包含的省份名称" @change="proChange">
                                    <el-option v-for="item in form.province" :key="item.id" :label="item.district_name" :value="item.id"> </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="适用城市" style="width: 50%">
                                <el-select class="selectInput" v-model="form2.city" multiple filterable placeholder="请输入包含的城市名称" @change="cityChange">
                                    <el-option v-for="item in form.city" :key="item.id" :label="item.district_name" :value="item.cid"> </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <el-form-item label="适用影院">
                            <div style="position: relative">
                                <el-input v-model="form2.cinema" placeholder=""></el-input>
                                <el-button style="position: absolute; right: 0; top: 0" type="primary" @click="remoteMethod(form2.cinema, 'getCinema')">搜索</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item label="搜索昵称">
                            <el-select
                                class="selectInput"
                                clearable
                                v-model="form2.nickname"
                                multiple
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请输入用户名"
                                :remote-method="
                                    (val) => {
                                        this.remoteMethod(val, 'getSellerList')
                                    }
                                "
                                :loading="nickLoading"
                                @focus="focusFun"
                            >
                                <el-option v-for="item in nickList" :key="item.id" :label="item.nickname + '-' + item.id" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" label-width="0px">
                            <el-transfer :titles="['未选列表', '选中列表']" v-model="form2.targetListKeys" :data="sourceList">
                                <div slot-scope="{ option }">
                                    <el-tooltip class="item" effect="dark" :content="option.addr" placement="right">
                                        <span :style="{ color: option.distribution_status === 1 ? 'red' : 'black' }">{{ option.cname }} | {{ option.label }}</span>
                                    </el-tooltip>
                                </div>
                                <el-button
                                    class="transfer-footer"
                                    slot="left-footer"
                                    size="small"
                                    @click="
                                        sourceList = []
                                        form2.targetListKeys = []
                                    "
                                    >清空</el-button
                                >
                            </el-transfer>
                        </el-form-item>
                        <el-form-item label="报价模式:">
                            <el-radio v-model="form2.priceModeRadio" @change="priceModel" label="1">比例报价</el-radio>
                            <!-- <el-radio v-model="form2.priceModeRadio" label="2">最高价减法报价</el-radio>
                            <el-radio v-model="form2.priceModeRadio" label="3">市场价减法报价</el-radio> -->
                            <el-radio v-model="form2.priceModeRadio" @change="priceModel" label="4">固定金额报价</el-radio>
                        </el-form-item>
                        <el-form-item label="">
                            <div class="hall">
                                <!-- v-if="form2.priceModeRadio == 4" -->

                                <div class="containHall" v-for="(item, index) in form2.hallArr">
                                    <el-input type="number" v-model="item.price">
                                        <template slot="prepend">
                                            <el-input class="input" v-model="item.hallName" :disabled="item.disable" placeholder="输入影厅"></el-input>
                                        </template>
                                    </el-input>
                                    <div class="delete" v-if="item.disable == false" @click="deletehall(item, index)">-</div>
                                </div>
                                <span class="plus" @click="addhall">
                                    <i class="el-icon-plus"></i>
                                </span>
                                <span v-if="form2.priceModeRadio == 4" style="color: red; margin: 0px">注释*：固定报价模式下，请设置不包含影厅，否则所有影厅都算普通厅！</span>
                            </div>
                            <!-- <el-input style="margin-bottom: 8px" type="number" v-model="form2.ratioPrice" v-if="form2.priceModeRadio == 1" class="input" placeholder="输入比例,不需要输入%"></el-input> -->
                        </el-form-item>

                        <!-- <el-form-item label="包含影厅:" v-if="form2.priceModeRadio != 4">
                            <el-select class="selectInput" popper-class="popper-class" v-model="form2.containHall" multiple filterable allow-create default-first-option placeholder="包含影厅">
                                <el-option v-for="item in form2.empty" :key="item.mid" :label="item.nm" :value="item.nm"> </el-option>
                            </el-select>
                        </el-form-item> -->

                        <el-form-item label="不包含影厅:">
                            <el-select
                                class="selectInput"
                                popper-class="popper-class"
                                v-model="form2.unIncluedeHall"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                placeholder="请输入不包含的影厅名称"
                            >
                                <el-option v-for="item in form2.empty" :key="item.mid" :label="item.nm" :value="item.nm"> </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="市场价:" class="price">
                            <el-input type="number" v-model="form2.price1" placeholder="请输入内容" clearable></el-input>
                            <div class="horizontal"></div>
                            <el-input type="number" v-model="form2.price2" placeholder="请输入内容" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="情侣座:">
                            <el-radio v-model="form2.loversRadio" label="1">包含</el-radio>
                            <el-radio v-model="form2.loversRadio" label="2">不包含</el-radio>
                        </el-form-item>
                        <el-form-item label="退回订单:">
                            <el-radio v-model="form2.isBack" label="1">包含</el-radio>
                            <el-radio v-model="form2.isBack" label="2">不包含</el-radio>
                        </el-form-item>
                        <el-form-item label="接受调座:">
                            <el-radio v-model="form2.seatRadio" label="1">含不可调座</el-radio>
                            <el-radio v-model="form2.seatRadio" label="2">只含调座</el-radio>
                        </el-form-item>
                        <el-form-item label="座位数量:">
                            <el-radio v-model="form2.seatNumberRadio" label="5">全部</el-radio>
                            <el-radio v-model="form2.seatNumberRadio" label="1">1</el-radio>
                            <el-radio v-model="form2.seatNumberRadio" label="2">2</el-radio>
                            <el-radio v-model="form2.seatNumberRadio" label="3">3</el-radio>
                            <el-radio v-model="form2.seatNumberRadio" label="4">4</el-radio>

                        </el-form-item>

                        <el-form-item class="button">
                            <el-button type="primary" style="background: #185ae9; min-width: 160px" @click="onSubmit">确认</el-button>
                            <el-button style="min-width: 100px" @click="dialogVisible = false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            _id: '',
            autoOffserTime: {
                autoOffserTime_1: '',
                autoOffserTime_2: '',
            },
            logList: [],
            tableData: [],
            dialogVisible: false,
            form: {
                empty: [],
                ruleName: '',
                proRadio: '1',
                province: [],
                cityRadio: '1',
                city: [],
                inlcudeProvince: '',
                unInlcudeProvince: '',
                inlcudeCity: '',
                unInlcudeCity: '',
                price1: '',
                price2: '',
                incluedeCinema: '',
                unIncluedeCinema: '',
                movieRadio: '1',
                inlcudeMovie: '',
                unIncludeMovie: '',
                movie: [],
                loversRadio: '1',
                seatRadio: '1',
                seatNumberRadio: '1',
                isBack: '1',
                priceModeRadio: '1',
                ratioPrice: '',
                marketPrice: '',
                highestPrice: '',
                placeHall: {
                    4: '输入报价',
                    1: '输入比例',
                    3: '输入金额',
                    2: '输入金额',
                },
                hallArr: [
                    {
                        price: '',
                        hallName: '普通厅',
                        disable: true,
                    },
                ],
                containHall: [],
                unIncluedeHall: '',
                statusValue: '',
                options: [
                    {
                        value: '1',
                        label: '开启',
                    },
                    {
                        value: '2',
                        label: '关闭',
                    },
                ],
            },
            editId: '',

            form2: {
                empty: [],
                title: '',
                province: [],
                city: '',
                cinema: '',
                nickname: [],
                unIncluedeHall: '',
                containHall: [],
                targetListKeys: [],
                priceModeRadio: '1',
                ratioPrice: '',
                hallArr: [
                    {
                        price: '',
                        hallName: '普通厅',
                        disable: false,
                    },
                ],
                price1: '',
                price2: '',
                loversRadio: '1',
                isBack: '1',
                seatRadio: '1',
                seatNumberRadio: '5',
            },
            sourceList: [],
            movieList: [],
            nickList: [],
            nickLoading: false,
            pageSize: 20,
            currentPage: 1,
            total: 0,
            distriStatus: '',
            page: {
                isShow: true,
                name: '',
                total: 0,
                limit: 8,
                offset: 0,
                currentPage: 1,
                type: '',
            },
            search: '',
            search2:'',
            nm: '',
            nickname:'',
        }
    },
    created() {
        this.getList()
        this.getPro()

        this.distriStatus = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).is_distribution : 2
    },
    filters: {
        dealData(val, type, len = 10) {
            if (!val.length > 0) {
                return ''
            }
            let arr = []
            val.forEach((item) => {
                arr.push(item[type])
            })
            arr = arr + ''
            if (arr.length > len) {
                return arr.substring(0, len) + '...'
            } else {
                return arr
            }
        },
    },
    methods: {
        priceModel(e) {
            this.form2.hallArr = [
                {
                    price: '',
                    hallName: '普通厅',
                    disable: false,
                },
            ]
        },
        dealObj(obj) {
            return Object.values(JSON.parse(obj))
        },
        searchBtn() {
            this.nm = this.search
            this.nickname = this.search2
            this.currentPage = 1
            this.getList()
        },
        changeStatus(e) {
            let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
            if (!userInfo) {
                this.distriStatus = e === 1 ? 2 : 1
                return false
            }
            userInfo.is_distribution = e
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.$api
                .setDistributionCinema({ status: e })
                .then((res) => {})
                .catch((err) => {})
        },
        getList() {
            let data = {
                limit: this.pageSize,
                offset: (this.currentPage - 1) * this.pageSize,
            }
            if (this.nm) {
                data['nm'] = this.nm
            }
            if(this.nickname){
                data['nickname'] = this.nickname
            }
            this.$api
                .getDistributionCinema(data)
                .then((res) => {
                    this.total = Number(res.data.data.count)
                    this.tableData = res.data.data.rows
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },
        editRule(item) {
            let arr = []
            let nickname = []
            if (item.user_list.length > 0) {
                item.user_list.forEach((item) => {
                    arr.push(item.nickname)
                    nickname.push(item.suid)
                })
            }
            arr = arr.join(',')
            this.page.type = 'getSellerList'

            Promise.all([this.getCity({ district: item.province_id }), this.getCinema({ cid: item.city_id }), this.SearchData(arr)])
                .then((res) => {
                    this.editId = item.id
                    if (item.cinema_list.length > 0) {
                        let selectList = []
                        let sourceList = []
                        item.cinema_list.forEach((item) => {
                            selectList.push(Number(item.cinema_id))
                            sourceList.push(item)
                        })
                        this.form2.targetListKeys = selectList
                        if (!this.sourceList.length > 0) {
                            this.sourceList = sourceList.map((item) => {
                                item.key = Number(item.cinema_id)
                                item.label = item.nm
                                return item
                            })
                        }
                    }
                    this.form2.title = item.rule_name
                    this.form2.unIncluedeHall = item.not_contain_cinema_halls ? item.not_contain_cinema_halls.split(',') : ''
                    this.form2.priceModeRadio = item.offer_type + ''
                    this.form2.province = item.province_id ? this.deal(item.province_id.split(',')) : ''
                    this.form2.city = item.city_id ? this.deal(item.city_id.split(',')) : ''
                    this.form2.nickname = nickname

                    this.form2.price1 = item.min_market_price ? Number(item.min_market_price) : ''
                    this.form2.price2 = item.max_market_price ? Number(item.max_market_price) : ''
                    this.form2.loversRadio = item.lovestatus + ''
                    this.form2.isBack = item.isback + ''
                    this.form2.seatRadio = item.ischange + ''
                    this.form2.seatNumberRadio = item.seat_num + ''

                    // 处理报价模式 start
                    let hallArr = []
                    let offer_price_obj = item.offer_price ? JSON.parse(item.offer_price) : ''
                    let offer_price_arr = []
                    let contain_hall = []
                    for (let key in offer_price_obj) {
                        offer_price_arr.push(offer_price_obj[key])
                    }
                    offer_price_arr.forEach((item) => {
                        if (item.contain != '其他') {
                            contain_hall.push(item.contain)
                        }
                    })
                    offer_price_arr.forEach((item) => {
                        let obj = {}
                        if (item.contain == '其他') {
                            obj.hallName = '普通厅'
                            obj.disable = false
                        } else {
                            obj.disable = false
                            obj.hallName = item.contain
                        }
                        if (this.form2.priceModeRadio == 1) {
                            obj.price = Number(item.price) * 100
                        } else if (this.form2.priceModeRadio == 4) {
                            obj.price = Number(item.price)
                        }
                        hallArr.push(obj)
                    })
                    this.form2.hallArr = hallArr

                    // 处理报价模式 end

                    this.dialogVisible = true
                })
                .catch((err) => {
                    console.log('err', err)
                })
        },
        deal(arr) {
            return arr.map((item) => {
                item = Number(item)
                return item
            })
        },
        deleteRule(item) {
            let that = this
            this.$confirm('您确定要删除规则吗?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then((res) => {
                if (res === 'confirm') {
                    let data = {
                        rule_id: item.id,
                        is_deleted: '2',
                    }
                    this.operaionDistriCinema(data, () => {
                        this.getList()
                    })
                }
            })
        },
        statusSwitch(e, item) {
            console.log(e)
            let label = e ? '1' : '2'
            let data = {
                rule_id: item.id,
                status: label,
            }
            this.operaionDistriCinema(data, () => {
                this.getList()
            })
        },
        onSubmit(e, sortCon) {
            if (!this.form2.title) {
                this.$message.error('请输入标题')
                return false
            } else if (!this.form2.targetListKeys.length > 0) {
                this.$message.error('请选择影院')
                return false
            } else if (!this.form2.nickname.length > 0) {
                this.$message.error('请选择用户昵称')
                return false
            }
            // 设置报价模式和金额 - start
            let cinema_halls = []
            let offer_price = {}
            let bol = this.form2.hallArr.every((item) => {
                if (item.hallName !== '' && item.price !== '') {
                    return true
                } else {
                    return false
                }
            })
            if (!bol) {
                this.$notify.error({
                    title: '失败',
                    message: `请输入对应的影厅或报价`,
                })
                return false
            }
            this.form2.hallArr.forEach((item, index) => {
                cinema_halls.push(item.hallName)
                let obj = {}
                if (item.hallName == '普通厅') {
                    obj.contain = '其他'
                } else {
                    obj.contain = item.hallName
                }
                if (this.form2.priceModeRadio == 1) {
                    obj.price = Number(item.price) / 100
                } else if (this.form2.priceModeRadio == 4) {
                    obj.price = Number(item.price)
                }
                offer_price[index] = obj
            })
            offer_price = JSON.stringify(offer_price)
            // 设置报价模式和金额 - end
            let data = {
                rule_name: this.form2.title,
                suid: this.form2.nickname + '',
                cinema_id: this.form2.targetListKeys + '',
                offer_price: offer_price,
                offer_type: this.form2.priceModeRadio,
                not_contain_cinema_halls: this.form2.unIncluedeHall ? this.form2.unIncluedeHall + '' : '',
                city_id: this.form2.city + '',
                province_id: this.form2.province + '',

                min_market_price: this.form2.price1,
                max_market_price: this.form2.price2,
                isback: this.form2.isBack,
                lovestatus: this.form2.loversRadio,
                ischange: this.form2.seatRadio,
                seat_num: this.form2.seatNumberRadio,
            }
            if (this.editId) {
                data['rule_id'] = this.editId
            }
            // debugger
            // // return false
            this.operaionDistriCinema(data, () => {
                this.dialogVisible = false
                this.getList()
            })
        },
        operaionDistriCinema(data, callback) {
            this.$api
                .distributionCinema(data)
                .then((res) => {
                    // console.log('res', res)
                    callback()
                })
                .catch((err) => {
                    // console.log('err', err)
                })
        },
        SearchData(name, e = 1) {
            this.nickLoading = true
            let data = {}
            if (this.page.type === 'getCinema') {
                data.keywords = name
                if (this.form2.city + '') {
                    data.cid = this.form2.city + ''
                }
                if (this.form2.province + '') {
                    data.district = this.form2.province + ''
                }
            } else {
                data = {
                    nickname: name,
                }
            }
            return new Promise((resolve) => {
                this.$api[this.page.type](data)
                    .then((res) => {
                        if (this.page.type === 'getCinema') {
                            this.movieList = res.data.data || []
                            if (Array.isArray(this.movieList) && this.movieList.length > 0) {
                                let list = this.movieList.map((item) => {
                                    item['key'] = item.cinemaid
                                    item['label'] = item.nm
                                    return item
                                })
                                list.forEach((item) => {
                                    // 插入数据时判断未选列表中是否存在该数据
                                    let bol = this.sourceList.some((ele) => {
                                        return ele.cinemaid === item.cinemaid
                                    })
                                    // 判断已选列表中是否存在该数据
                                    let bol2 = this.form2.targetListKeys.some((ele) => {
                                        return ele === item.cinemaid
                                    })
                                    //
                                    if (!bol && !bol2) {
                                        this.sourceList.push(item)
                                    }
                                })
                            }
                        } else {
                            this.nickList = res.data.data
                        }
                        this.nickLoading = false
                        resolve()
                    })
                    .catch((err) => {
                        console.log(err)
                        this.nickLoading = false
                        resolve()
                    })
            })
        },
        remoteMethod(e, type) {
            this.page.type = type
            // if (e != this.page.name) {
            //     console.log('远程    ------')
            //     this.page.currentPage = 1
            //     this.page.isShow = false
            //     this.$nextTick(() => {
            //         this.page.isShow = true
            //     })
            // }
            this.page.name = e
            if (this.page.name) {
                this.SearchData(this.page.name)
                return false
            }
            if (this.form2.province + '') {
                this.SearchData()
                return false
            }
        },
        changePage(e) {
            this.SearchData(this.page.name, e)
        },
        proChange(e) {
            this.form2.city = ''
            this.form.city = []
            this.form2.cinema = ''
            this.sourceList = []
            this.form2.targetListKeys = []

            let data = {
                district: e + '',
            }
            this.getCity(data)
        },
        cityChange() {
            this.form2.cinema = ''
            this.sourceList = []
            this.form2.targetListKeys = []

            let data = {
                cid: this.form2.city + '',
            }
            this.movieList = []
            this.getCinema(data)
        },
        // 获取省份
        getPro(data = {}) {
            this.$api.getProvince(data).then((res) => {
                if (res.data.code == 0) {
                    this.form.province = res.data.data
                }
            })
        },
        //获取城市
        getCity(data) {
            return new Promise((resolve) => {
                this.$api
                    .getCity(data)
                    .then((res) => {
                        if (res.data.data) {
                            this.form.city = res.data.data
                        }
                        resolve()
                    })
                    .catch((err) => {
                        resolve()
                    })
            })
        },
        // 获取影片列表
        getCinema(data) {
            return new Promise((resolve) => {
                this.$api
                    .getCinema(data)
                    .then((res) => {
                        let list = res.data.data
                        if (Array.isArray(list) && list.length > 0) {
                            list.forEach((element) => {
                                element.key = element.cinemaid
                                element.label = element.nm
                            })
                            this.sourceList = list
                        }
                        resolve()
                    })
                    .catch((err) => {
                        console.log(err)
                        resolve()
                    })
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
        addhall() {
            this.form2.hallArr.push({
                price: '',
                hallName: '',
                disable: false,
            })
        },
        deletehall(item, index) {
            this.form2.hallArr.splice(index, 1)
        },
        closeDialog() {
            this.editId = ''
            this.form2.title = ''
            this.form2.province = ''
            this.form2.city = ''
            this.form2.cinema = ''
            this.form2.nickname = ''
            this.form2.targetListKeys = []
            this.form2.priceModeRadio = '1'
            this.form2.ratioPrice = ''
            this.form2.containHall = []
            this.form2.unIncluedeHall = ''
            // this.form2.marketPrice = ''
            // this.form2.highestPrice = ''
            this.form2.hallArr = [
                {
                    price: '',
                    hallName: '普通厅',
                    disable: true,
                },
            ]

            this.form2.price1 = ''
            this.form2.price2 = ''
            this.form2.isBack = '1'
            this.form2.loversRadio = '1'
            this.form2.seatRadio = '1'
            this.form2.seatNumberRadio = '5'
            this.form2.unIncluedeHall = ''
            this.sourceList = this.movieList = this.nickList = []
            this.focusFun()
        },
        focusFun() {
            this.page.isShow = true
            this.page.name = ''
            this.page.total = 0
            this.page.limit = 8
            this.page.offset = 0
            this.page.currentPage = 1
            this.page.type = ''
        },
    },
}
</script>

<style lang="less" scoped>
.distribution {
    .disable {
        color: #c0c4cc !important;
    }
    .caozuo {
        padding: 0px;
        color: #185ae9;
        background: none;
        border: none;
    }
    .horizontal {
        width: 14px;
        height: 1px;
        background: #efefef;
        margin: 0 5px;
    }
    .title {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        background: #ffffff;
        padding: 10px 30px;
        border-radius: 8px;

        .title_right {
            display: flex;
            > div {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
        }

        .active {
            border-radius: 8px;
            background: #185ae9;
            color: white;
            margin-left: 20px;
            padding: 9px 44px;
        }
        .autoRule {
            margin-left: 41px;
            padding: 9px 44px;
        }
        /deep/ .el-input {
            // width: 128px;
            // .el-input__inner {
            //     height: 30px;
            // }
            .el-input__icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .content {
        margin-top: 20px;
    }
    .dialog {
        /deep/.el-dialog {
            margin-top: 6vh !important;
        }
        .dialog_title {
            background: #185ae9;
            color: white;
            padding: 20px 30px 20px 40px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        /deep/.dialog_body {
            padding: 24px;
            .el-radio__input.is-checked .el-radio__inner {
                border-color: #185ae9;
                background: #185ae9;
            }
            .el-radio__input.is-checked + .el-radio__label {
                color: #185ae9;
            }
            .el-checkbox__input.is-indeterminate .el-checkbox__inner,
            .is-checked .el-checkbox__inner {
                border-color: #185ae9;
                background: #185ae9;
            }
            .el-transfer-panel {
                width: 280px;
            }
            .el-transfer__buttons .el-button {
                padding: 12px 5px;
            }

            .el-transfer__buttons {
                padding: 0 15px;
            }
            .selectInput {
                width: 100%;
            }
            .hall {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                .containHall {
                    position: relative;
                    margin-right: 20px;
                    max-width: 280px;
                    margin-bottom: 8px;

                    .delete {
                        width: 10px;
                        height: 10px;
                        text-align: center;
                        line-height: 10px;
                        position: absolute;
                        border: 1px solid #bababa;
                        background: #ffffff;
                        color: #bababa;
                        border-radius: 50%;
                        top: -4px;
                        right: -4px;
                        cursor: pointer;
                    }
                    .el-input-group--prepend {
                        border: 1px solid #d1d1d1;
                        border-radius: 4px;
                        overflow: hidden;
                    }
                    .el-input__inner {
                        width: 100px;
                        border: none;
                    }
                    .el-input-group__prepend {
                        padding: 0 !important;
                        border: none !important;
                    }
                    .input {
                        .el-input__inner {
                            border-right: 1px solid #d1d1d1;
                        }
                    }
                    .el-input-group {
                        vertical-align: baseline;
                    }
                }
                .plus {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    background: #185ae9;
                    border-radius: 4px;
                    margin-bottom: 8px;
                }
            }
            .price {
                .el-input {
                    width: 30% !important;
                }
                .el-form-item__content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
        /deep/.el-dialog__header {
            padding: 0px;
        }
        /deep/ .el-dialog__body {
            padding: 0px !important;
        }
    }
    .log_block {
        min-height: 620px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(24, 90, 233, 0.25);
        border-radius: 8px;
        padding: 8px 15px;
        .border_bottom {
            margin: 5px 15px;
            border-bottom: 2px solid #efefef;
        }
        .log_title {
            display: flex;
            justify-content: space-between;
            padding: 10px 0 8px 0;
            margin: 0 17px;
            font-size: 16px;
            color: black;
            .icon {
                transform: rotate(90deg);
            }
            .log_title_block {
                min-width: 200px;
                text-align: left;
            }
        }
        .log_content {
            transition: all 3s;
            background: #f9f9f9;
            padding: 11px 18px;
            color: #333333;
            .log_content_block {
                margin-bottom: 20px;
                > div {
                    margin: 8px 0px;
                }
            }
        }
    }
}
.red {
    color: #f84670;
}
.green {
    color: #22c162;
}
.gray {
    color: #666666;
}
</style>