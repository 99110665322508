import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
		// baseUrl:'', // 客户的域名
   userInfo:JSON.parse(localStorage.getItem('userInfo')) || undefined
  },
  mutations: {
    setUInfo(state, info){
			state.userInfo = info
		},
  },
  actions: {
  },
  modules: {
  }
})

export default store
