<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style lang="less">

</style>
<script>
import $ from "jquery";
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            isRouterAlive: true,
        };
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
    }, //methods
};
</script>
